import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import optimusData from '../reducers/index'

export default function configureStore(initialState) {
    let devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
    return createStore(
        optimusData,
        initialState,
        compose(
            applyMiddleware(thunk),
            devTools()
        )
    )
}
