import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { adminRoutes, buildRoutePath } from '../../constants/routes'

class WidgetClients extends React.Component {
    state = {

    }

    renderClients = () => {
        const { clients } = this.props
        if (!clients) {
            return <div>No Clients Set</div>
        }

        return clients.map((client, i) => {
            let clientLink = buildRoutePath(adminRoutes.clientView, { clientId: client.get('id') })
            return (
                <NavLink key={i} to={ clientLink } className="btn-block">
                    <label className="d-flex align-items-center">
                        { client.get('name') }
                    </label>
                    <FontAwesomeIcon icon="caret-right" title="Go" />
                </NavLink>
            )
        })
    }

    render = () => {
        return (
            <div className="widget">
                <h3 className="border-bottom">Client List</h3>
                <div>
                    { this.renderClients() }
                </div>
            </div>
        )
    }
}

export default WidgetClients
