import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// constants
import { buildRoutePath } from '../../../constants/routes'

// models
import ClientModel from '../../../services/models/client-model'

// utilities
import SHSForm from '../../utils/shs-form'
import ModelInput from '../../utils/model-input'

// actions
import { createModel } from '../../../actions/api-model'

class CreateClient extends React.Component {
    state = {
        client: new ClientModel()
    }

    successFX = (clientId) => {
        const { routes } = this.props
        const nextPath = buildRoutePath(routes.clientView, { clientId })
        this.props.history.push(nextPath)
    }

    submitFX = (formData, formId) => {
        const { client } = this.state
        client.hydrate(formData)
        this.props.dispatch(createModel(client, formId))
    }

    render = () => {
        const { routes } = this.props
        return (
            <div className="row justify-content-center">
                <div className="col-6">
                    <Link to={routes.adminIndex}>
                        <small className="font-weight-light">&larr; Back to Client Dashboard</small>
                    </Link>
                    <h1 className="mt-4">Create a New Client</h1>
                    <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Create Client" >
                        <ModelInput
                            model={this.state.client}
                            field="name"
                            label="Client Name"
                            placeholder="Acme Fleet Company" />
                        <ModelInput
                            model={this.state.client}
                            field="fleetId"
                            label="Fleet ID"
                            placeholder="789" />
                        <ModelInput
                            model={this.state.client}
                            field="primaryContact"
                            label="Primary Contact"
                            placeholder="Ricky Bobby" />
                        <ModelInput
                            model={this.state.client}
                            field="phone"
                            label="Phone Number"
                            placeholder="(123) 456-7890" />
                        <ModelInput
                            model={this.state.client}
                            field="zipcode"
                            label="Zipcode"
                            placeholder="90210" />
                        <ModelInput
                            model={this.state.client}
                            field="website"
                            label="Website"
                            placeholder="https://www.example.com" />
                        <ModelInput
                            model={this.state.client}
                            field="notes"
                            label="Notes"
                            placeholder="Any notes about the client..." />
                    </SHSForm>
                </div>
            </div>
        )
    }
}

export default connect()(CreateClient)
