import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import AccountView from './view'
// import AccountEdit from './edit'
import NotFound from '../../pages/not-found'

class AccountIndex extends React.Component {
    state = {

    }

    render = () => {
        const { routes } = this.props
        return (
            <Switch>
                {/*<Route path={routes.accountEdit} render={props => <AccountEdit {...props} routes={routes} /> } />*/}
                <Route path={routes.accountIndex} render={props => <AccountView {...props} routes={routes} /> } />
                <Route component={NotFound} />
            </Switch>
        )
    }
}

export default withRouter(AccountIndex)
