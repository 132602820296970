import immutable from 'immutable'
import uuidv4 from 'uuid/v4'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_TRANSACTION } from '../constants/actions'

class TransactionModel extends Model {
    _actionType = SAVE_TRANSACTION
    _collection = COLLECTIONS.TRANSACTION_COLLECTION
    _parentCollection = COLLECTIONS.STATION_COLLECTION
    _isSubCollection = true
    _parentPK = 'stationId'
    _primaryKey = 'NT_TransactionID'
    _endpoint = 'transactions'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            usePrefix: true,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        stationId: {
            type: 'string',
            required: true
        },
        NT_TransactionID: {
            type: 'string',
            isRequired: true,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        NT_TransactionVehID: {
            type: 'string',
            isRequired: true
        },
        NT_TransactionFuelAmount: {
            type: 'string',
            isRequired: true
        },
        S_Pulse_Val_X: {
            type: 'string',
            isRequired: false
        },
        nozzleId: {
            type: 'string',
            isRequired: false
        },
        fuelType: {
            type: 'string',
            isRequired: false
        },
        tagNumber: {
            type: 'string',
            isRequired: false
        },
        dateString: {
            type: 'string',
            isRequired: false
        },
        dateValue: {
            type: 'integer'
        },
        pricePerGallon: {
            type: 'number',
            isRequired: false
        },
        transactionCost: {
            type: 'number',
            isRequired: false
        },
        truckId: {
            type: 'string',
            isRequired: false
        },
        customerCode: {
            type: 'string',
            isRequired: false
        },
        odometerAtTransaction: {
            type: 'string',
            isRequired: false
        },
        lastOdometerAtTransaction: {
            type: 'string',
            isRequired: false
        },
        engineHoursAtTransaction: {
            type: 'string',
            isRequired: false
        },
        lastEngineHoursAtTransaction: {
            type: 'string',
            isRequired: false
        },
        priceId: {
            type: 'string',
            isRequired: false
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    validate() {
        console.log('in the Transaction Model validate function of transaction model')
    }
}

export default TransactionModel
