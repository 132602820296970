import React from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// constants
import { buildRoutePath, apiRoutes } from '../../../constants/routes'

// models
import ClientModel from '../../../services/models/client-model'

// utilities
import SHSForm from '../../utils/shs-form'
import ModelInput from '../../utils/model-input'
import DragAndDrop from '../../utils/drag-and-drop'
import { validateImage } from '../../utils/validate-image'

// actions
import { updateModelWithFile, customModelAction } from '../../../actions/api-model'

const options = {
    minW: 50,
    minH: 50,
    maxW: 1000,
    maxH: 1000,
    maxSize: 0.8
}

class Edit extends React.Component {
    state = {
        client: null,
        deleteLogo: false,
        uploadFile: null,
        errorMessage: ''
    }

    componentDidMount = () => {
        if (this.props.client) {
            let clientModel = new ClientModel()
            clientModel.hydrate(this.props.client.toJSON())
            this.setState({ client: clientModel })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.client && prevProps.client !== this.props.client) {
            let clientModel = new ClientModel()
            clientModel.hydrate(this.props.client.toJSON())
            this.setState({ client: clientModel })
        }
    }

    confirmDelete = () => {
        const { dispatch, client } = this.props
        let confirmKill = window.confirm('Are you sure you want to delete this entry? This will remove this client from all trucks and stations and remove their account. This cannot be undone.')
        if (confirmKill) {
            const routeObj = apiRoutes.deleteClient
            routeObj.url = buildRoutePath(routeObj.url, { clientId: client.get('id') })
            let clientModel = new ClientModel()
            dispatch(customModelAction(clientModel, routeObj, {}, true))
        }
    }

    handleDrop = (file) => {
        this.setState({ errorMessage: '' })
        if (file[0]) {
            validateImage(file[0], options)
                .then(() => {
                    this.setState({ deleteLogo: false, uploadFile: file[0] })
                })
                .catch(err => {
                    this.setState({ errorMessage: err })
                })
        }
    }

    deleteImage = () => {
        let del = window.confirm('Would you like to remove this image? The image will be deleted when you save the options.')
        if (del) {
            this.setState({ deleteLogo: true, uploadFile: 'DELETE' })
        }
    }

    handleClick = () => {
        this.refs.logoManualUpload.click()
    }

    handleManualChange = () => {
        this.setState({ errorMessage: '' })
        if (this.refs.logoManualUpload.files[0]) {
            validateImage(this.refs.logoManualUpload.files[0], options)
                .then(() => {
                    this.setState({ deleteLogo: false, uploadFile: this.refs.logoManualUpload.files[0] })
                })
                .catch(err => {
                    this.setState({ errorMessage: err })
                })
        }
    }

    successFX = (clientId) => {
        this.props.onClose()
    }

    submitFX = (formData, formId) => {
        const { client } = this.state
        client.hydrate(formData)
        formData.clientId = this.props.client.get('id')
        formData.existingUrl = this.props.client.get('logo')
        this.props.dispatch(updateModelWithFile(client, formData, formId, this.state.uploadFile))
    }

    render = () => {
        const { client } = this.state
        return (
            <div className="px-5 pb-5">
                <h1 className="my-2 text-center">Edit Client</h1>
                { !client && <div>Fetching Client Data.</div>}
                { client &&
                    <>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save Changes">
                            <ModelInput
                                model={client}
                                field="name"
                                label="Client Name"
                                placeholder="Acme Fleet Company" />
                            <ModelInput
                                model={client}
                                field="fleetId"
                                label="Fleet ID"
                                placeholder="798" />
                            <ModelInput
                                model={client}
                                field="primaryContact"
                                label="Primary Contact"
                                placeholder="Ricky Bobby" />
                            <ModelInput
                                model={client}
                                field="phone"
                                label="Phone Number"
                                placeholder="(123) 456-7890" />
                            <ModelInput
                                model={client}
                                field="zipcode"
                                label="Zipcode"
                                placeholder="90210" />
                            <ModelInput
                                model={client}
                                field="website"
                                label="Website"
                                placeholder="https://www.example.com" />
                            <ModelInput
                                model={client}
                                field="notes"
                                label="Notes"
                                placeholder="Any notes about the client..." />
                            <input ref="logoManualUpload" type="file" name="logoManualUpload" onChange={ this.handleManualChange }/>
                            <div className="form-group file-drop-area" ref="logoFileDrop" >
                                <label htmlFor="siteLogo">Site Logo</label>
                                {!this.state.uploadFile && client.get('logo') && !this.state.deleteLogo &&
                                    <div className="site-logo-wrapper">
                                        <img className="thumbnail" src={client.get('logo')} alt="Site Logo" />
                                        <FontAwesomeIcon icon="times" title="Delete Logo" onClick={this.deleteImage} />
                                    </div>
                                }
                                <div onClick={ this.handleClick }>
                                    <DragAndDrop handleDrop={this.handleDrop} label="Click or drop new image file here to change">
                                        {
                                            this.state.uploadFile &&
                                            <div className="alert alert-success">
                                                <p>
                                                    New file <b>{this.state.uploadFile.name}</b> is ready.<br />
                                                    Click save to upload.
                                                </p>
                                            </div>
                                        }
                                        <div className="dragarea-label" />
                                        {
                                            this.state.errorMessage !== '' &&
                                            <div className="alert alert-danger">
                                                {this.state.errorMessage}
                                            </div>
                                        }
                                        <p className="helper-text"><small>Min Dimension: {options.minH}px, Max Dimension: {options.maxH}px, Max filesize: {options.maxSize}MB</small></p>
                                    </DragAndDrop>
                                </div>
                            </div>
                        </SHSForm>
                        <div className="btn mt-4" onClick={this.confirmDelete}>Delete Client</div>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        client: state.get('clients').find(client => client.get('id') === ownProps.client.get('id'))
    }
}

export default connect(mapStateToProps)(Edit)
