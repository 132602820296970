import React from 'react'
import { connect } from 'react-redux'

// actions
import { listModels } from '../../../actions/api-model'

// models
import ClientModel from '../../../services/models/client-model'

// components / helpers
import { formatTotalWithUnit } from '../../utils/formatters'
import FleetTable from '../../general/fleet-table'
import FleetUnassignedTable from '../../general/fleet-unassigned-table'
import InfoMark from '../../utils/info-mark'

class List extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(listModels(new ClientModel()))
    }

    renderCustomRuntime = () => {
        const { vehicles } = this.props
        if (!vehicles) {
            return null
        }

        let hours = vehicles.reduce((prev, cur, index) => {
            let value = cur.getIn(['report', 'bioDieselRuntime', 'hoursDiesel'])
            if (value) {
                return prev + value
            }
            return prev
        }, 0)

        return (
            <div className="widget">
                <h3 className="border-bottom">Biodiesel Runtime</h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ formatTotalWithUnit(hours) }</span>
                        <label>Hours</label>
                    </div>
                </div>
            </div>
        )
    }

    renderCustomRuntimePer = () => {
        const { vehicles } = this.props
        if (!vehicles) {
            return null
        }

        let totalBio = 0
        let totalDiesel = 0
        let totalLowFuel = 0
        vehicles.forEach(v => {
            let bio = v.getIn(['report', 'bioDieselRuntime', 'hoursBio'])
            let diesel = v.getIn(['report', 'bioDieselRuntime', 'hoursDiesel'])
            let lowFuel = v.getIn(['report', 'bioDieselRuntime', 'hoursLowFuel']) || 0
            totalLowFuel = totalLowFuel + lowFuel
            if (bio && diesel) {
                totalBio = totalBio + bio
                totalDiesel = totalDiesel + (diesel - lowFuel)
            }
        })

        let percent = 0
        let info = ''
        if (totalBio > 0 || totalDiesel> 0) {
            percent = Math.floor((totalBio / (totalBio + totalDiesel)) * 100)
            info = <div>Percentage excludes {totalLowFuel} low fuel hours.</div>
            if (percent < 0 || percent > 100) {
                percent = 0
                info = <div>There was an issue calculating this value.</div>
            }
        }

        return (
            <div className="widget">
                <h3 className="border-bottom">Percentage Biodiesel Runtime <InfoMark pos="left" info={info} /></h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ percent }</span>
                        <label>%</label>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        const { vehicles, unassignedVehicles, routes, clients } = this.props
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        { this.renderCustomRuntime() }
                    </div>
                    <div className="col-md-4">
                        { this.renderCustomRuntimePer() }
                    </div>
                    <div className="col-sm-4">
                        <FleetUnassignedTable vehicles={unassignedVehicles} path={routes.fleetEdit} />
                    </div>
                </div>
                <FleetTable label="Active Vehicles" allClients={clients} vehicles={vehicles} path={routes.fleetView} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        clients: state.get('clients'),
        vehicles: state.get('trucks').filter(s => s.get('clients') && s.get('clients').size > 0),
        allTrucks: state.get('truck'),
        unassignedVehicles: state.get('truckList').filter(s => !s.get('clients') || s.get('clients').size === 0)
    }
}

export default connect(mapStateToProps)(List)
