import { upsertForm } from './form'
import { fireauth, actionCodeSettings } from '../services/firebase/firebase'

import { formStates } from '../constants/helper-states'

import {
    KILL_STATE, SAVE_AUTH, SAVE_ANONYMOUS_AUTH, REMOVE_AUTH, REMOVE_AUTH_HANDLER, SAVE_AUTH_HANDLER, AUTH_HANDLER_ERROR
} from '../services/constants/actions'

const killState = () => {
    return {
        type: KILL_STATE
    }
}

const saveAuth = (auth) => {
    return {
        type: SAVE_AUTH,
        auth
    }
}

const saveAnonymousAuth = (auth) => {
    return {
        type: SAVE_ANONYMOUS_AUTH,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: REMOVE_AUTH
    }
}

const saveAuthHandlerConfig = (code, action, email) => {
    return {
        type: SAVE_AUTH_HANDLER,
        code,
        action,
        email
    }
}

const saveAuthHandlerError = () => {
    return {
        type: AUTH_HANDLER_ERROR
    }
}

export const clearAuthCode = () => {
    return {
        type: REMOVE_AUTH_HANDLER
    }
}

export const handleFirebaseAuthCode = (code, action) => {
    return dispatch => {
        fireauth.verifyPasswordResetCode(code)
            .then(email => {
                dispatch(saveAuthHandlerConfig(code, action, email))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const anonymousSignIn = () => {
    return dispatch => {
        fireauth.signInAnonymously()
            .then(() => {
                // nothing to do....
            })
            .catch(err => {
                console.log('error with anonymous')
            })
    }
}

export const registerWithInvitation = (model, password, invitationId, formId) => {
    return async dispatch => {
        const result = await model.acceptInvitation({ password, invitationId })
        if (typeof result === 'undefined' || result.hasError) {
            console.log(result)
            dispatch(upsertForm(formId, formStates.ERROR, `Error: ${result.error.message}`))
        } else {
            fireauth.signInWithEmailAndPassword(model.get('email'), password)
                .then(() => {
                    dispatch(upsertForm(formId, formStates.SUCCESS))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
                })
        }
    }
}

export const signInWithEmailAndPass = (email, password, formId) => {
    return dispatch => {
        fireauth.signInWithEmailAndPassword(email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                console.log(err)
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const generatePasswordResetLink = (email, formId) => {
    return dispatch => {
        fireauth.sendPasswordResetEmail(email, actionCodeSettings)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                console.log(err)
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const resetPasswordWithCode = (code, email, password, formId) => {
    return dispatch => {
        return fireauth.confirmPasswordReset(code, password)
            .then((response) => {
                fireauth.signInWithEmailAndPassword(email, password)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        console.log('in here with an error?')
                    })
            })
            .catch(err => {
                console.log('in here?')
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleAnonymousFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAnonymousAuth(firebaseUser))
    }
}

export const signOut = () => {
    return dispatch => {
        fireauth.signOut()
            .then(() => {
                dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}
