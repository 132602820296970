const globalMinW = 10
const globalMinH = 10
const globalMaxW = 2400
const globalMaxH = 2400
// in Mbs
const globalMaxSize = 2

export function validateImage(img, options) {
    let minW = options.minW ? options.minW : globalMinW
    let minH = options.minH ? options.minH : globalMinH
    let maxW = options.maxW ? options.maxW : globalMaxW
    let maxH = options.maxH ? options.maxH : globalMaxH
    let maxSize = options.maxSize ? options.maxSize : globalMaxSize

    return new Promise((resolve, reject) => {
        let obj = new Image()
        let wURL = window.URL || window.webkitURL
        obj.src = wURL.createObjectURL(img)
        obj.onload = (evt) => {
            const fileObject = evt.target ? evt.target : evt.path[0]
            // convert bytes to mbs
            let fSize = img.size / 1000000
            let imgwidth = fileObject.width
            let imgheight = fileObject.height
            if (fSize <= maxSize && imgwidth <= maxW && imgheight <= maxH) {
                if (imgwidth >= minW && imgheight >= minH) {
                    resolve(true)
                }
                reject('Image does not meet minimum requirements.')
            }
            if (fSize > maxSize) {
                reject('File size is too large.')
            } else if (imgwidth > maxW || imgheight > maxH) {
                reject('Image dimensions are too large.')
            }
            reject('Oops! Something went wrong.')
        }
    })
}

export function getImageProportion(imgSrc) {
    return new Promise((resolve, reject) => {
        let obj = new Image()
        obj.src = imgSrc
        obj.onload = (evt) => {
            const fileObject = evt.target ? evt.target : evt.path[0]
            if (fileObject.width > fileObject.height) {
                resolve('landscape')
            }
            resolve('portrait')
        }
    })
}
