import React from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// actions
import { queryModel, customModelAction } from '../../../actions/api-model'
import { apiRoutes } from '../../../constants/routes'

// models
import InvitationModel from '../../../services/models/invitation-model'

import MUIDataTable from 'mui-datatables'
import Modal from '../../utils/modal'
import InviteUser from './invite-user'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none'
}

class Invitations extends React.Component {
    state = {
        showModal: false
    }

    componentDidMount = () => {
        const { dispatch, clientId } = this.props
        dispatch(queryModel(new InvitationModel(), { clientId }))
    }

    handleModal = (bool) => {
        this.setState({ showModal: bool })
    }

    generateMailToLink = (inviteId) => {
        const invitation = this.props.invitations.find(invite => invite.get('id') === inviteId)
        const subject = 'Optimus Invitation'
        const body = `You have been invited to access the Optimus Dashboard. Accept your invitation at ${window.location.origin}/invitation/${inviteId}`
        window.open(`mailto:${invitation.get('email')}?subject=${subject}&body=${body}`)
    }

    copyInviteToClipboard = (inviteId) => {
        const inviteLink = `${window.location.origin}/invitation/${inviteId}`
        navigator.clipboard.writeText(inviteLink)
    }

    deleteInvitation = (inviteId) => {
        let deleteMe = window.confirm('Remove this invitation? This cannot be undone.')
        if (deleteMe) {
            this.props.dispatch(customModelAction(new InvitationModel(), apiRoutes.deleteInvitation, { id: inviteId }))
        }
    }

    renderInvitations = () => {
        if (!this.props.invitations) {
            return null
        }
        const columns = [
            {
                name: 'name',
                label: 'Name'
            },
            {
                name: 'email',
                label: 'Email'
            },
            {
                name: 'role',
                label: 'Role'
            },
            {
                name: 'status',
                label: 'Status'
            },
            {
                name: 'id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        return (
                            <div className="text-right">
                                <FontAwesomeIcon icon="envelope" title="Mail Invite" className="mx-3 pointer" onClick={() => this.generateMailToLink(value)} />
                                <FontAwesomeIcon icon="clipboard" title="Copy Invitation Link" className="mx-3 pointer" onClick={() => this.copyInviteToClipboard(value)} />
                                { (this.props.auth.get('role') === 'ADMIN' || this.props.auth.get('role') === 'OWNER') &&
                                    <FontAwesomeIcon icon="trash" title="Delete" className="mx-3 pointer" onClick={() => this.deleteInvitation(value)} />
                                }
                            </div>
                        )
                    }
                }
            }
        ]

        // Need to convert to an array and clean up
        let invitationArray = []
        this.props.invitations.forEach(invite => {
            let i = {
                name: invite.get('firstName'),
                email: invite.get('email'),
                role: invite.get('role').split('/')[1],
                status: invite.get('status').charAt(0).toUpperCase() + invite.get('status').slice(1).toLowerCase(),
                id: invite.get('id')
            }
            invitationArray.push(i)
        })

        return <MUIDataTable title="" data={invitationArray} columns={columns} options={options} />
    }

    render = () => {
        return (
            <div className="w-100">
                <h3 className="border-bottom">Pending Invitations
                    { (this.props.auth.get('role') === 'ADMIN' || this.props.auth.get('role') === 'OWNER') &&
                        <span className="a-style float-right btn btn-outline-secondary" onClick={() => this.handleModal(true)}>+ Invite New User</span>
                    }
                </h3>
                { this.state.showModal &&
                    <Modal onClose={() => this.handleModal(false)}>
                        <InviteUser clientId={this.props.clientId} onClose={() => this.handleModal(false)} />
                    </Modal>
                }
                <div className="mui-table">
                    { this.renderInvitations() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth'),
        invitations: state.get('invitations').filter((invite) => {
            return invite.get('clientId').indexOf(ownProps.clientId) !== -1 && invite.get('status') !== 'USED'
        })
    }
}

export default connect(mapStateToProps)(Invitations)
