import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

// actions
import { listModels } from '../../../actions/api-model'
import { buildRoutePath } from '../../../constants/routes'

// models
import ClientModel from '../../../services/models/client-model'

// components
import MUIDataTable from 'mui-datatables'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none'
}

class List extends React.Component {
    state = {
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(listModels(new ClientModel()))
    }

    renderClients = () => {
        const { clients, trucks, stations, routes } = this.props

        const columns = [
            {
                name: 'name',
                label: 'Name'
            },
            {
                name: 'primaryContact',
                label: 'Contact'
            },
            {
                name: 'truckCount',
                label: 'Trucks'
            },
            {
                name: 'stationCount',
                label: 'Stations'
            },
            {
                name: 'id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let link = buildRoutePath(routes.clientView, { clientId: value })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">View</NavLink>
                        )
                    }
                }
            }
        ]

        let clientArray = []
        if (clients) {
            clients.forEach((c) => {
                let stationCount = stations.filter(s => s.get('clients').indexOf(c.get('id')) > -1).count()
                let truckCount = trucks.filter(t => t.get('clients').indexOf(c.get('id')) > -1).count()
                let client = {
                    id: c.get('id'),
                    name: c.get('name'),
                    primaryContact: c.get('primaryContact'),
                    truckCount: truckCount,
                    stationCount: stationCount
                }
                clientArray.push(client)
            })
        }

        if (!clients || clients.size === 0) {
            return <label className="text-center w-100">No clients found.</label>
        }

        return (
            <MUIDataTable
                title=""
                data={clientArray}
                columns={columns}
                options={options}
            />
        )
    }

    render = () => {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h1>Client Overview</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mui-table">
                        { this.renderClients() }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        clients: state.get('clients'),
        stations: state.get('stations'),
        trucks: state.get('trucks')
    }
}

export default connect(mapStateToProps)(List)
