import immutable from 'immutable'

import { SAVE_LIVE_TRUCK_DATA, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_LIVE_TRUCK_DATA:
        if (action.model.faults) {
            objectIndex = utils.getObjectIndex(state, action.model.faults.truckId, 'truckId')
            if (objectIndex === -1) {
                return utils.addObject(state, action.model.faults)
            }
            return utils.updateObject(state, action.model.faults, objectIndex)
        }
        return state
    case `LIST_${SAVE_LIVE_TRUCK_DATA}`:
        action.models.forEach(model => {
            if (model.faults) {
                objectIndex = utils.getObjectIndex(newState, model.faults.truckId, 'truckId')
                if (objectIndex === -1) {
                    newState = utils.addObject(newState, model.faults)
                }
                newState = utils.updateObject(newState, model.faults, objectIndex)
            }
        })
        return newState
    case 'DELETE_TRUCK':
        return state.filter((fault) => fault.get('truckId') !== action.model.truckId)
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
