import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// actions
import { SAVE_LIVE_PRICES, SAVE_LIVE_TRANSACTIONS } from '../../../services/constants/actions'
import { watchFirestoreDocsSubCollection } from '../../../actions/api-model'
import { buildRoutePath } from '../../../constants/routes'

// components
// import BiodieselConsumed from '../../general/biodiesel-consumed'
import CoOffset from '../../general/co-offset'
import TransactionTable from '../../general/transaction-table'
import StationReportDownloadForm from '../../general/station-report-download-form'
import StationTransactionRefreshForm from '../../general/station-transactions-refresh-form'
import Modal from '../../utils/modal'
import WidgetLinearGauge from '../../general/widget-linear-gauge'
import WidgetCallout from '../../general/widget-callout'

// utils
import { formatDate, formatTotalWithUnit } from '../../utils/formatters'
import { offsetClients } from '../../../constants/offset-clients'

class View extends React.Component {
    state = {
        showReportModal: false,
        showTransModal: false,
        temp: null
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        let stationId = this.props.match.params.stationId
        dispatch(watchFirestoreDocsSubCollection('stations', stationId, 'transactions', SAVE_LIVE_TRANSACTIONS))
        dispatch(watchFirestoreDocsSubCollection('stations', stationId, 'prices', SAVE_LIVE_PRICES))
    }

    componentDidUpdate = (prevProps) => {
        const { dispatch } = this.props
        let stationId = this.props.match.params.stationId
        if (stationId !== prevProps.match.params.stationId) {
            dispatch(watchFirestoreDocsSubCollection('stations', stationId, 'transactions', SAVE_LIVE_TRANSACTIONS))
            dispatch(watchFirestoreDocsSubCollection('stations', stationId, 'prices', SAVE_LIVE_PRICES))
        }
    }

    handleReportModal = (bool) => {
        this.setState({ showReportModal: bool })
    }

    handleTransModal = (bool) => {
        this.setState({ showTransModal: bool })
    }

    renderTemp = () => {
        const { station } = this.props
        if (station) {
            if (station.get('id') === '4241' && station.get('P_TankTemp')) {
                return (
                    <>
                        <span>{ station.get('P_TankTemp').replace('degF', '') }</span>
                        <label>F</label>
                    </>
                )
            }
            if (station.get('S_Tank1_Temp')) {
                return (
                    <>
                        <span>{ station.get('S_Tank1_Temp').replace(' F', '') }</span>
                        <label>F</label>
                    </>
                )
            }
        }
        return <label>Not Found</label>
    }

    render = () => {
        // TODO: This whole view could probably be collapsed with the Admin view, moving stations to a watcher which handles access
        const { station, curPrice, routes, auth } = this.props

        if (!station) {
            return <div>Loading</div>
        }

        let editLink = routes.stationsEdit ? buildRoutePath(routes.stationsEdit, { stationId: station.get('id') }) : null
        let pricesLink = buildRoutePath(routes.stationsPriceIndex, { stationId: station.get('id') })
        let priceDisplay = curPrice ? `$${curPrice.get('pricePerGallon')}` : 'Not Set'
        let f = curPrice ? formatDate(curPrice.getIn(['startDate', '_seconds']) * 1000) : null
        let priceDate = f ? `as of ${f.month}/${f.day}/${f.year}` : null

        let updateDate = formatDate(station.get('updateDate').seconds * 1000)
        let ullage = station.get('S_Tank1_Ullage') ? parseFloat(station.get('S_Tank1_Ullage').replace(' gal', '')) : 0

        let volume = station.get('S_Tank1_TCVolume') ? parseFloat(station.get('S_Tank1_TCVolume').replace(' gal', '')) : 0
        let volumeLabel = 'TC Volume'

        if (station.get('Volume_Type') && station.get('Volume_Type') === 'Default') {
            volumeLabel = 'Volume'
            // Override based on station id to get diff value, Check if P_TankLevelVolume exists and use that instead if it does
            if (station.get('id') === '4241') {
                volume = station.get('P_TankLevelVolume') ? parseFloat(station.get('P_TankLevelVolume').replace(' gal', '')) : 0
                ullage = 2500 - volume
            } else {
                volume = station.get('S_Tank1_Volume') ? parseFloat(station.get('S_Tank1_Volume').replace(' gal', '')) : 0
            }
        }
        let totalPumped = station.get('NT_TotalFuel') ? Number(station.get('NT_TotalFuel').replace(' gal', '')) : 0
        if (station.get('Offset_NT_TotalFuel')) {
            totalPumped = totalPumped - parseFloat(station.get('Offset_NT_TotalFuel'))
        }
        return (
            <>
                <div className="row mb-4">
                    <div className="col-sm-12">
                        <NavLink to={routes.stationsIndex} className="btn-label">
                            <FontAwesomeIcon icon="angle-left" title="Go Back" className="mr-2" />
                            Back to Stations List
                        </NavLink>
                    </div>
                </div>
                { editLink &&
                    <div className="row">
                        <div className="col-sm-12">
                            <NavLink to={ editLink } className="h1">
                                { station.get('display') }
                                <FontAwesomeIcon icon="edit" title="Edit" />
                            </NavLink>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-4">
                        <WidgetLinearGauge
                            header={`Volume As of: ${updateDate.time}`}
                            max={ (ullage + volume) }
                            value={ volume }
                        >
                            <div><label>{ volumeLabel }:<strong className="blue">{`${volume} gals`}</strong></label></div>
                        </WidgetLinearGauge>
                    </div>
                    <div className="col-md-4">
                        <WidgetCallout header="Total Pumped" value={ formatTotalWithUnit(totalPumped) } label="gals" />
                    </div>
                    <div className="col-md-4">
                        <CoOffset station={station} clientId={auth.get('clientId')} overview={false} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <TransactionTable stationId={station.get('id')} />
                        <div className="row">
                            <div className="col-md-12 justify-content-end d-flex">
                                <div className="btn btn-outline-secondary btn-blue py-3 px-5 d-inline-block" onClick={() => this.handleTransModal(true)}>
                                    Fetch Transactions <sup>*</sup>
                                </div>
                                <div className="btn btn-outline-secondary btn-blue py-3 px-5 d-inline-block ml-2" onClick={() => this.handleReportModal(true)}>
                                    Generate Report
                                </div>
                                { editLink &&
                                    <NavLink to={editLink} className="btn btn-outline-secondary btn-blue py-3 px-5 d-inline-block ml-2">
                                        Edit Station
                                    </NavLink>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 justify-content-end d-flex mt-4">
                                <small><sup>*</sup> Transactions are updated every 15mins. This will fetch the latest or missing items if needed.</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <WidgetCallout header="Ullage" value={ullage} label="gals" />
                        <div className="widget">
                            <h3 className="border-bottom" onClick={() => this.props.history.push(pricesLink) }>
                                Price per Gallon
                                <FontAwesomeIcon icon="edit" role="presentation" className="float-right" />
                            </h3>
                            <div className="widget-wrapper">
                                <div className="callout">
                                    <span className="d-block">{ priceDisplay }</span>
                                    <label>{ priceDate }</label>
                                </div>
                            </div>
                        </div>
                        <div className="widget">
                            <h3 className="border-bottom">Tank Temperature</h3>
                            <div className="widget-wrapper">
                                <div className="callout">
                                    { this.renderTemp()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.showReportModal &&
                    <Modal onClose={() => this.handleReportModal(false)}>
                        <StationReportDownloadForm stationId={station.get('id')} onHandleModal={this.handleReportModal} />
                    </Modal>
                }
                { this.state.showTransModal &&
                    <Modal onClose={() => this.handleTransModal(false)}>
                        <StationTransactionRefreshForm stationId={station.get('id')} onHandleModal={this.handleTransModal} />
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let stationId = ownProps.match.params.stationId
    return {
        auth: state.get('auth'),
        stations: state.get('stations'),
        station: state.get('stations').find(s => s.get('id') === stationId),
        curPrice: state.get('prices').find(p => p.get('stationId') === stationId && p.get('isCurrent'))
    }
}

export default connect(mapStateToProps)(View)
