import immutable from 'immutable'

import { KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    switch (action.type) {
    case 'SAVE_WATCHER':
        objectIndex = utils.getObjectIndex(state, action.target)
        if (objectIndex === -1) {
            return utils.addObject(state, { id: action.target })
        }
        return state
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
