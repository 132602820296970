import uuidv4 from 'uuid/v4'
import immutable from 'immutable'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_STATION } from '../constants/actions'

class StationModel extends Model {
    _actionType = SAVE_STATION
    _collection = COLLECTIONS.STATION_COLLECTION
    _primaryKey = 'id'
    _endpoint = 'stations'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            usePrefix: true,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        clients: {
            type: 'array',
            isRequired: false
        },
        display: {
            type: 'string',
            isRequired: true
        },
        siteId: {
            type: 'string',
            isRequired: false
        },
        zipcode: {
            type: 'number'
        },
        NT_TotalFuel: {
            type: 'string',
            isRequired: false
        },
        Offset_NT_TotalFuel: {
            type: 'string',
            isRequired: false
        },
        Volume_Type: {
            type: 'string',
            isRequired: false
        },
        S_Tank1_Volume: {
            type: 'string',
            isRequired: false
        },
        S_Tank1_TCVolume: {
            type: 'string',
            isRequired: false
        },
        S_Tank1_Ullage: {
            type: 'string',
            isRequired: false
        },
        S_Tank1_Temp: {
            type: 'string',
            isRequired: false
        },
        lastTransactionId: {
            type: 'string',
            isRequired: false
        },
        curPricePerGallon: {
            type: 'number',
            isRequired: false
        },
        pricePerGallonStart: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        P_TankLevelVolume: {
            type: 'string',
            isRequired: false
        },
        P_TankTemp: {
            type: 'string',
            isRequired: false
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    validate() {
        console.log('in the Station Model validate function for stations')
    }
}

export default StationModel
