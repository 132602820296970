import React from 'react'
import { connect } from 'react-redux'

import ClientModel from '../../services/models/client-model'
import { findModel } from '../../actions/api-model'
import { getImageProportion } from '../utils/validate-image'

import ClientNav from './nav'
import Logo from '../../assets/imgs/OptimusTechnologies.svg'

class ClientHeader extends React.Component {
    state = {
        imageProportion: 'landscape'
    }

    componentDidMount = () => {
        this.props.dispatch(findModel(new ClientModel(), this.props.auth.get('clientId')))
    }

    render = () => {
        const { client } = this.props
        let imageSrc = Logo
        if (client && client.get('logo')) {
            imageSrc = client.get('logo')
            getImageProportion(imageSrc).then((result) => {
                if (this.state.imageProportion !== result) {
                    this.setState({ imageProportion: result })
                }
            })
        }
        return (
            <div id="header" className={`row px-4 ${this.state.imageProportion}`}>
                <div className="optimus-logo">
                    <img src={ imageSrc } alt="Optimus Technologies" />
                </div>
                <div className="navbar">
                    <ClientNav />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const auth = state.get('auth')
    return {
        auth,
        client: state.get('clients').find(stateClient => stateClient.get('id') === auth.get('clientId'))
    }
}

export default connect(mapStateToProps)(ClientHeader)
