import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { sharedRoutes } from '../../constants/routes'

export function AppRoute({ component: Component, auth, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => !auth.isEmpty() && !auth.get('isAnonymous')
                ? <Component auth={auth} {...props} />
                : <Redirect to={{ pathname: sharedRoutes.login, state: { from: props.location } }} />}
        />
    )
}

// TODO: make the auth check more restrictive
export function AdminRoute({ component: Component, auth, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => !auth.isEmpty() && auth.get('role') === 'ADMIN'
                ? <Component auth={auth} {...props} />
                : <Redirect to={{ pathname: sharedRoutes.login, state: { from: props.location } }} />}
        />
    )
}
