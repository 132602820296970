import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'

// constants
import { sharedRoutes, adminRoutes } from '../../constants/routes'

// actions
import { SAVE_LIVE_TRUCK_DATA, SAVE_STATION } from '../../services/constants/actions'
import { watchFirestoreDocsAdmin, watchFirestoreDocs } from '../../actions/api-model'

// component elements
import AdminHeader from './header'
import ApiLoader from '../api-loader/api-loader'

// component pages
import Dashboard from './dashboard'
import AdminClientIndex from './clients/index'
import EntityIndex from './entities/index'
import FleetIndex from './fleet/index'
import AccountIndex from '../shared/account/index'
import StationsIndex from '../shared/stations/index'
import PriceIndex from '../shared/stations/prices/index'

class AdminIndex extends React.Component {
    state = {

    }

    componentDidMount = () => {
        // Watch All My things
        this.props.dispatch(watchFirestoreDocsAdmin('trucks', 'SAVE_TRUCK'))
        // this.props.dispatch(watchFirestoreDocsAdmin('liveTrucks', SAVE_LIVE_TRUCK_DATA))
        // Watch all trucks BUT archived client
        this.props.dispatch(watchFirestoreDocs('liveTrucks', 'clientId', 'ef755dbe-5431-4ab1-9470-a6f9d8fba5e8', SAVE_LIVE_TRUCK_DATA, '!='))
        this.props.dispatch(watchFirestoreDocsAdmin('stations', SAVE_STATION))
    }


    render = () => {
        return (
            <div className="container-fluid">
                <AdminHeader auth={this.props.auth} />
                <ApiLoader />
                <div className="container mb-5">
                    <Switch>
                        <Route path={adminRoutes.entityIndex} render={props => <EntityIndex {...props} routes={adminRoutes} />} />
                        <Route path={adminRoutes.clientIndex} render={props => <AdminClientIndex {...props} routes={adminRoutes} />} />
                        <Route path={adminRoutes.stationsPriceIndex} render={props => <PriceIndex {...props} routes={adminRoutes} />} />
                        <Route path={adminRoutes.stationsIndex} render={props => <StationsIndex {...props} routes={adminRoutes} />} />
                        <Route path={adminRoutes.accountIndex} render={props => <AccountIndex {...props} routes={adminRoutes} />} />
                        <Route path={adminRoutes.fleetIndex} render={props => <FleetIndex {...props} routes={adminRoutes} />} />
                        <Route path={sharedRoutes.adminIndex} render={props => <Dashboard {...props} routes={adminRoutes} />} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default withRouter(connect()(AdminIndex))
