import { offsetClients } from '../../constants/offset-clients'
// const offsetClients = ['3ea16a0b-fe04-4892-9d8b-0887c8920490']

export function biodieselRuntimeCalc(truck, clientId) {
    let baseValues = truck.getIn(['report','bioDieselRuntime'])
    let offsetHoursBio = 0
    let offsetHoursDiesel = 0
    let offsetHoursLowFuel = 0
    let percent = 0

    // TODO: Set Pepsico ID, using a test ID right now
    if (clientId && offsetClients.indexOf(clientId) > -1) {
        offsetHoursBio = truck.get('Offset_Hours_Bio') ? truck.get('Offset_Hours_Bio') : 0
        offsetHoursDiesel = truck.get('Offset_Hours_Diesel') ? truck.get('Offset_Hours_Diesel') : 0
        offsetHoursLowFuel = truck.get('Offset_Hours_LowFuel') ? truck.get('Offset_Hours_LowFuel') : 0
    }
    let hoursBio = baseValues.get('hoursBio') - offsetHoursBio
    let hoursDiesel = baseValues.get('hoursDiesel') - offsetHoursDiesel
    let hoursLowFuel = baseValues.get('hoursLowFuel') - offsetHoursLowFuel
    let info = hoursLowFuel > 0 ? `Percentage excludes ${hoursLowFuel} low fuel hours.` : null

    if (hoursBio > 0 && hoursDiesel > 0) {
        percent = Math.floor((hoursBio / (hoursBio + (hoursDiesel - hoursLowFuel))) * 100)
        if (percent < 0 || percent > 100) {
            percent = 0
            info = 'There was an issue calculating this value.'
        }
    } else {
        hoursBio = 0
        hoursDiesel = 0
        percent = 0
        info = 'There was an issue calculating this value.'
    }

    return { hoursBio, hoursDiesel, hoursLowFuel, percent, info }
}
