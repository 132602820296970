import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { customModelAction } from '../../actions/api-model'
import { apiRoutes, buildRoutePath } from '../../constants/routes'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
// import TransactionModel from '../../services/models/transaction-model'
import SourcesModel from '../../services/models/sources-model'

class StationTransactionRefreshForm extends React.Component {
    state = {
        startDate: moment().format('YYYY-MM-DD')
    }

    refreshTransactions = (params) => {
        const { stationId } = this.props
        const routeObj = apiRoutes.refreshTransactions
        routeObj.url = buildRoutePath(routeObj.url, { stationId })
        this.props.dispatch(customModelAction(new SourcesModel(), routeObj, params, true))
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    successFX = () => {
        this.props.onHandleModal(false)
    }

    submitFX = (formData, formId) => {
        const { startDate } = this.state
        const payload = {
            stationId: this.props.stationId,
            start: startDate,
            formId
        }
        this.refreshTransactions(payload)
    }

    render = () => {
        return (
            <div>
                <h1>Refresh Station Transactions</h1>
                <p>
                    Transactions are updated every 15mins. This will fetch all transactions on a given day.<br />
                    Set to today to get the latest.<br />
                    <b>You may need to refresh this page to see the udpates.</b>
                </p>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Refresh" buttonMessage="Processing...">
                    <InputElement
                        type="date"
                        label="Start Date"
                        id="startDate"
                        default={this.state.startDate}
                        onChange={this.handleChange} />
                </SHSForm>
            </div>
        )
    }
}

export default connect()(StationTransactionRefreshForm)
