import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import List from './list'
import View from './view'

class FleetIndex extends React.Component {
    state = {

    }

    render = () => {
        const { routes } = this.props
        return (
            <Switch>
                <Route path={routes.fleetView} render={props => <View {...props} routes={routes} />} />
                <Route path={routes.fleetIndex} render={props => <List {...props} routes={routes} />} />
            </Switch>
        )
    }
}

export default withRouter(FleetIndex)
