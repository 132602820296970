import React from 'react'
import { NavLink } from 'react-router-dom'
import { buildRoutePath } from '../../constants/routes'

// components
import MUIDataTable from 'mui-datatables'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none'
}

class StationsTable extends React.Component {
    state = {

    }

    renderTable = () => {
        const { path, stations, isUnassigned } = this.props

        const unnassignedColumns = [
            {
                name: 'display',
                label: 'Station'
            },
            {
                name: 'station_id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let link = buildRoutePath(path, { stationId: value })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">Add Clients</NavLink>
                        )
                    }
                }
            }
        ]

        const columns = [
            {
                name: 'display',
                label: 'Station'
            },
            {
                name: 'gasPerc',
                label: 'Fuel Level %',
                options: {
                    customBodyRender: (value) => {
                        return `${value}%`
                    }
                }
            },
            {
                name: 'S_Tank1_Volume',
                label: 'Fuel Volume'
            },
            {
                name: 'S_Tank1_TCVolume',
                label: 'Fuel Volume (TC)'
            },
            {
                name: 'safeFill',
                label: 'Safe Fill Volume'
            },
            {
                name: 'station_id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let link = buildRoutePath(path, { stationId: value })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">View</NavLink>
                        )
                    }
                }
            }
        ]

        let stationArray = []
        if (stations) {
            stations.forEach((v) => {
                let gasPerc = 0
                let volume = 0
                if (v.get('S_Tank1_Ullage')) {
                    let ullage = parseFloat(v.get('S_Tank1_Ullage').replace(' gal', ''))
                    if (v.get('Volume_Type') && v.get('Volume_Type') === 'Default') {
                        volume = parseFloat(v.get('S_Tank1_TCVolume').replace(' gal', ''))
                        if (volume + ullage > 0) {
                            gasPerc = Math.ceil((volume / (ullage + volume)) * 100)
                        }
                    } else if (v.get('S_Tank1_TCVolume')) {
                        let tcVolume = parseFloat(v.get('S_Tank1_TCVolume').replace(' gal', ''))
                        if (tcVolume + ullage > 0) {
                            gasPerc = Math.ceil((tcVolume / (ullage + tcVolume)) * 100)
                        }
                    }
                }
                let t = {
                    station_id: v.get('id'),
                    display: v.get('display'),
                    gasPerc: gasPerc,
                    S_Tank1_TCVolume: v.get('S_Tank1_TCVolume'),
                    S_Tank1_Volume: v.get('P_TankLevelVolume') ? v.get('P_TankLevelVolume') : v.get('S_Tank1_Volume'),
                    safeFill: 'TBD'
                }
                if (v.get('id') === '4241') {
                    volume = v.get('P_TankLevelVolume') ? parseFloat(v.get('P_TankLevelVolume').replace(' gal', '')) : 0
                    t.gasPerc = Math.ceil((volume / 2500) * 100)
                    t.safeFill = 2500
                }
                stationArray.push(t)
            })
        }

        if (!stations || stations.size === 0) {
            return <label className="text-center w-100">No Stations</label>
        }

        return (
            <MUIDataTable
                title=""
                data={stationArray}
                columns={isUnassigned ? unnassignedColumns : columns}
                options={options}
            />
        )
    }

    render = () => {
        let label = this.props.label ? this.props.label : 'Stations'
        let cta = this.props.cta ? this.props.cta : null
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="widget">
                        <h3 className="border-bottom mb-3">
                            {label}
                            {cta}
                        </h3>
                        <div className="mui-table">
                            { this.renderTable() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StationsTable
