import React from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// actions
import { apiRoutes } from '../../../constants/routes'
import { customModelAction } from '../../../actions/api-model'

// models
import UserModel from '../../../services/models/user-model'

// components
import Modal from '../../utils/modal'
import MUIDataTable from 'mui-datatables'
import EditUser from './edit-user'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none'
}

class Users extends React.Component {
    state = {
        showModal: false,
        activeUserId: null
    }

    componentDidMount = () => {
        const { dispatch, clientId } = this.props
        dispatch(customModelAction(new UserModel(), apiRoutes.listUsers, { clientId }, true))
    }

    handleModal = (bool, activeUserId = null) => {
        this.setState({ showModal: bool, activeUserId: activeUserId })
    }

    confirmDelete = (authId) => {
        let deleteMe = window.confirm('Remove this user? This cannot be undone.')
        if (deleteMe) {
            const { dispatch } = this.props
            dispatch(customModelAction(new UserModel(), apiRoutes.deleteUser, { authId }))
        }
    }

    renderUserActions = (authIdRole) => {
        const { auth } = this.props
        const authId = authIdRole.split('~')[0]
        const role = authIdRole.split('~')[1]
        if (auth.get('role') === 'ADMIN' || (auth.get('role') === 'OWNER' && role !== 'ADMIN') || auth.get('authId') === authId) {
            // TODO: Set url path for admin vs owner
            return (
                <div className="table-actions text-right">
                    <FontAwesomeIcon
                        icon="edit"
                        title="Edit"
                        className="mx-3"
                        onClick={() => this.handleModal(true, authId)} />
                    <FontAwesomeIcon
                        icon="trash"
                        title="Delete"
                        className="mx-3"
                        onClick={() => this.confirmDelete(authId)} />
                </div>
            )
        }
        return null
    }

    renderUsers = () => {
        if (!this.props.users) {
            return null
        }

        const columns = [
            {
                name: 'firstName',
                label: 'Name'
            },
            {
                name: 'email',
                label: 'Email'
            },
            {
                name: 'roleId',
                label: 'Role'
            },
            {
                name: 'authIdRole',
                label: ' ',
                options: {
                    customBodyRender: (authIdRole) => {
                        return this.renderUserActions(authIdRole)
                    }
                }
            }
        ]

        // Need to convert to an array and clean up the IDs
        let userArray = []
        this.props.users.forEach(user => {
            let u = {
                firstName: user.get('firstName'),
                email: user.get('email'),
                roleId: user.get('roleId').split('/')[1],
                authIdRole: `${user.get('authId')}~${user.get('roleId').split('/')[1]}`
            }
            userArray.push(u)
        })

        return <MUIDataTable title="" data={userArray} columns={columns} options={options} />
    }

    render = () => {
        return (
            <>
                <div className="w-100">
                    <h3 className="border-bottom">Users</h3>
                    <div className="mui-table">
                        { this.renderUsers() }
                    </div>
                </div>
                { this.state.showModal &&
                    <Modal onClose={() => this.handleModal(false)}>
                        <EditUser userId={this.state.activeUserId} onClose={() => this.handleModal(false)} />
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth'),
        users: state.get('users').filter((user) => {
            return user.get('clientId').indexOf(ownProps.clientId) !== -1
        })
    }
}

export default connect(mapStateToProps)(Users)
