import React from 'react'
import { formatTotalWithUnit } from '../utils/formatters'

class BiodieselConsumed extends React.Component {
    state = {

    }

    calcValue = () => {
        const { stations, station } = this.props
        let value = 0
        if (stations) {
            value = stations.reduce((accum, t) => {
                let val = t.get('NT_TotalFuel') ? parseInt(t.get('NT_TotalFuel').replace(' gal').trim(), 10) : 0
                if (t.get('Offset_NT_TotalFuel')) {
                    val = val - parseInt(t.get('Offset_NT_TotalFuel'), 10)
                }
                let total = accum + val
                return total
            }, 0)
        } else if (station) {
            value = station.get('NT_TotalFuel') ? parseInt(station.get('NT_TotalFuel').replace(' gal').trim(), 10) : 0
            if (station.get('Offset_NT_TotalFuel')) {
                value = value - parseInt(station.get('Offset_NT_TotalFuel'), 10)
            }
        }
        // return Math.round(value).toLocaleString('en-US')
        return formatTotalWithUnit(value)
    }

    render = () => {
        return (
            <div className="widget">
                <h3 className="border-bottom">Biodiesel Consumed</h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ this.calcValue() }</span>
                        <label>Gallons</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default BiodieselConsumed
