import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

// actions
import { createModel } from '../../../../actions/api-model'
import { buildRoutePath } from '../../../../constants/routes'

// models
import PricesModel from '../../../../services/models/prices-model'

// utilities
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'

class Create extends React.Component {
    state = {

    }

    successFX = () => {
        const { station, routes } = this.props
        let path = buildRoutePath(routes.stationsPriceIndex, { stationId: station.get('id') })
        this.props.history.push(path)
    }

    submitFX = (formData, formId) => {
        const { station } = this.props
        let newPrice = new PricesModel()
        newPrice.hydrate(formData)
        newPrice.set('stationId', station.get('id'))
        this.props.dispatch(createModel(newPrice, formId))
    }

    render = () => {
        let today = moment().format('YYYY-MM-DD')
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 mb-5">
                        <h1 className="mt-4">Create a new fuel price entry</h1>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save">
                            <InputElement
                                type="text"
                                label="Price Per Gallon DDD.CCC"
                                id="pricePerGallon"
                                required />
                            <InputElement
                                type="date"
                                label="Start Date"
                                id="startDate"
                                default={today}
                                required />
                            <InputElement
                                type="date"
                                label="End Date (Leave blank if current price)"
                                id="endDate" />
                        </SHSForm>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        station: state.get('stations').find(s => s.get('id') === ownProps.match.params.stationId)
    }
}

export default connect(mapStateToProps)(Create)
