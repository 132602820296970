import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import { adminRoutes, clientRoutes } from '../../../../constants/routes'

import List from './list'
import Create from './create'
import Edit from './edit'

class PriceIndex extends React.Component {
    state = {

    }

    render = () => {
        const { routes } = this.props
        return (
            <Switch>
                <Route path={[clientRoutes.stationsPriceCreate, adminRoutes.stationsPriceCreate]} render={props => <Create {...props} routes={routes} />} />
                <Route path={[clientRoutes.stationsPriceEdit, adminRoutes.stationsPriceEdit]} render={props => <Edit {...props} routes={routes} />} />
                <Route path={[clientRoutes.stationsPriceIndex, adminRoutes.stationsPriceIndex]} render={props => <List {...props} routes={routes} />} />
            </Switch>
        )
    }
}

export default withRouter(PriceIndex)
