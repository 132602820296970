import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactMapGL, { Popup, Source, Layer } from 'react-map-gl'
import { apiRoutes } from '../../constants/routes'
import WebMercatorViewport from 'viewport-mercator-project'
import { Link } from 'react-router-dom'

// actions
import { customModelAction } from '../../actions/api-model'
import { buildRoutePath } from '../../constants/routes'

// models
import SourcesModel from '../../services/models/sources-model'

const pointLayer = {
    type: 'circle',
    paint: {
        'circle-radius': 8,
        'circle-color': '#0A6AAD'
    }
}

const lineLayer = {
    id: 'truckPath',
    type: 'line',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
    paint: {
        'line-color': '#B42222',
        'line-width': 4
    }
}

const mapStyles = {
    street: 'mapbox://styles/mapbox/light-v9',
    satellite: 'mapbox://styles/mapbox/satellite-v9'
}

class MapBlock extends React.Component {
    state = {
        dotLayers: null,
        truckDot: null,
        truckLine: null,
        popup: null,
        layerIds: [],
        start: 0,
        end: 60,
        refetch: false,
        viewport: {
            latitude: 38.91872,
            longitude: -76.987005,
            zoom: 14
        },
        mapStyle: 'street',
        mapStyleSource: mapStyles.street,
        hasLocation: false,
        hasInteracted: false
    }

    componentDidMount = () => {
        if (this.props.truckIds && this.props.truckIds.length > 0) {
            this.fetchTruckPositions()
        }
        // Defaulting to DC
        let newViewport = {
            ...this.state.viewport,
            latitude: 38.91872,
            longitude: -76.987005
        }

        if (this.props.zipcode) {
            fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.props.zipcode}.json?country=US&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`)
                .then(res => res.json())
                .then(json => {
                    if (json.features.length > 0) {
                        // set to client location, else will fallback to default
                        newViewport = {
                            ...this.state.viewport,
                            latitude: json.features[0].center[1],
                            longitude: json.features[0].center[0]
                        }
                    }
                    this.setState({ hasLocation: true, viewport: newViewport })
                })
        } else {
            // no zipcode set, go back to default
            this.setState({ viewport: newViewport })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.truckIds && this.props.truckIds.length > 0 && JSON.stringify(this.props.truckIds) !== JSON.stringify(prevProps.truckIds)) {
            this.fetchTruckPositions()
        }
        if (this.state.start !== prevState.start || this.state.refetch) {
            this.fetchTruckPositions()
            this.setState({ refetch: false })
        }
        if (this.props.positions && this.props.positions !== prevProps.positions) {
            this.parseTruckData()
        }
    }

    fetchTruckPositions = () => {
        // Updating to expect an array, even if just 1
        const { truckIds } = this.props
        let source = new SourcesModel()
        let params = {
            truckIds: truckIds,
            start: truckIds.length > 1 ? 0 : this.state.start,
            end: truckIds.length > 1 ? 1 : this.state.end
        }
        this.props.dispatch(customModelAction(source, apiRoutes.fetchTruckPositions, params, true))
    }

    parseTruckData = () => {
        const { truckIds, positions } = this.props
        let newViewport = { ...this.state.viewport }
        let dotLayers = []
        let lineLayers = []
        let avgLat = 0
        let avgLon = 0
        let layerIds = []
        if (truckIds.length > 1) {
            // Multiple Trucks: dots only
            let lats = []
            let lngs = []

            truckIds.forEach((id) => {
                let allPos = positions.filter((p) => {
                    return p.get('truckId') === id
                })
                // sort on time, we may only have 1 but maybe not?
                let tPos = allPos.sortBy(
                    (t) => t.get('time')
                )
                // get last one
                let lastPos = tPos.get(-1)
                if (lastPos) {
                    lats.push(lastPos.get('lat'))
                    lngs.push(lastPos.get('lon'))
                    avgLat = avgLat + lastPos.get('lat')
                    avgLon = avgLon + lastPos.get('lon')
                    let dotData = {
                        id: `dot-data-${id}`,
                        type: 'Point',
                        coordinates: [lastPos.get('lon'), lastPos.get('lat')]
                    }
                    layerIds.push(`dot-layer-${id}`)
                    dotLayers.push(<Source key={`dot-${id}`} type="geojson" data={dotData}><Layer id={`dot-layer-${id}`} {...pointLayer} /></Source>)
                }
            })

            let minlat = Math.min.apply(null, lats)
            let maxlat = Math.max.apply(null, lats)
            let minlng = Math.min.apply(null, lngs)
            let maxlng = Math.max.apply(null, lngs)
            if (!this.state.hasInteracted) {
                newViewport = new WebMercatorViewport({ width: 400, height: 400 })
                    .fitBounds([[minlng, minlat], [maxlng, maxlat]], {
                        padding: 20,
                        offset: [0, -100]
                    })
            }
            this.setState({ hasLocation: true, layerIds: layerIds, dotLayers: dotLayers, lineLayers: lineLayers, viewport: newViewport })
        } else {
            // Single Truck: dot and line
            truckIds.forEach((id) => {
                let allPos = positions.filter((p) => {
                    return p.get('truckId') === id
                })
                // sort on time, we may only have 1 but maybe not?
                let tPos = allPos.sortBy(
                    (t) => t.get('time')
                )
                // get last one for the dot
                let lastPos = tPos.get(-1)
                let dotData = {
                    id: `dot-data-${id}`,
                    type: 'Point',
                    coordinates: [lastPos.get('lon'), lastPos.get('lat')]
                }
                // parst the line data
                let posArray = []
                tPos.forEach((p) => {
                    posArray.push([p.get('lon'), p.get('lat')])
                })
                let lineData = {
                    id: `line-data-${id}`,
                    type: 'Feature',
                    properties: '',
                    geometry: {
                        type: 'LineString',
                        coordinates: posArray
                    }
                }
                if (!this.state.hasInteracted) {
                    newViewport = {
                        ...this.state.viewport,
                        latitude: lastPos.get('lat'),
                        longitude: lastPos.get('lon')
                    }
                }
                layerIds.push(`dot-layer-${id}`)
                dotLayers.push(<Source key={`dot-${id}`} type="geojson" data={dotData}><Layer id={`dot-layer-${id}`} {...pointLayer} /></Source>)
                lineLayers.push(<Source key={`line-${id}`} type="geojson" data={lineData}><Layer {...lineLayer} /></Source>)
            })
            // set state
            this.setState({ hasLocation: true, layerIds: layerIds, dotLayers: dotLayers, lineLayers: lineLayers, viewport: newViewport })
        }
    }

    handleViewportChange = (viewport) => {
        if (viewport.width !== 0) {
            this.setState({ viewport })
        }
    }

    handleMapClick = (info, event) => {
        const { fleetPath, trucks } = this.props
        let feature = this.refs.truckMap.queryRenderedFeatures(info.point, { layers: this.state.layerIds })
        if (feature[0] && feature[0].layer.id) {
            let fleetId = feature[0].layer.id.replace('dot-layer-', '')
            if (fleetId) {
                let truck = trucks.find(t => t.get('id') === fleetId)
                let link = buildRoutePath(fleetPath, { fleetId })
                let popup = (
                    <Popup
                        latitude={info.lngLat[1]}
                        longitude={info.lngLat[0]}
                        closeButton={true}
                        closeOnClick={false}
                        onClose={() => this.setState({popup: null})}
                        anchor="top"
                        dynamicPosition={false}>
                        <div className="text-center">
                            <div className="my-3">{truck.get('display')}</div>
                            <div className={truck.get('status').toLowerCase().replace(' ', '-')}>
                                <small>{truck.get('status')}</small>
                            </div>
                            <Link className="my-3 btn btn-blue" to={link}>View Truck</Link>
                        </div>
                    </Popup>
                )
                this.setState({ popup: popup })
            }
        }
    }

    toggleStyle = (mapStyle) => {
        this.setState({ mapStyle, mapStyleSource: mapStyles[mapStyle] })
    }

    handleMapInteraction = () => {
        if (!this.state.hasInteracted) {
            this.setState({ hasInteracted: true })
        }
    }

    addPositions = (type) => {
        if (type === 'older') {
            let newStart = this.state.end
            let newEnd = this.state.end + 60
            this.setState({ start: newStart, end: newEnd, refetch: true })
        } else {
            this.setState({ start: 0, end: 60, refetch: true })
        }
    }

    render = () => {
        return (
            <div className="map-wrapper">
                { this.state.hasLocation && <ReactMapGL
                    { ...this.state.viewport }
                    width="100%"
                    height="400px"
                    ref="truckMap"
                    onClick={(info, event) => {
                        this.handleMapClick(info, event)
                    }}
                    onInteractionStateChange = {(iState) => this.handleMapInteraction()}
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    mapStyle={this.state.mapStyleSource}
                    onViewportChange={ (viewport) => this.handleViewportChange(viewport) }
                >
                    { this.state.lineLayers }
                    { this.state.dotLayers }
                    { this.state.popup }
                </ReactMapGL> }
                { !this.state.hasLocation &&
                    <div className="map-placeholder">No Location data.</div>
                }
                <div className="btn-group btn-group-toggle w-100 mt-4" data-toggle="buttons">
                    <label className={`btn btn-outline-secondary ${this.state.mapStyle === 'street' ? 'active' : ''}`} onClick={() => this.toggleStyle('street')}>
                        <input type="radio" name="options" id="street" autoComplete="off" defaultChecked={this.state.mapStyle === 'street'} /> Street
                    </label>
                    <label className={`btn btn-outline-secondary ${this.state.mapStyle === 'satellite' ? 'active' : ''}`} onClick={() => this.toggleStyle('satellite')}>
                        <input type="radio" name="options" id="satellite" autoComplete="off" defaultChecked={this.state.mapStyle === 'satellite'} /> Satellite
                    </label>
                </div>
                <div className="w-100 mt-4 align-items-center justify-content-between d-flex" data-toggle="buttons">
                    <label>Load More:</label>
                    <div className="d-flex">
                        <label className="btn btn-outline-secondary mr-2 px-4" onClick={() => this.addPositions('older')}>
                            Older
                        </label>
                        <label className="btn btn-outline-secondary px-4" onClick={() => this.addPositions('newer')}>
                            Newer
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let positions = state.get('positions').filter((t) => {
        return ownProps.truckIds.indexOf(t.get('truckId')) > -1
    })
    return {
        positions: positions,
        trucks: state.get('trucks')
    }
}

export default withRouter(connect(mapStateToProps)(MapBlock))
