import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import List from './list'
import View from './view'
import Edit from './edit'

class StationsIndex extends React.Component {
    state = {

    }

    render = () => {
        const { routes } = this.props
        return (
            <Switch>
                { routes.stationsEdit && <Route path={routes.stationsEdit} render={props => <Edit {...props} routes={routes} />} /> }
                <Route path={routes.stationsView} render={props => <View {...props} routes={routes} />} />
                <Route path={routes.stationsIndex} render={props => <List {...props} routes={routes} />} />
            </Switch>
        )
    }
}

export default withRouter(StationsIndex)
