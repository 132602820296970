import React from 'react'
import { connect } from 'react-redux'

// models
import EmailPreferencesModel from '../../../services/models/email-preferences'

// actions
import { listModels, createModel, updateModel } from '../../../actions/api-model'

// utilities
import SHSForm from '../../utils/shs-form'
// import InputElement from '../../utils/input'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PREFERENCES = [
    {
        label: 'On Occurrence',
        key: 'Instant',
        description: 'Receive an email when a fault first occurs and when it is resolved.'
    },
    {
        label: 'Daily Report',
        key: 'Daily',
        description: 'A daily email with a report of the day\'s faults.'
    },
    {
        label: 'Weekly Report',
        key: 'Weekly',
        description: 'A weekly email sent on Sunday evenings with the previous week\'s faults.'
    },
    {
        label: 'Monthly Report',
        key: 'Monthly',
        description: 'An email sent on the first of every month with the previous month\'s faults.'
    },
    {
        label: 'No Notifications',
        key: 'NONE',
        description: 'You will not be notified by email of any faults.'
    }
]

class EmailPreferences extends React.Component {
    state = {
        inEdit: false,
        preferenceModel: new EmailPreferencesModel(),
        noTrucks: false,
        noStations: false
    }

    componentDidMount = () => {
        // let prefModel = new EmailPreferencesModel()
        this.props.dispatch(listModels(new EmailPreferencesModel()))
        const { preference } = this.props
        if (preference) {
            let preferenceModel = new EmailPreferencesModel()
            preferenceModel.hydrate(preference.toJSON())
            this.setState({ preferenceModel: preferenceModel })

            let noTrucks = true
            let noStations = true
            PREFERENCES.forEach(p => {
                if (preferenceModel.get('truck' + p.key)) {
                    noTrucks = false
                }
                if (preferenceModel.get('station' + p.key)) {
                    noStations = false
                }
            })
            this.setState({ noTrucks, noStations })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.preference && this.props.preference) {
            let preferenceModel = new EmailPreferencesModel()
            preferenceModel.hydrate(this.props.preference.toJSON())
            this.setState({ preferenceModel: preferenceModel })

            let noTrucks = true
            let noStations = true
            PREFERENCES.forEach(p => {
                if (preferenceModel.get('truck' + p.key)) {
                    noTrucks = false
                }
                if (preferenceModel.get('station' + p.key)) {
                    noStations = false
                }
            })
            this.setState({ noTrucks, noStations })
        }
    }

    handleSelection = (type, pref) => {
        let preferenceModel = this.state.preferenceModel
        if (pref === 'NONE') {
            PREFERENCES.forEach(p => {
                preferenceModel.set(type + p.key, false)
            })
            if (type === 'truck') {
                this.setState({ noTrucks: true })
            } else if (type === 'station') {
                this.setState({ noStations: true })
            }
        }
        if (preferenceModel.get(type + pref)) {
            preferenceModel.set(type + pref, false)
        } else {
            preferenceModel.set(type + pref, true)
        }

        this.setState({ preferenceModel: preferenceModel })

        // check to see if we need to override the NONE preference in the state
        let hasNone = true
        PREFERENCES.forEach(p => {
            if (preferenceModel.get(type + p.key)) {
                hasNone = false
            }
        })
        if (type === 'truck') {
            this.setState({ noTrucks: hasNone })
        } else if (type === 'station') {
            this.setState({ noStations: hasNone })
        }
    }

    compileOptions = (type) => {
        const { preferenceModel, noTrucks, noStations } = this.state
        let allOptions = []
        PREFERENCES.forEach((pref, idx) => {
            let icon = 'N/A'
            if (noTrucks && type === 'truck' && pref.key !== 'NONE') {
                icon = <FontAwesomeIcon icon={['far', 'square']} />
            } else if (noStations && type === 'station' && pref.key !== 'NONE') {
                icon = <FontAwesomeIcon icon={['far', 'square']} />
            } else if (noTrucks && type === 'truck' && pref.key === 'NONE') {
                icon = <FontAwesomeIcon icon={['far', 'check-square']} />
            } else if (noStations && type === 'station' && pref.key === 'NONE') {
                icon = <FontAwesomeIcon icon={['far', 'check-square']} />
            } else if (preferenceModel && preferenceModel.get(type + pref.key)) {
                icon = <FontAwesomeIcon icon={['far', 'check-square']} />
            } else {
                icon = <FontAwesomeIcon icon={['far', 'square']} />
            }
            allOptions.push(
                <div key={idx} className="radio-options">
                    <div className="option">
                        <span className="clickable" onClick={() => this.handleSelection(type, pref.key)}>
                            { icon }
                        </span>
                    </div>
                    <div className="info">
                        <span className="label">{pref.label}</span>
                        <p>{pref.description}</p>
                    </div>
                </div>
            )
        })
        return allOptions
    }

    successFX = () => {
        // just set the edit state back to false
        this.setState({ inEdit: false })
    }

    submitFX = (formData, formId) => {
        const { dispatch } = this.props
        const { preferenceModel } = this.state
        if (preferenceModel.get('id')) {
            let mJSON = preferenceModel.getJSON()
            // don't want to update the client and user ids...
            delete mJSON.clientId
            delete mJSON.userId
            dispatch(updateModel(preferenceModel, mJSON, formId))
        } else {
            dispatch(createModel(preferenceModel, formId))
        }
    }

    renderForm = () => {
        let allTruckOptions = this.compileOptions('truck')
        let allStationOptions = this.compileOptions('station')
        return (
            <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save" buttonMessage="Saving...">
                <div className="form-options">
                    <label className="border-bottom pb-2 my-3 w-100">Truck Preferences</label>
                    {allTruckOptions}
                    <label className="border-bottom pb-2 my-3 w-100">Station Preferences</label>
                    {allStationOptions}
                </div>
            </SHSForm>
        )
    }

    renderView = () => {
        const { preference } = this.props
        let truckPrefs = []
        let stationPrefs = []
        if (preference) {
            PREFERENCES.forEach(p => {
                if (preference.get('truck' + p.key)) {
                    truckPrefs.push(
                        <li key={`truck-${p.key}`}>{p.label}</li>
                    )
                }
                if (preference.get('station' + p.key)) {
                    stationPrefs.push(
                        <li key={`station-${p.key}`}>{p.label}</li>
                    )
                }
            })
        }
        return (
            <div className="email-prefs">
                <label className="border-bottom pb-2 my-3 w-100">Truck Preferences</label>
                { truckPrefs.length === 0 ? 'No Notifications' : <ul>{truckPrefs}</ul>}
                <label className="border-bottom pb-2 mb-3 mt-5 w-100">Station Preferences</label>
                { stationPrefs.length === 0 ? 'No Notifications' : <ul>{stationPrefs}</ul>}
                <span className="edit" onClick={() => this.setState({ inEdit: true })}>
                    <FontAwesomeIcon icon="pencil-alt" /> Edit Preferences
                </span>
            </div>
        )
    }

    render = () => {
        return (
            <div className="w-100">
                <h3 className="border-bottom">Email Notification Preferences</h3>
                <p className="py-2">Configure your preference for how frequently to receive email notifications for faults.</p>
                { this.state.inEdit
                    ? this.renderForm()
                    : this.renderView()
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const auth = state.get('auth')
    return {
        preference: state.get('emailPreferences').find(ep => ep.get('userId').indexOf(auth.get('authId') !== -1)),
        auth
    }
}

export default connect(mapStateToProps)(EmailPreferences)
