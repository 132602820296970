import React from 'react'
import { connect } from 'react-redux'

// models
import TruckModel from '../../../services/models/truck-model'

// utilities
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

// actions
import { updateModel } from '../../../actions/api-model'
import { upsertForm } from '../../../actions/form'

// constants
import { formStates } from '../../../constants/helper-states'

class ManageClientTrucks extends React.Component {
    state = {
        clientId: this.props.clientId,
        clientTruckList: this.props.clientTrucks
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.trucks !== prevProps.trucks) {
            let newClientTrucks = this.props.trucks.filter((truck) => {
                return (truck.get('clients') && truck.get('clients').indexOf(this.props.clientId) > -1)
            })
            this.setState({ clientTruckList: newClientTrucks })
        }
    }

    successFX = () => {
        this.props.onClose()
    }

    submitFX = (formData, formId) => {
        const { dispatch, clientId, trucks, client } = this.props
        let formReset = false
        let curTrucks = formData.trucks ? formData.trucks.split(',') : []

        // For all trucks
        trucks.forEach((truck) => {
            // list of all trucks client is currently on
            let updateTruck = false
            let hasClient = truck.has('clients') ? truck.get('clients').indexOf(clientId) !== -1 : false
            let inTruckList = curTrucks.indexOf(truck.get('id')) !== -1
            let clientList = truck.has('clients') ? truck.get('clients').toJSON() : []
            let fleetIdList = truck.has('fleetIds') ? truck.get('fleetIds').toJSON() : []

            let newModel = new TruckModel()
            newModel.set('id', truck.get('id'))
            if (hasClient && !inTruckList) {
                // was removed from list
                clientList = clientList.filter(c => c !== clientId)
                fleetIdList = fleetIdList.filter(f => f !== client.get('fleetId'))
                updateTruck = true
            } else if (!hasClient && inTruckList) {
                // was added to list
                clientList.push(clientId)
                if (client.get('fleetId')) {
                    fleetIdList.push(client.get('fleetId'))
                }
                updateTruck = true
            }

            if (updateTruck) {
                formReset = true
                dispatch(updateModel(newModel, { clients: clientList, fleetIds: fleetIdList }, formId))
            }
        })
        if (!formReset) {
            // We didn't dispatch anything, close the form
            dispatch(upsertForm(formId, formStates.SUCCESS, 'No trucks changed, close form'))
        }
    }


    render = () => {
        const { trucks } = this.props
        const { clientTruckList } = this.state

        if (!trucks) {
            return 'Loading...'
        }

        let defaultOptions = []
        if (clientTruckList) {
            clientTruckList.forEach(truck => {
                defaultOptions.push(truck.get('id'))
            })
        }

        let availableTruckOptions = []
        if (trucks) {
            trucks.forEach(truck => {
                availableTruckOptions.push({ value: truck.get('id'), label: truck.get('display') })
            })
        }

        return (
            <div className="px-5 pb-5">
                <h1 className="my-2 text-center">Add / Edit Client Trucks</h1>
                <p className="text-center">
                    Use the input field and dropdown to add trucks to a client.<br/>
                    Remove a truck by clicking the [X] on an existing item.
                </p>
                <hr/>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save">
                    <InputElement
                        type="searchMultiSelect"
                        label="Find Trucks"
                        id="trucks"
                        onChange={this.handleChange}
                        default={defaultOptions}
                        options={availableTruckOptions} />
                </SHSForm>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        client: state.get('clients').find(c => c.get('id') === ownProps.clientId),
        clientTrucks: state.get('trucks').filter((truck) => {
            return (truck.get('clients') && truck.get('clients').indexOf(ownProps.clientId) !== -1)
        }),
        trucks: state.get('trucks')
    }
}

export default connect(mapStateToProps)(ManageClientTrucks)
