import immutable from 'immutable'

export function getObjectIndex(state, objectId, field = 'id') {
    return state.findIndex((object)=>{
        return object.get(field) === objectId
    })
}

export function addObject(state, model) {
    return state.update((objects)=>{
        let object = new immutable.fromJS(model)
        return objects.push(object)
    })
}

export function updateObject(state, model, objectIndex) {
    return state.update(objectIndex, (object) => {
        return object.merge(immutable.fromJS(model))
    })
}

export function deleteObject(state, objectIndex) {
    return [ ...state.slice(0, objectIndex), ...state.slice(objectIndex + 1) ]
}
