import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import { adminRoutes } from '../../../constants/routes'

import Create from './create'
import View from './view'
// import Edit from './edit'
import List from './list'

class AdminClientIndex extends React.Component {
    state = {

    }

    render = () => {
        return (
            <Switch>
                <Route path={adminRoutes.clientCreate} render={props => <Create {...props} routes={adminRoutes} />} />
                {/*<Route path={adminRoutes.clientEdit} render={props => <Edit {...props} routes={adminRoutes} />} />*/}
                <Route path={adminRoutes.clientView} render={props => <View {...props} routes={adminRoutes} />} />
                <Route path={adminRoutes.clientIndex} render={props => <List {...props} routes={adminRoutes} />} />
            </Switch>
        )
    }
}

export default withRouter(AdminClientIndex)
