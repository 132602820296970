import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import SourcesModel from '../../services/models/sources-model'

import { customModelAction } from '../../actions/api-model'
import { apiRoutes, buildRoutePath } from '../../constants/routes'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

class ReportDownloadForm extends React.Component {
    state = {
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        reportType: 'csv',
        includeGPS: false,
        maxDate: moment().format('YYYY-MM-DD'),
        minDate: moment().subtract(6, 'months').format('YYYY-MM-DD')
    }

    componentDidUpdate = (prevProps, prevState) => {
        const startMoment = moment(this.state.startDate)
        const endMoment = moment(this.state.endDate)
        if (prevState.startDate !== this.state.startDate) {
            // check that end Date is within 7 days
            if (endMoment.isBefore(startMoment)) {
                this.setState({ endDate: startMoment.format('YYYY-MM-DD') })
            } else if (endMoment.diff(startMoment, 'days') > 7) {
                this.setState({ endDate: startMoment.add(6, 'days').format('YYYY-MM-DD') })
            }
        } else if (prevState.endDate !== this.state.endDate) {
            if (endMoment.isBefore(startMoment)) {
                this.setState({ startDate: endMoment.format('YYYY-MM-DD') })
            } else if (endMoment.diff(startMoment, 'days') > 7) {
                this.setState({ startDate: endMoment.subtract(6, 'days').format('YYYY-MM-DD') })
            }
        }
    }

    generateReport = (params) => {
        const routeObj = apiRoutes.downloadTruckReportFile
        routeObj.url = buildRoutePath(routeObj.url, { truckId: this.props.truckId })
        this.props.dispatch(customModelAction(new SourcesModel(), routeObj, params, true, true))
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    successFX = () => {
        this.props.onHandleModal(false)
    }

    submitFX = (formData, formId) => {
        const { startDate, endDate, reportType, includeGPS } = this.state
        const payload = {
            truckId: this.props.truckId,
            truckDisplay: this.props.truckDisplay,
            start: startDate,
            end: endDate,
            reportType,
            includeGps: includeGPS,
            formId
        }
        this.generateReport(payload)
    }

    render = () => {
        return (
            <div>
                <h1>Download Report Settings</h1>
                <p>
                    Use the form below to configure your preferences for downloading a report.<br />
                    Reports are limited to a 7 day period.<br />
                    Please note the report files are large and can take 1-2 minutes to download.
                </p>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Download Report" buttonMessage="Processing...">
                    <InputElement
                        type="date"
                        label="Start Date"
                        id="startDate"
                        default={this.state.startDate}
                        onChange={this.handleChange}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate} />
                    <InputElement
                        type="date"
                        label="End Date"
                        id="endDate"
                        default={this.state.endDate}
                        onChange={this.handleChange}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate} />
                    <InputElement
                        type="valueSelect"
                        label="Format"
                        id="reportType"
                        includeInitialValue={false}
                        onChange={this.handleChange}
                        options={[{ value: 'csv', label: 'Comma Separated Values (CSV)' }, { value: 'kmz', label: 'Google Earth File (KMZ)' }]} />
                    <InputElement
                        type="valueSelect"
                        label="Include GPS*"
                        id="includeGPS"
                        includeInitialValue={false}
                        onChange={this.handleChange}
                        options={[{ value: false, label: 'Do not include GPS data' }, { value: true, label: 'Yes, include GPS data' }]}
                        helperText=" * Only available for CSV formats." />
                </SHSForm>

            </div>
        )
    }
}

export default connect()(ReportDownloadForm)
