import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// actions
import { listModels, customModelAction } from '../../actions/api-model'
import { buildRoutePath, apiRoutes } from '../../constants/routes'

// models
import ClientModel from '../../services/models/client-model'
import EntitiesModel from '../../services/models/entity-model'

// components
import FleetTable from '../general/fleet-table'
import StationsTable from '../general/stations-table'
import EntityTable from '../general/entity-table'
import BiodieselConsumed from '../general/biodiesel-consumed'
import CoOffset from '../general/co-offset'

class Dashboard extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(listModels(new ClientModel()))
    }

    renderClients = () => {
        const { clients, routes } = this.props
        if (!clients || clients.size === 0) {
            return <div>No Clients</div>
        }
        return clients.map((client, i) => {
            let clientLink = buildRoutePath(routes.clientView, { clientId: client.get('id') })
            return (
                <NavLink key={i} to={ clientLink } className="btn-block">
                    <label className="d-flex align-items-center">
                        { client.get('name') }
                    </label>
                    <FontAwesomeIcon icon="caret-right" title="Go" />
                </NavLink>
            )
        })
    }

    fetchAllEntities = () => {
        let confirmfetch = window.confirm('Are you sure you want to fetch new entities from New Eagle? This process can take a few minutes.')
        if (confirmfetch) {
            const { dispatch } = this.props
            dispatch(customModelAction(new EntitiesModel(), apiRoutes.scrapeAllEntities, {}, true))
        }
    }

    render = () => {
        const { trucks, stations, routes, clients } = this.props
        let entityCta = <span className="a-style float-right btn btn-outline-secondary" onClick={() => this.fetchAllEntities()}>Fetch</span>
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        { stations && stations.size > 0 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <BiodieselConsumed stations={stations} />
                                </div>
                                <div className="col-md-6">
                                    <CoOffset stations={stations} overview={true} />
                                </div>
                            </div>
                        }
                        <FleetTable allClients={clients} label="Active Trucks" path={ routes.fleetView } vehicles={ trucks } />
                        <StationsTable label="Stations" path={routes.stationsView} stations={stations} />
                    </div>
                    <div className="col-lg-4">
                        <div className="widget">
                            <h3 className="border-bottom mb-4">Clients</h3>
                            { this.renderClients() }
                            <NavLink to={ routes.clientCreate } className="btn btn-outline-secondary btn-blue w-100 p-3 mt-4">
                                Add New Client
                            </NavLink>
                        </div>
                        <div className="widget">
                            <EntityTable cta={ entityCta } />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        clients: state.get('clients'),
        trucks: state.get('trucks'),
        stations: state.get('stations')
    }
}

export default connect(mapStateToProps)(Dashboard)
