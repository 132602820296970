import React from 'react'
import { NavLink } from 'react-router-dom'
import { buildRoutePath } from '../../constants/routes'

// components
import MUIDataTable from 'mui-datatables'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 100]
}

class FleetUnassignedTable extends React.Component {
    state = {

    }

    renderFleet = () => {
        const { path, vehicles } = this.props

        const columns = [
            {
                name: 'display',
                label: 'Vehicle'
            },
            {
                name: 'status',
                label: 'Status',
                options: {
                    customBodyRender: (value) => {
                        let c = value.toLowerCase().replaceAll(' ', '-')
                        return (<span className={c}>{value}</span>)
                    }
                }
            },
            {
                name: 'vehicle_id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let link = buildRoutePath(path, { fleetId: value })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">Edit</NavLink>
                        )
                    }
                }
            }
        ]

        let fleetArray = []
        if (vehicles) {
            vehicles.forEach((v) => {
                let t = {
                    vehicle_id: v.get('id'),
                    display: v.get('display'),
                    status: 'No Client Set'
                }
                fleetArray.push(t)
            })
        }

        if (!vehicles || vehicles.size === 0) {
            return <label className="text-center w-100">No Unassigned Vehicles.</label>
        }

        return (
            <MUIDataTable
                title=""
                data={fleetArray}
                columns={columns}
                options={options}
            />
        )
    }

    render = () => {
        let label = this.props.label ? this.props.label : 'Unassigned Vehicles'
        let cta = this.props.cta ? this.props.cta : null
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="widget">
                        <h3 className="border-bottom mb-3">
                            {label}
                            {cta}
                        </h3>
                        <div className="mui-table">
                            { this.renderFleet() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FleetUnassignedTable
