// other actions
import { upsertForm } from './form'
import moment from 'moment'

// constants
import { SAVE_API_CALL, CLEAR_API_CALL } from '../services/constants/actions'
import { firestore, firebaseStorageRefFromURL, firebaseUploadToStorage, firebaseCallableFX } from '../services/firebase/firebase'
import { formStates } from '../constants/helper-states'

export const saveModel = (type, model) => {
    return {
        type: type,
        model
    }
}

export const saveList = (type, models) => {
    return {
        type: `LIST_${type}`,
        models
    }
}

export const saveWatcher = (target) => {
    return {
        type: 'SAVE_WATCHER',
        target
    }
}

export const watchFirestoreDocs = (collection, field, value, action, op = '==') => {
    return dispatch => {
        return firestore.collection(collection).where(field, op, value)
            .onSnapshot(querySnapshot => {
                let models = []
                querySnapshot.docChanges().forEach(change => {
                    if (change.type === 'added' || change.type === 'modified') {
                        models.push(change.doc.data())
                    }
                    if (change.type === 'removed') {
                        dispatch(saveModel(`DELETE_${action}`, change.doc.data()))
                    }
                })
                dispatch(saveList(action, models))
            })
    }
}

export const watchFirestoreDocsAdmin = (collection, action) => {
    return dispatch => {
        return firestore.collection(collection)
            .onSnapshot(querySnapshot => {
                let models = []
                querySnapshot.docChanges().forEach(change => {
                    if (change.type === 'added' || change.type === 'modified') {
                        models.push(change.doc.data())
                    }
                    if (change.type === 'removed') {
                        dispatch(saveModel(`DELETE_${action}`, change.doc.data()))
                    }
                })
                dispatch(saveList(action, models))
            })
    }
}

// set on station view
// TODO: Do we actually need to scope this to a client (prices / transactions) ?
export const watchFirestoreDocsSubCollection = (collection, parentPk, subCollection, action) => {
    return (dispatch, getState) => {
        // store the watcher string as we don't need to recreate once in place
        let wString = `${collection}~${parentPk}~${subCollection}`
        let isActive = getState().get('watchers').find(w => w.get('id') === wString)
        if (!isActive) {
            return firestore.collection(collection).doc(parentPk).collection(subCollection)
                .onSnapshot(querySnapshot => {
                    dispatch(saveWatcher(wString))
                    let models = []
                    querySnapshot.docChanges().forEach(change => {
                        if (change.type === 'added' || change.type === 'modified') {
                            models.push(change.doc.data())
                        }
                        if (change.type === 'removed') {
                            dispatch(saveModel(`DELETE_${action}`, change.doc.data()))
                        }
                    })
                    dispatch(saveList(action, models))
                })
        }
        return null
    }
}


export const customModelAction = (model, urlObj, params, force = false, isFile = false) => {
    return async (dispatch, getState) => {
        // TODO: buildRoute here with params and object, pass in clean / base urlObj
        const url = urlObj.url
        if (force || shouldFetchApiData(getState().get('apiCaches'), url)) {
            dispatch(requestFetch(url))
            let result
            if (isFile) {
                result = await model.customFileAction(urlObj.url, urlObj.method, params)
            } else {
                result = await model.customAction(urlObj.url, urlObj.method, params)
            }
            if (typeof result === 'undefined' || result.hasError) {
                dispatch(fetchError(url, `Unable to fetch ${url}`))
            } else {
                dispatch(receiveFetch(url))
                if (isFile && result) {
                    dispatch(upsertForm(params.formId, formStates.SUCCESS))
                    return true
                }
                const data = result.data
                if (Array.isArray(data)) {
                    // If urlObj.action, use that instead
                    if (urlObj.action) {
                        dispatch(saveList(urlObj.action, data))
                    } else {
                        dispatch(saveList(model.getActionType(), data))
                    }
                } else if (urlObj.action) {
                    dispatch(saveModel(urlObj.action, data))
                } else {
                    dispatch(saveModel(model.getActionType(), data))
                }
                if (result && params.formId) {
                    dispatch(upsertForm(params.formId, formStates.SUCCESS))
                }
            }
        }
    }
}

export const requestModel = (model, route, method, params, fetchingId) => {

}

export const createModel = (model, formId) => {
    return async dispatch => {
        const result = await model.create(true)

        if (typeof result === 'undefined' || result.hasError) {
            dispatch(upsertForm(formId, formStates.ERROR, 'There was an error saving the data.'))
        } else {
            const data = result.data
            dispatch(upsertForm(formId, formStates.SUCCESS, data.id))
            dispatch(saveModel(model.getActionType(), data))
        }
    }
}

export const uploadImageToFBStorage = (uploadFile, clientId, existingUrl = null) => {
    if (existingUrl) {
        firebaseStorageRefFromURL(existingUrl).delete()
            .then(() => {
                return Promise.resolve(false)
            })
            .catch((err) => {
                console.log('Problem deleting the image: ', err)
            })
    }
    if (uploadFile === 'DELETE') {
        return Promise.resolve(false)
    }
    return firebaseUploadToStorage(clientId).child(uploadFile.name).put(uploadFile)
        .then((snapshot) => {
            return snapshot.ref.getDownloadURL()
                .then(downloadURL => {
                    return downloadURL
                })
                .catch(err => {
                    console.log('could not get site config download url: ', err)
                    return false
                })
        })
        .catch(error => {
            console.log('error uploading site config file to storage: ', error)
            return false
        })
}

export const updateModelWithFile = (model, payload, formId, fileData) => {
    return async dispatch => {
        if (!fileData) {
            delete payload.clientId
            delete payload.existingUrl
            delete payload.logoManualUpload
            return dispatch(updateModel(model, payload, formId))
        }
        return uploadImageToFBStorage(fileData, payload.clientId, payload.existingUrl)
            .then(imgPath => {
                if (imgPath === false) {
                    payload.logo = ''
                } else {
                    payload.logo = imgPath
                }
                delete payload.clientId
                delete payload.existingUrl
                delete payload.logoManualUpload
                dispatch(updateModel(model, payload, formId))
            })
            .catch(err => {
                console.log('what happened? ', err)
                dispatch(upsertForm(formId, formStates.ERROR, 'There was an error saving the file.'))
            })
    }
}

export const updateModel = (model, payload, formId, dispatchAction = true) => {
    return async dispatch => {
        const result = await model.update(model.get(model._primaryKey || 'id'), payload)
        if (typeof result === 'undefined' || result.hasError) {
            dispatch(upsertForm(formId, formStates.ERROR, 'There was an error saving the data.'))
        } else {
            const data = result.data
            dispatch(upsertForm(formId, formStates.SUCCESS, data.id))
            if (dispatchAction) {
                dispatch(saveModel(model.getActionType(), data))
            }
        }
    }
}

export const findModel = (model, pk) => {
    return async (dispatch, getState) => {
        const url = `${model._generateAPIURL()}${pk}/`
        if (shouldFetchApiData(getState().get('apiCaches'), url)) {
            dispatch(requestFetch(url))
            const result = await model.find(pk)
            if (typeof result === 'undefined' || result.error) {
                dispatch(fetchError(url, `Unable to fetch ${url}`))
            } else {
                const data = result.data
                dispatch(receiveFetch(url))
                dispatch(saveModel(model.getActionType(), data))
            }
        }
    }
}

export const listModels = (model) => {
    return async (dispatch, getState) => {
        const url = model._generateAPIURL()
        if (shouldFetchApiData(getState().get('apiCaches'), url)) {
            dispatch(requestFetch(url))
            const results = await model.list()
            if (typeof results === 'undefined' || results.hasError) {
                dispatch(fetchError(url, `Unable to fetch ${url}`))
            } else {
                const data = results.data
                dispatch(receiveFetch(url))
                dispatch(saveList(model.getActionType(), data))
            }
        }
    }
}

export const queryModel = (model, filters) => {
    return async (dispatch, getState) => {
        const query = '?' +
                Object.keys(filters).map(key => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
                }).join('&')
        const url = `${model._generateAPIURL()}${query}`
        if (shouldFetchApiData(getState().get('apiCaches'), url)) {
            dispatch(requestFetch(url))
            const result = await model.filter(filters)
            if (typeof result === 'undefined' || result.hasError) {
                dispatch(fetchError(url, `Unable to query ${url}`))
            } else {
                const data = result.data
                dispatch(receiveFetch(url))
                dispatch(saveList(model.getActionType(), data))
            }
        }
    }
}

const requestFetch = (routeUrl) => {
    return {
        type: SAVE_API_CALL,
        data: {
            url: routeUrl,
            isFetching: true,
            hasError: false
        }
    }
}

const receiveFetch = (routeUrl) => {
    return {
        type: SAVE_API_CALL,
        data: {
            url: routeUrl,
            isFetching: false,
            hasError: false,
            lastFetch: moment().format()
        }
    }
}

const fetchError = (routeUrl, error) => {
    return {
        type: SAVE_API_CALL,
        data: {
            url: routeUrl,
            isFetching: false,
            hasError: true,
            error
        }
    }
}

export const clearApiFetch = (routeUrl) => {
    return {
        type: CLEAR_API_CALL,
        data: {
            url: routeUrl
        }
    }
}

const shouldFetchApiData = (apiCaches, routeUrl) => {
    const cachedRoute = apiCaches.find(cache => cache.get('url') === routeUrl)
    if (!cachedRoute) {
        return true
    } else if (cachedRoute.get('isFetching')) {
        return false
    }
    const lastFetch = moment(cachedRoute.get('lastFetch'))
    const lapsedTime = moment().diff(lastFetch, 's', true)
    if (lapsedTime < 5) {
        return false
    }
    return true
}

export const fetchStationCsvOnDemand = () => {
    return dispatch => {
        firebaseCallableFX('fetchStationCsvOnDemand', {})
            .then(result => {
                console.log(result.data)
            })
            .catch(err => {
                console.log('ERROR', 'Error: ' + err.message)
            })
    }
}