import React from 'react'
// import moment from 'moment'
import { connect } from 'react-redux'

class FaultsListTable extends React.Component {
    renderFaults = (faults, label) => {
        let f = null
        let hasFault = false
        // TODO: Check against Low Fuel to pull correct label, check value exists
        if (faults && faults.size > 0) {
            f = faults.map((fault, i) => {
                // if label is 'Inactive', only show warnings
                if ((label === 'Inactive' && fault.get('fault_type') === 'warning') || !fault.get('fault_type')) {
                    return null
                }
                hasFault = true
                let initTime = fault.get('initTime') ? fault.get('initTime') : 'Unknown'
                return (
                    <div key={`${label}-fault-${i}`} className="widget-table-row mt-4">
                        <div className={`col-sm-6 d-block ${fault.get('fault_type') ? fault.get('fault_type').toLowerCase() : ''}`}>
                            <div className="bold mb-1">{ fault.get('label') }</div>
                            <div>{ fault.get('level') }</div>
                        </div>
                        <div className="col-sm-6">
                            {/* moment.unix(fault.get('time')).format('MMM D, h:mm A') */}
                            { initTime }
                        </div>
                    </div>
                )
            })
        }
        if (!hasFault) {
            f = <div className="widget-table-row mt-4"><div className="col-sm-12 bold">None</div></div>
        }
        return (
            <div className="col-md-6">
                <div className="widget">
                    <h3 className="border-bottom">{ label } Faults</h3>
                    { f }
                </div>
            </div>
        )
    }

    render = () => {
        let activeFaults = this.props.faults ? this.props.faults.get('activeFaults') : null
        let inActiveFaults = this.props.faults ? this.props.faults.get('inActiveFaults') : null
        return (
            <div className="row">
                { this.renderFaults(activeFaults, 'Active')}
                { this.renderFaults(inActiveFaults, 'Inactive')}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let truckId = ownProps.truckId
    let faults = state.get('faults').find(f => f.get('truckId') === truckId)
    return { truckId, faults }
}

export default connect(mapStateToProps)(FaultsListTable)
