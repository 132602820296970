import immutable from 'immutable'

import { SAVE_INVITATION, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_INVITATION:
        objectIndex = utils.getObjectIndex(newState, action.model.id)
        if (objectIndex === -1) {
            return utils.addObject(newState, action.model)
        }
        return utils.updateObject(newState, action.model, objectIndex)
    case `LIST_${SAVE_INVITATION}`:
        action.models.forEach(model => {
            objectIndex = utils.getObjectIndex(newState, model.id)
            if (objectIndex === -1) {
                newState = utils.addObject(newState, model)
            } else {
                newState = utils.updateObject(newState, model, objectIndex)
            }
        })
        return newState
    case 'DELETE_INVITATION':
        return newState.filter(s => s.get('id') !== action.model.id)
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
