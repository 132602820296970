import immutable from 'immutable'

import { SAVE_PRICE, SAVE_LIVE_PRICES, DELETE_PRICE, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_PRICE:
        if (action.model.isDeleted || !action.model.id) {
            return newState
        }
        objectIndex = utils.getObjectIndex(state, action.model.id)
        if (objectIndex === -1) {
            return utils.addObject(state, action.model)
        }
        return utils.updateObject(state, action.model, objectIndex)
    case `LIST_${SAVE_PRICE}`:
        action.models.forEach(model => {
            if (!model.isDeleted && model.id) {
                objectIndex = utils.getObjectIndex(newState, model.id)
                if (objectIndex === -1) {
                    newState = utils.addObject(newState, model)
                } else {
                    newState = utils.updateObject(newState, model, objectIndex)
                }
            }
        })
        return newState
    case `LIST_${SAVE_LIVE_PRICES}`:
        action.models.forEach(fbModel => {
            fbModel.createDate = { _seconds: fbModel.createDate.seconds, _nanoseconds: fbModel.createDate._nanoseconds }
            fbModel.startDate = { _seconds: fbModel.startDate.seconds, _nanoseconds: fbModel.startDate._nanoseconds }
            fbModel.updateDate = { _seconds: fbModel.updateDate.seconds, _nanoseconds: fbModel.updateDate._nanoseconds }
            fbModel.endDate = fbModel.endDate ? { _seconds: fbModel.endDate.seconds, _nanoseconds: fbModel.endDate._nanoseconds } : null
            objectIndex = utils.getObjectIndex(newState, fbModel.id)
            if (objectIndex === -1) {
                newState = utils.addObject(newState, fbModel)
            } else {
                newState = utils.updateObject(newState, fbModel, objectIndex)
            }
        })
        return newState
    case `UPDATE_TRANSACTION`:
        objectIndex = utils.getObjectIndex(newState, action.model.stationId, 'stationId')
        if (objectIndex > -1) {
            return newState.setIn([objectIndex, 'hasChanged'], false)
        }
        return newState
    case `DELETE_${SAVE_LIVE_PRICES}`:
        // If the delete comes from the watcher
        newState = newState.filter((i) => {
            return i.get('id') !== action.model.id
        })
        return newState
    case DELETE_PRICE:
        // If the delete comes locally, probably a soft delete
        newState = newState.filter((i) => {
            return i.get('id') !== action.model.id
        })
        return newState
    case 'DELETE_STATION':
        newState = newState.filter((i) => {
            return i.get('stationId') !== action.model.stationId
        })
        return newState
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
