import React from 'react'
import { connect } from 'react-redux'

// actions
import { findModel } from '../../../actions/api-model'

// models
import ClientModel from '../../../services/models/client-model'

// components
import ManageClientTrucks from './manage-client-trucks'
import ManageClientStations from './manage-client-stations'
import FleetTable from '../../general/fleet-table'
import StationsTable from '../../general/stations-table'
import Edit from '../../shared/account/edit'
import Details from '../../shared/account/details'
import ClientUsers from '../../shared/account/users'
import Invitations from '../../shared/account/invitations'
import MapBlock from '../../general/mapblock'
import BiodieselConsumed from '../../general/biodiesel-consumed'
import CoOffset from '../../general/co-offset'
import Modal from '../../utils/modal'


class ClientView extends React.Component {
    state = {
        clientId: this.props.match.params.clientId,
        showTruckModal: false,
        showStationModal: false,
        showEditModal: false
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(findModel(new ClientModel(), this.props.match.params.clientId))
    }

    handleModal = (target, bool) => {
        this.setState({ [target]: bool })
    }

    render = () => {
        const { client, trucks, stations, routes } = this.props
        if (!client) {
            return <h1>Loading</h1>
        }
        let truckIds = []
        if (trucks) {
            trucks.forEach((v) => {
                truckIds.push(v.get('id'))
            })
        }
        let truckCTA = <span className="a-style float-right btn btn-outline-secondary" onClick={() => this.handleModal('showTruckModal', true)}>Manage Trucks</span>
        let stationCTA = <span className="a-style float-right btn btn-outline-secondary" onClick={() => this.handleModal('showStationModal', true)}>Manage Stations</span>
        let editCTA = <span className="a-style float-right btn btn-outline-secondary" onClick={() => this.handleModal('showEditModal', true)}>Edit</span>
        return (
            <>
                <div>
                    <div className="row">
                        <div className="col-12">
                            <h1>Fleet Overview: {client.get('name')}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            { stations && stations.size > 0 &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <BiodieselConsumed stations={stations} />
                                    </div>
                                    <div className="col-md-6">
                                        <CoOffset stations={stations} overview={true} />
                                    </div>
                                </div>
                            }
                            <FleetTable clientId={client.get('id')} cta={truckCTA} vehicles={trucks} path={ routes.fleetView } />
                            <StationsTable cta={stationCTA} label="Active Stations" stations={stations} path={ routes.stationsView } />
                            <div className="row">
                                <div className="col-12">
                                    <ClientUsers clientId={client.get('id')} />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <Invitations clientId={client.get('id')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <h3>Fleet Locations</h3>
                            <MapBlock zipcode={client.get('zipcode')} truckIds={ truckIds } fleetPath={ routes.fleetView } />
                            <Details cta={editCTA} client={client} />
                        </div>
                    </div>
                </div>
                { this.state.showTruckModal &&
                    <Modal onClose={() => this.handleModal('showTruckModal', false)}>
                        <ManageClientTrucks clientId={client.get('id')} onClose={() => this.handleModal('showTruckModal', false)} />
                    </Modal>
                }
                { this.state.showStationModal &&
                    <Modal onClose={() => this.handleModal('showStationModal', false)}>
                        <ManageClientStations clientId={client.get('id')} onClose={() => this.handleModal('showStationModal', false)} />
                    </Modal>
                }
                { this.state.showEditModal &&
                    <Modal onClose={() => this.handleModal('showEditModal', false)}>
                        <Edit client={client} onClose={() => this.handleModal('showEditModal', false)} />
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const clientId = ownProps.match.params.clientId
    return {
        client: state.get('clients').find(client => client.get('id') === clientId),
        trucks: state.get('trucks').filter(t => t.get('clients').indexOf(clientId) !== -1),
        stations: state.get('stations').filter(s => s.get('clients').indexOf(clientId) !== -1)
    }
}

export default connect(mapStateToProps)(ClientView)
