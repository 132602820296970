import React from 'react'
import { connect } from 'react-redux'

// Utils
import { formatDate } from '../utils/formatters'

// actions
import { customModelAction } from '../../actions/api-model'
import { buildRoutePath, apiRoutes } from '../../constants/routes'

// models
import TransactionModel from '../../services/models/station-model'

// components
import MUIDataTable from 'mui-datatables'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    rowsPerPage: 10,
    sortOrder: {
        name: 'NT_TransactionID',
        direction: 'asc'
    },
    customSort: (data, colIndex, order, meta) => {
        if (colIndex === 0 || colIndex === 1) {
            return data.sort((a, b) => {
                return (Number(a.data[colIndex]) < Number(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1)
            })
        }
        if (colIndex === 2) {
            return data.sort((a, b) => {
                return (Number(a.data[colIndex].replace(' gal', '')) < Number(b.data[colIndex].replace(' gal', '')) ? -1 : 1) * (order === 'desc' ? 1 : -1)
            })
        }
        if (colIndex === 3) {
            return data.sort((a, b) => {
                return (Number(a.data[colIndex].replace('$', '')) < Number(b.data[colIndex].replace('$', '')) ? -1 : 1) * (order === 'desc' ? 1 : -1)
            })
        }
        return data.sort((a, b) => {
            return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1)
        })
    }
}

class TransactionTable extends React.Component {
    state = {
        init: false
    }

    componentDidUpdate = (prevProp) => {
        if (!this.state.init) {
            this.setState({ init: true })
        }
    }

    shouldComponentUpdate = (prevProps, prevState) => {
        if (this.props.stationId !== prevProps.stationId || !this.state.init) {
            return true
        }
        let update = false
        this.props.transactions.forEach((t, idx) => {
            let match = prevProps.transactions.get(idx)
            if (!t.equals(match)) {
                update = true
            }
        })
        this.props.prices.forEach((p, idx) => {
            let oldPrice = prevProps.prices.get(idx)
            if (!p.equals(oldPrice)) {
                update = true
            }
        })
        return update
    }

    renderTransactions = () => {
        const { transactions } = this.props

        if (!transactions) {
            return 'No Transactions Found'
        }

        const columns = [
            {
                name: 'NT_TransactionID',
                label: 'ID'
            },
            {
                name: 'NT_TransactionVehID',
                label: 'Vehicle ID'
            },
            {
                name: 'NT_TransactionFuelAmount',
                label: 'Fuel Amount'
            },
            {
                name: 'transactionCost',
                label: 'Cost',
                options: {
                    customBodyRender: (value) => {
                        return value ? `$${value}` : '--'
                    }
                }
            },
            {
                name: 'dateValue',
                label: 'Date',
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            let dateObj = formatDate(value * 1000)
                            return `${dateObj.month}/${dateObj.day}/${dateObj.year} ${dateObj.time}`
                        }
                        return 'Not Set'
                    }
                }
            }
        ]

        let itemArray = []
        if (transactions) {
            transactions.forEach((v) => {
                let t = {
                    NT_TransactionID: v.get('NT_TransactionID'),
                    NT_TransactionVehID: v.get('NT_TransactionVehID'),
                    NT_TransactionFuelAmount: v.get('NT_TransactionFuelAmount'),
                    dateValue: v.getIn(['dateValue', '_seconds']) ? v.getIn(['dateValue', '_seconds']) : null,
                    transactionCost: v.get('transactionCost')
                }
                itemArray.push(t)
            })
        }

        let sortedItemArray = itemArray.sort((a, b) => {
            return Number(a.NT_TransactionID) > Number(b.NT_TransactionID) ? -1 : 1
        })

        return (
            <MUIDataTable
                title=""
                data={sortedItemArray}
                columns={columns}
                options={options}
            />
        )
    }

    render = () => {
        const { label } = this.props
        // const { label, hasChanged } = this.props
        // const routeObj = apiRoutes.updateTransactions

        let cta = null
        // if (hasChanged && hasChanged.size > 0) {
        //     routeObj.url = buildRoutePath(routeObj.url, { stationId: this.props.stationId })
        //     let t = new TransactionModel()
        //     t.set('stationId', this.props.stationId)
        //     cta = <button className="btn btn-red" onClick={(() => {this.props.dispatch(customModelAction(t, routeObj, {}, true))})}>Prices have changed, Update Transactions</button>
        // }
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="widget">
                        <h3 className="border-bottom mb-3 d-flex justify-content-between">
                            {label || 'Transactions'}
                            {cta}
                        </h3>
                        <div className="mui-table">
                            { this.renderTransactions() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        transactions: state.get('transactions').filter(t => t.get('stationId') === ownProps.stationId),
        prices: state.get('prices').filter(price => price.get('stationId') === ownProps.stationId),
        hasChanged: state.get('prices').filter(price => {
            return price.get('hasChanged')
        })
    }
}

export default connect(mapStateToProps)(TransactionTable)
