import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { sharedRoutes } from '../../constants/routes'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import Lottie from 'react-lottie'
import * as logoAnim from '../../assets/json/optimusLogo.json'

import { signInWithEmailAndPass } from '../../actions/auth'

class Login extends React.Component {
    state = {
        isStopped: true
    }

    componentDidMount = () => {
        this.timer = setTimeout(() => {
            this.setState({ isStopped: false })
        }, 1000)
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer)
    }

    successFX = () => {
        // do nothing
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(signInWithEmailAndPass(formData.email, formData.password, formId))
    }

    render = () => {
        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: logoAnim.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return (
            <div className="container">
                <div className="row pt-5">
                    <div className="col-4 offset-4">
                        <Lottie
                            options={defaultOptions}
                            isStopped={this.state.isStopped}
                            ref={animation => {
                                this.animation = animation
                            }}/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-8 p-4">
                        <div className="input-values">
                            <SHSForm successFX={this.successFX} submitFX={this.submitFX} buttonLabel="Login">
                                <InputElement
                                    type="email"
                                    id="email"
                                    label="Email Address"
                                    placeholder="lightening.mcqueen@drivefast.com"
                                    extraClass="flex-100"
                                    required />
                                <InputElement
                                    type="password"
                                    id="password"
                                    label="Password"
                                    placeholder="Something secure that only you know..."
                                    extraClass="flex-100 mb-5"
                                    required />
                            </SHSForm>
                            <div className="w-100 mt-4">
                                <p className="text-center">
                                    <small className="font-weight-light">
                                        Login problems? <Link to={sharedRoutes.passwordHelp}>Get Password Help</Link>.
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(Login)
