import immutable from 'immutable'

import { SAVE_TRUCK_REPORT, SAVE_LIVE_TRUCK_DATA, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_TRUCK_REPORT:
        const data = { id: action.model.truckId, report: action.model }
        objectIndex = utils.getObjectIndex(state, action.model.truckId)
        if (objectIndex === -1) {
            return utils.addObject(state, data)
        }
        console.log('Save Truck Report')
        return utils.updateObject(state, data, objectIndex)
    case SAVE_LIVE_TRUCK_DATA:
        let truck = {
            id: action.model.truckId,
            display: action.model.display,
            status: action.model.status,
            report: action.model.report,
            Offset_Hours_Diesel: action.model.Offset_Hours_Diesel,
            Offset_Hours_DPF: action.model.Offset_Hours_DPF,
            Offset_Hours_Bio: action.model.Offset_Hours_Bio,
            Offset_Hours_LowFuel: action.model.Offset_Hours_LowFuel,
            vehicleId: action.model.vehicleId,
            lastContact: action.model.lastContact,
            updateDate: action.model.updateDate
        }
        objectIndex = utils.getObjectIndex(state, action.model.truckId)
        if (objectIndex === -1) {
            truck.clients = [action.model.clientId]
            return utils.addObject(newState, truck)
        }
        let existingClientIds = state.getIn([objectIndex, 'clients'])
        let clientIndex = existingClientIds.indexOf(action.model.clientId)
        if (clientIndex === -1) {
            truck.clients = [...existingClientIds, action.model.clientId]
        } else {
            truck.clients = [...existingClientIds]
        }
        return utils.updateObject(newState, truck, objectIndex)
    case `LIST_${SAVE_LIVE_TRUCK_DATA}`:
        action.models.forEach(model => {
            let truckMulti = {
                id: model.truckId,
                display: model.display,
                status: model.status,
                report: model.report,
                Offset_Hours_Diesel: model.Offset_Hours_Diesel,
                Offset_Hours_DPF: model.Offset_Hours_DPF,
                Offset_Hours_Bio: model.Offset_Hours_Bio,
                Offset_Hours_LowFuel: model.Offset_Hours_LowFuel,
                vehicleId: model.vehicleId,
                lastContact: model.lastContact,
                updateDate: model.updateDate
            }
            objectIndex = utils.getObjectIndex(newState, model.truckId)
            if (objectIndex === -1) {
                truckMulti.clients = [model.clientId]
                newState = utils.addObject(newState, truckMulti)
            } else {
                let existingClientIdsMulti = newState.getIn([objectIndex, 'clients'])
                let clientIndexMulti = existingClientIdsMulti.indexOf(model.clientId)
                if (clientIndexMulti === -1) {
                    truckMulti.clients = [...existingClientIdsMulti, model.clientId]
                } else {
                    truckMulti.clients = [...existingClientIdsMulti]
                }
                newState = utils.updateObject(newState, truckMulti, objectIndex)
            }
        })
        return newState
    case `DELETE_${SAVE_LIVE_TRUCK_DATA}`:
    case 'DELETE_TRUCK':
        return state.filter((stateTruck) => stateTruck.get('id') !== action.model.truckId)
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
