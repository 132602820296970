import immutable from 'immutable'

import {
    SAVE_AUTH_HANDLER, AUTH_HANDLER_ERROR, REMOVE_AUTH_HANDLER, KILL_STATE
} from '../services/constants/actions'

const INITIAL_STATE = immutable.fromJS({})

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case SAVE_AUTH_HANDLER:
        return state
            .set('authCode', action.code)
            .set('authAction', action.action)
            .set('authTempEmail', action.email)
    case AUTH_HANDLER_ERROR:
        return state
            .set('hasError', true)
    case REMOVE_AUTH_HANDLER:
        return INITIAL_STATE
    case KILL_STATE:
        return immutable.fromJS({})
    default:
        return state
    }
}
