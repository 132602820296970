import React from 'react'
import { connect } from 'react-redux'

class Details extends React.Component {
    state = {}

    renderField = (label, field) => {
        let display = field ? field : 'Not Set'
        return (
            <div>
                <label>{ label }</label>
                <p>{ display }</p>
            </div>
        )
    }

    render = () => {
        const { client, auth } = this.props
        let editCTA = null
        if (auth.get('role') === 'ADMIN' || auth.get('role') === 'OWNER') {
            // only show edit to admin and owners
            editCTA = this.props.cta
        }
        return (
            <div className="client-details">
                <h3 className="border-bottom">
                    Details
                    { editCTA }
                </h3>
                { this.renderField('Client Name', client.get('name')) }
                { this.renderField('Fleet ID', client.get('fleetId')) }
                { this.renderField('Primary Contact', client.get('primaryContact')) }
                { this.renderField('Phone Number', client.get('phone')) }
                { this.renderField('Zipcode', client.get('zipcode')) }
                { this.renderField('Website', client.get('website')) }
                { this.renderField('Notes', client.get('notes')) }
                <div>
                    <label>Logo</label>
                    <p>{client.get('logo') ? <img className="client-image" src={client.get('logo')} alt="client logo" /> : <i>Not Set</i>}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth')
    }
}


export default connect(mapStateToProps)(Details)
