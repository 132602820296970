import React from 'react'
import { connect } from 'react-redux'

// components / helpers
import { formatTotalWithUnit } from '../../utils/formatters'
import { biodieselRuntimeCalc } from '../../utils/client-offset'
import FleetTable from '../../general/fleet-table'
import InfoMark from '../../utils/info-mark'
import { get } from 'lodash'

class List extends React.Component {
    state = {

    }

    renderCustomRuntime = () => {
        const { vehicles, auth } = this.props
        if (!vehicles) {
            return null
        }
        let hours = vehicles.reduce((prev, cur, index) => {
            // NOTE: use offset if exists
            let values = biodieselRuntimeCalc(cur, auth.get('clientId'))
            if (values.hoursDiesel) {
                return prev + values.hoursDiesel
            }
            return prev
        }, 0)

        return (
            <div className="widget">
                <h3 className="border-bottom">Biodiesel Runtime</h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ formatTotalWithUnit(hours) }</span>
                        <label>Hours</label>
                    </div>
                </div>
            </div>
        )
    }

    renderCustomRuntimePer = () => {
        const { vehicles, auth } = this.props
        if (!vehicles) {
            return null
        }

        let totalBio = 0
        let totalDiesel = 0
        let totalLowFuel = 0
        let percent = 0
        vehicles.forEach(v => {
            // NOTE: use offset if exists
            let values = biodieselRuntimeCalc(v, auth.get('clientId'))
            totalLowFuel = totalLowFuel + values.hoursLowFuel
            if (values.hoursBio && values.hoursDiesel) {
                totalBio = totalBio + values.hoursBio
                totalDiesel = totalDiesel + (values.hoursDiesel - values.hoursLowFuel)
            }
        })

        let info = `Percentage excludes ${totalLowFuel} low fuel hours.`
        if (totalBio > 0 && totalDiesel > 0) {
            percent = Math.floor((totalBio / (totalBio + (totalDiesel - totalLowFuel))) * 100)
            if (percent < 0 || percent > 100) {
                percent = 0
                info = 'There was an issue calculating this value.'
            }
        } else {
            totalBio = 0
            totalDiesel = 0
            percent = 0
            info = 'There was an issue calculating this value.'
        }

        return (
            <div className="widget">
                <h3 className="border-bottom">Percentage Biodiesel Runtime <InfoMark pos="left" info={info} /></h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ percent }</span>
                        <label>%</label>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        const { vehicles, routes, auth } = this.props
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        { this.renderCustomRuntime() }
                    </div>
                    <div className="col-md-6">
                        { this.renderCustomRuntimePer() }
                    </div>
                </div>
                <FleetTable label="Vehicles" vehicles={vehicles} path={routes.fleetView} clientId={auth.get('clientId')} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth'),
        vehicles: state.get('trucks')
    }
}

export default connect(mapStateToProps)(List)
