import React from 'react'
import immutable from 'immutable'
import moment from 'moment'

import WidgetLinearGauge from './widget-linear-gauge'

class LinearFilterDifferential extends React.Component {
    state = {

    }

    render = () => {
        const { report } = this.props
        if (!report) {
            return null
        }
        let values = report.get('filterDifferential') ? report.get('filterDifferential') : immutable.fromJS({ value: 0, diff: 0, time: null })
        let updateTime = values.get('time') ? moment.unix(values.get('time').seconds).format('MMM D, h:mm A') : 'N/A'
        return (
            <WidgetLinearGauge
                value={values.get('value')}
                minLabel="0"
                maxLabel={`${values.get('diff')} psi`}
                max={values.get('diff')}
                header="Filter Differential"
            >
                <div><label>{ `${updateTime}:` }<strong className="blue">{`${values.get('value')} psi` }</strong></label></div>
            </WidgetLinearGauge>
        )
    }
}

export default LinearFilterDifferential
