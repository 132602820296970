import React from 'react'
import { connect } from 'react-redux'

import AdminNav from './nav'
import Logo from '../../assets/imgs/OptimusTechnologies.svg'

class AdminHeader extends React.Component {
    render = () => {
        return (
            <div id="header" className="row px-4">
                <div className="optimus-logo">
                    <img src={ Logo } alt="Optimus Technologies" />
                </div>
                <div className="navbar">
                    <AdminNav />
                </div>
            </div>
        )
    }
}

export default connect(null)(AdminHeader)
