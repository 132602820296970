import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

// actions
import { apiRoutes, adminRoutes, clientRoutes } from '../../../constants/routes'
import { updateModel, listModels, customModelAction } from '../../../actions/api-model'

// constants
import { buildRoutePath } from '../../../constants/routes'

// models
import StationModel from '../../../services/models/station-model'
import ClientModel from '../../../services/models/client-model'

// utilities
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import ModelInput from '../../utils/model-input'

class Edit extends React.Component {
    state = {
        station: null,
        stationClients: [],
        deleted: false
    }

    componentDidMount = () => {
        const { dispatch, station } = this.props
        if (station) {
            let stationModel = new StationModel()
            stationModel.hydrate(station.toJSON())
            this.setState({ station: stationModel })
        }
        dispatch(listModels(new ClientModel()))
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.station && prevProps.station !== this.props.station) {
            let stationModel = new StationModel()
            stationModel.hydrate(this.props.station.toJSON())
            this.setState({ station: stationModel })
        } else if (!this.props.station && prevProps.station) {
            this.setState({ deleted: true })
        }
    }

    successFX = (stationId) => {
        const { routes } = this.props
        let path = buildRoutePath(routes.stationsView, { stationId })
        this.props.history.push(path)
    }

    submitFX = (formData, formId) => {
        const { station } = this.state
        station.hydrate(formData)
        formData.id = this.props.stationId
        formData.clients = formData.clients && formData.clients !== '' ? formData.clients.split(',') : []
        this.props.dispatch(updateModel(station, formData, formId, false))
    }

    confirmDelete = () => {
        const { stationId } = this.props
        if (!stationId) {
            // shoudlnt happen but catch in case, should we alert the user?
            return null
        }
        let deleteMe = window.confirm('Delete this Station? This will remove all associated data and cannot be undone.')
        if (deleteMe) {
            let deleteMeCheck = window.confirm('Just to confirm, this cannot be undone. Continue?')
            if (deleteMeCheck) {
                const { dispatch } = this.props
                const routeObj = apiRoutes.deleteStation
                routeObj.url = buildRoutePath(routeObj.url, { stationId })
                let stationModel = new StationModel()
                dispatch(customModelAction(stationModel, routeObj, {}, true))
            }
        }
        return null
    }

    renderDelete = () => {
        const { auth } = this.props

        if (auth.get('role') === 'ADMIN') {
            return (
                <>
                    <hr className="mt-4" />
                    <div className="d-flex flex-100 w-100 justify-right">
                        <span className="a-style" onClick={ this.confirmDelete }>Delete</span>
                    </div>
                </>
            )
        }
        return null
    }

    render = () => {
        const { station } = this.state
        const { auth, clients } = this.props

        if (this.state.deleted) {
            if (auth.get('role') === 'ADMIN') {
                return <Redirect to={adminRoutes.stationsIndex} />
            }
            return <Redirect to={clientRoutes.stationsIndex} />
        }

        let clientOptions = []
        if (clients) {
            clients.forEach((client, idx) => {
                clientOptions.push({ value: client.get('id'), label: client.get('name') })
            })
        }

        return (
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 mb-5">
                    <h1 className="mt-4">Edit Station</h1>
                    { !station && <div>Fetching Station Data.</div>}
                    { station &&
                        <>
                            <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save Changes">
                                <ModelInput
                                    model={station}
                                    field="display"
                                    label="Station Name"
                                    placeholder="Acme Fleet Station 1" />
                                <ModelInput
                                    model={station}
                                    field="siteId"
                                    label="Site ID"
                                    placeholder="5555" />
                                <ModelInput
                                    model={station}
                                    field="zipcode"
                                    label="Zipcode"
                                    placeholder="90210" />
                                <ModelInput
                                    model={station}
                                    field="Volume_Type"
                                    label="Volume Type"
                                    type="select"
                                    options={['Default', 'Temperature Corrected']} />
                                <InputElement
                                    type="searchMultiSelect"
                                    label="Clients"
                                    id="clients"
                                    onChange={this.handleChange}
                                    default={station.get('clients').toJSON()}
                                    options={clientOptions} />
                                <hr/>
                                <h3>Offsets</h3>
                                <p>
                                    These values will be subtracted from this station display when viewed by certain clients.
                                    These do not alter base data for the station.
                                </p>
                                <p><b><u>Enter only integers. No special characters or commas</u></b></p>
                                <ModelInput
                                    model={station}
                                    field="Offset_NT_TotalFuel"
                                    label="Offset for NT_TotalFuel" />
                            </SHSForm>
                            { this.renderDelete() }
                        </>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const stationId = ownProps.match.params.stationId
    return {
        auth: state.get('auth'),
        clients: state.get('clients'),
        stationId: stationId,
        station: state.get('stations').find(s => s.get('id') === stationId)
    }
}

export default connect(mapStateToProps)(Edit)
