import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { Link } from 'react-router-dom'

import { clientRoutes } from '../../constants/routes'

// actions
import { updateModel } from '../../actions/api-model'

// models
import UserModel from '../../services/models/user-model'

// components
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

class UserAgreement extends React.Component {
    state = {}

    componentDidMount() {
        const { auth } = this.props
        if (auth && auth.get('hasAccepted')) {
            this.props.history.push(clientRoutes.fleetIndex)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.user.get('hasAccepted')) {
            this.props.history.push(clientRoutes.fleetIndex)
        }
    }

    successFX = () => {
        // do nothing
    }

    submitFX = (formData, formId) => {
        const { auth } = this.props
        let newModel = new UserModel()
        newModel.set('authId', auth.get('authId'))
        this.props.dispatch(updateModel(newModel, { hasAccepted: true }, formId))
    }

    render = () => {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        <h1>Use Terms & Privacy Agreement</h1>
                        <p>TODO: Inline docs, pdfs, static pages? How to present this data...</p>
                        <div className="input-values">
                            <SHSForm successFX={this.successFX} submitFX={this.submitFX} buttonLabel="Login">
                                <InputElement
                                    type="checkbox"
                                    id="hasAccepted"
                                    label="Accept"
                                    required />
                            </SHSForm>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let auth = state.get('auth')
    return {
        auth: state.get('auth'),
        user: state.get('users').find(u => u.get('authId') === auth.get('authId'))
    }
}


export default withRouter(connect(mapStateToProps)(UserAgreement))
