import React, { Component } from 'react'
class DragAndDrop extends Component {
    state = {
        drag: false
    }

    dropRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter = this.dragCounter + 1
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true })
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter = this.dragCounter - 1
        if (this.dragCounter === 0) {
            this.setState({ drag: false })
        }
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: false })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            this.dragCounter = 0
        }
    }

    handleDragStart = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ drag: true })
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            e.dataTransfer.clearData()
        }
    }

    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragstart', this.handleDragStart)
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }

    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragstart', this.handleDragStart)
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    render() {
        let dropAreaLabel = this.props.label ? this.props.label : 'Drag File(s) Here'
        return (
            <div className="dragarea-wrapper" ref={this.dropRef} >
                {!this.state.drag &&
                    <div className="dragarea-mssg">{dropAreaLabel}</div>
                }
                {this.state.drag &&
                    <div className="dragarea-target">
                        <div className="dragarea-label">Drop File(s) Here</div>
                    </div>
                }
                {this.props.children}
            </div>
        )
    }
}

export default DragAndDrop
