import React from 'react'
import { Circle } from 'rc-progress'
import InfoMark from '../utils/info-mark'

class WidgetCircleGraph extends React.Component {
    state = {

    }

    render = () => {
        let percent = this.props.percent ? this.props.percent : 0
        let hasInfo = this.props.info
        return (
            <div className="widget">
                <h3 className="border-bottom">{ this.props.header }{ hasInfo && <InfoMark pos="left" info={this.props.info} /> }</h3>
                <div className="description">
                    {this.props.children}
                </div>
                <div className="widget-wrapper">
                    <div className="circle-wrapper">
                        <Circle
                            percent={percent}
                            strokeWidth="18"
                            strokeColor="#0A6AAD"
                            trailWidth="18"
                            trailColor="#E6E6E6"
                            strokeLinecap="butt"/>
                        <span>{percent}%</span>
                    </div>
                    { this.props.label && <div className="widget-header">{ this.props.label }</div> }
                </div>
            </div>
        )
    }
}

export default WidgetCircleGraph
