import uuidv4 from 'uuid/v4'
import immutable from 'immutable'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_ENTITY } from '../constants/actions'

class EntityModel extends Model {
    _actionType = SAVE_ENTITY
    _collection = COLLECTIONS.ENTITY_COLLECTION
    _primaryKey = 'id'
    _endpoint = 'entities'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            usePrefix: true,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        display: {
            type: 'string',
            isRequired: true
        },
        lastContact: {
            type: 'string',
            isRequired: true
        },
        type: {
            type: 'string'
        },
        typeSet: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    validate() {
        // console.log('in the validate function')
    }
}

export default EntityModel
