import uuidv4 from 'uuid/v4'
import immutable from 'immutable'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_CLIENT } from '../constants/actions'

class ClientModel extends Model {
    _actionType = SAVE_CLIENT
    _collection = COLLECTIONS.CLIENT_COLLECTION
    _endpoint = 'clients'
    _prefix = 'client_'
    _primaryKey = 'id'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        name: {
            type: 'string',
            isRequired: true
        },
        primaryContact: {
            type: 'string'
        },
        email: {
            type: 'string',
            inputType: 'email'
        },
        phone: {
            type: 'string'
        },
        website: {
            type: 'string'
        },
        notes: {
            type: 'string',
            inputType: 'textarea'
        },
        logo: {
            type: 'string'
        },
        zipcode: {
            type: 'number'
        },
        fleetId: {
            type: 'string'
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        _creator: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.USER_COLLECTION
        },
        _lastEditedBy: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.USER_COLLECTION
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    validate() {
        console.log('in the validate function')
    }
}

export default ClientModel
