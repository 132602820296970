import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import EntityEdit from './edit'

class EntityIndex extends React.Component {
    state = {

    }

    render = () => {
        const { routes } = this.props
        return (
            <Switch>
                <Route path={routes.entityEdit} render={props => <EntityEdit {...props} routes={routes} />} />
            </Switch>
        )
    }
}

export default withRouter(EntityIndex)
