import React from 'react'
import { Line } from 'rc-progress'

class WidgetLinearGauge extends React.Component {
    state = {

    }

    render = () => {
        let minVal = this.props.min ? this.props.min : 0
        let maxVal = this.props.max ? this.props.max : 100
        let setVal = this.props.value ? this.props.value : 0
        let percent = Math.ceil(setVal / (maxVal - minVal) * 100)
        let minLabel = this.props.minLabel ? this.props.minLabel : 'E'
        let maxLabel = this.props.maxLabel ? this.props.maxLabel : 'F'
        return (
            <div className="widget">
                <h3 className="border-bottom">{ this.props.header }</h3>
                <div className="description">
                    {this.props.children}
                </div>
                <div className="widget-wrapper">
                    <div className="widget-table-row">
                        <div className="gas-gauge col-75">
                            <span>{ minLabel }</span>
                            <Line
                                percent={percent}
                                strokeWidth="6"
                                trailWidth="6"
                                strokeColor="#0A6AAD"
                                trailColor="#E6E6E6" />
                            <span>{ maxLabel }</span>
                        </div>
                        <div>
                            { this.props.timestamp }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WidgetLinearGauge
