export function formatDate(sec) {
    let d = new Date(sec)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    const hh = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(d)
    return { year: ye, day: da, month: mo, time: hh }
}


// return a total with the numerical unit
export const formatTotalWithUnit = (total) => {
    let pos = total < 0 ? -1 : 1
    let value = Math.abs(total)
    let unit = ''
    let formatDigits = 3
    const digits = Math.ceil(Math.log10(value + 1))
    if (digits < 4) {
        formatDigits = 2
    } else if (digits >= 4 && digits < 7) {
        value = Math.round((total * 1000 * pos) / 1000) / 1000
        unit = 'k'
    } else if (digits >= 7 && digits < 10) {
        value = Math.round((total * 1000 * pos) / 1000000) / 1000
        unit = 'M'
    } else {
        value = Math.round((total * 1000 * pos) / 1000000000) / 1000
        unit = 'B'
    }
    return `${parseFloat(value * pos).toFixed(formatDigits)}${unit}`
    // return `${formatCurrency(value, formatDigits)}${unit}`
}
