import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'

import '../assets/scss/irwin.scss'

import { fireauth } from '../services/firebase/firebase'

// Font Awesome pre-loader mechanism
import { library } from '@fortawesome/fontawesome-svg-core'
// Font Awesome icons, need to be individually imported? Import all by changing to 'fas'
// import { faCheckSquare, faCoffee, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

// actions
import { handleFirebaseAuth, handleAnonymousFirebaseAuth, removeAuth } from '../actions/auth'

// constants
import { sharedRoutes } from '../constants/routes'

// pages
import AdminIndex from './admin/index'
import ClientIndex from './client/index'
import Footer from './general/footer'

// auth
import Login from './auth/login'
import PasswordHelp from './auth/password-help'
import PasswordReset from './auth/password-reset'
import AuthHandler from './auth/auth-handler'
import AuthRouter from './auth/router'
import InvitationRegistration from './auth/invitation'
import UserAgreement from './auth/user-agreement'

// generic
import NotFound from './pages/not-found'

// utils
import { AppRoute, AdminRoute } from './utils/private-route'

class App extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch } = this.props

        // TODO: Update to individual Font Awesome chars once settled. For now, import them all
        library.add(fas)
        library.add(far)

        // listens for login changes
        fireauth.onAuthStateChanged(firebaseUser => {
            if (firebaseUser) {
                if (firebaseUser.isAnonymous) {
                    dispatch(handleAnonymousFirebaseAuth(firebaseUser))
                } else if (!this.props.auth.has('authId') || this.props.auth.get('isAnonymous')) {
                    dispatch(handleFirebaseAuth(firebaseUser))
                    const fromState = this.props.location.state && this.props.location.state.from.pathname
                        ? this.props.location.state.from.pathname
                        : null
                    const nextLocation = {
                        pathname: sharedRoutes.authRouter,
                        state: { from: { pathname: fromState } }
                    }
                    this.props.history.push(nextLocation)
                } else {
                    // TODO: can we remove this logic?
                    alert('Duplication in authentication. Please log out and try again.')
                }
            } else {
                dispatch(removeAuth())
            }
        })

        // this handles the update from anonymous auth to signed in auth
        fireauth.onIdTokenChanged(firebaseUser => {
            if (firebaseUser) {
                if (!firebaseUser.isAnonymous && this.props.auth.get('isAnonymous')) {
                    // TODO: do we want to make a check here, or do a wholesale update of the auth state?
                    dispatch(handleFirebaseAuth(firebaseUser))
                    const fromState = this.props.location.state && this.props.location.state.from.pathname
                        ? this.props.location.state.from.pathname
                        : null
                    const nextLocation = {
                        pathname: sharedRoutes.authRouter,
                        state: { from: { pathname: fromState } }
                    }
                    this.props.history.push(nextLocation)
                }
            }
        })
    }

    render = () => {
        const { auth } = this.props
        return (
            <>
                <Switch>
                    <AdminRoute auth={auth} path={sharedRoutes.adminIndex} component={AdminIndex} />
                    <AppRoute auth={auth} path={sharedRoutes.clientIndex} component={ClientIndex} />
                    <AppRoute auth={auth} path={sharedRoutes.userAgreement} component={UserAgreement} />
                    <AppRoute auth={auth} path={sharedRoutes.authRouter} component={AuthRouter} />
                    <Route path={sharedRoutes.invitation} component={InvitationRegistration} />
                    <Route path={sharedRoutes.passwordHelp} component={PasswordHelp} />
                    <Route path={sharedRoutes.passwordReset} component={PasswordReset} />
                    <Route path={sharedRoutes.authHandler} component={AuthHandler} />
                    <Route path={[sharedRoutes.login, sharedRoutes.index]} component={Login} />
                    <Route component={NotFound} />
                </Switch>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default withRouter(connect(mapStateToProps)(App))
