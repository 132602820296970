import React from 'react'
import { connect } from 'react-redux'

// actions
import { SAVE_LIVE_PRICES } from '../../../../services/constants/actions'
import { createModel, watchFirestoreDocsSubCollection } from '../../../../actions/api-model'
import { buildRoutePath } from '../../../../constants/routes'

// models
import PricesModel from '../../../../services/models/prices-model'

// utilities
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import { formatDate } from '../../../utils/formatters'

class Edit extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch, stationId } = this.props
        dispatch(watchFirestoreDocsSubCollection('stations', stationId, 'prices', SAVE_LIVE_PRICES))
    }

    successFX = () => {
        const { stationId, routes } = this.props
        let path = buildRoutePath(routes.stationsPriceIndex, { stationId: stationId })
        this.props.history.push(path)
    }

    submitFX = (formData, formId) => {
        const { stationId, price } = this.props
        let newPrice = new PricesModel()
        newPrice.hydrate(formData)
        newPrice.set('stationId', stationId)
        newPrice.set('id', price.get('id'))
        this.props.dispatch(createModel(newPrice, formId))
    }

    render = () => {
        const { price } = this.props
        if (!price) {
            return 'Loading...'
        }
        let startDateParts = formatDate(price.getIn(['startDate', '_seconds']) * 1000)
        let startDate = `${startDateParts.year}-${startDateParts.month}-${startDateParts.day}`
        let endDateParts = price.get('endDate') ? formatDate(price.getIn(['endDate', '_seconds']) * 1000) : null
        let endDate = endDateParts ? `${endDateParts.year}-${endDateParts.month}-${endDateParts.day}` : ''
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 mb-5">
                        <h1 className="mt-4">Create a new fuel price entry</h1>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save">
                            <InputElement
                                type="text"
                                label="Price Per Gallon DDD.CCC"
                                id="pricePerGallon"
                                default={price.get('pricePerGallon')}
                                required />
                            <InputElement
                                type="date"
                                label="Start Date"
                                id="startDate"
                                default={startDate}
                                required />
                            <InputElement
                                type="date"
                                default={endDate}
                                label="End Date"
                                id="endDate" />
                        </SHSForm>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stationId: ownProps.match.params.stationId,
        price: state.get('prices').find(p => p.get('id') === ownProps.match.params.priceId)
    }
}

export default connect(mapStateToProps)(Edit)
