import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { handleFirebaseAuthCode } from '../../actions/auth'
import { sharedRoutes } from '../../constants/routes'

import Lottie from 'react-lottie'
import * as logoAnim from '../../assets/json/optimusLogo.json'

class AuthHandler extends React.Component {
    state = {
        authCode: '',
        actionType: '',
        isValidCode: false,
        hasError: false,
        isStopped: true
    }

    componentDidMount = () => {
        const urlParams = new URLSearchParams(this.props.location.search)

        // TODO: should we validate these?
        const authCode = urlParams.get('oobCode')
        const actionType = urlParams.get('mode')
        this.setState({ authCode, actionType })
        this.props.dispatch(handleFirebaseAuthCode(authCode, actionType))
        this.timer = setTimeout(() => {
            this.setState({ isStopped: false })
        }, 1000)
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.authCode.has('authCode') && !prevProps.authCode.has('authCode')) {
            this.setState({ isValidCode: true })
        }
        if (this.props.authCode.get('hasError') && !prevProps.authCode.get('hasError')) {
            this.setState({ hasError: true })
        }
    }

    render = () => {
        if (this.state.isValidCode) {
            switch (this.state.actionType) {
            case 'resetPassword':
                return <Redirect to={sharedRoutes.passwordReset} />
            default:
                return null
                // (
                //     <div className="container mt-4">
                //         <div className="col-4 offset-4">
                //             <div className="alert alert-danger w-100 text-center">
                //                 <p>Need to handle the situation where action type is {this.state.actionType}</p>
                //                 <Link to={routes.passwordReset}>Return to Login</Link>
                //             </div>
                //         </div>
                //     </div>
                // )
            }
        }
        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: logoAnim.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return (
            <div className="container">
                <div className="row pt-5">
                    <div className="col-4 offset-4">
                        <Lottie
                            options={defaultOptions}
                            isStopped={this.state.isStopped}
                            ref={animation => {
                                this.animation = animation
                            }}/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-8 p-4">
                        <div className="input-values">
                            { this.state.hasError
                                ? (
                                    <div className="alert alert-danger w-100 text-center">
                                        <p>There was a problem with the reset password token. It may have expired or already been used. Please try again.</p>
                                        <Link to={sharedRoutes.login}>Return to Login</Link>
                                    </div>
                                )
                                : <div>Validating the authorization code...</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authCode: state.get('authCode')
    }
}

export default connect(mapStateToProps)(AuthHandler)
