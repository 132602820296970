// API CACHE
export const SAVE_API_CALL = 'SAVE_API_CALL'
export const CLEAR_API_CALL = 'CLEAR_API_CALL'

// AUTH
export const REMOVE_AUTH = 'REMOVE_AUTH'
export const SAVE_AUTH = 'SAVE_AUTH'
export const SAVE_ANONYMOUS_AUTH = 'SAVE_ANONYMOUS_AUTH'
export const REMOVE_AUTH_HANDLER = 'REMOVE_AUTH_HANDLER'
export const SAVE_AUTH_HANDLER = 'SAVE_AUTH_HANDLER'
export const AUTH_HANDLER_ERROR = 'AUTH_HANDLER_ERROR'

// CLIENTS
export const SAVE_CLIENT = 'SAVE_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'

// EMAIL PREFERENCES
export const SAVE_EMAIL_PREFERENCE = 'SAVE_EMAIL_PREFERENCE'

// ENTITIES
export const SAVE_ENTITY = 'SAVE_ENTITY'

// FAULTS
export const SAVE_FAULTS = 'SAVE_FAULTS'
export const SAVE_FAULT_LOG = 'SAVE_FAULT_LOG'

// FORMS
export const CLEAR_FORM = 'CLEAR FORM'
export const UPSERT_FORM = 'UPSERT_FORM'

// INVITATIONS
export const SAVE_INVITATION = 'SAVE_INVITATION'

// MISC
export const KILL_STATE = 'KILL_STATE'

// SHARED
export const SAVE_LIVE_TRUCK_DATA = 'SAVE_LIVE_TRUCK_DATA'

// STATIONS
export const SAVE_STATION = 'SAVE_STATION'
export const SAVE_TRANSACTION = 'SAVE_TRANSACTION'
export const SAVE_LIVE_TRANSACTIONS = 'SAVE_LIVE_TRANSACTIONS'
export const SAVE_PRICE = 'SAVE_PRICE'
export const SAVE_LIVE_PRICES = 'SAVE_LIVE_PRICES'
export const DELETE_PRICE = 'DELETE_PRICE'

// TRUCKS
export const SAVE_TRUCK = 'SAVE_TRUCK'
export const SAVE_TRUCK_REPORT = 'SAVE_TRUCK_REPORT'
export const SAVE_TRUCK_POSITIONS = 'SAVE_TRUCK_POSITIONS'

// USER
export const SAVE_USER = 'SAVE_USER'
export const DELETE_USER = 'DELETE_USER'
