import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'

// constants
import { sharedRoutes, clientRoutes } from '../../constants/routes'

// actions
import { SAVE_LIVE_TRUCK_DATA, SAVE_STATION } from '../../services/constants/actions'
import { watchFirestoreDocs } from '../../actions/api-model'

// component elements
import ClientHeader from './header'
import ApiLoader from '../api-loader/api-loader'

// component pages
import ClientDashboard from './dashboard'
import FleetIndex from './fleet/index'
import AccountIndex from '../shared/account/index'
import StationsIndex from '../shared/stations/index'
import PriceIndex from '../shared/stations/prices/index'
import SupportIndex from './support'

class ClientIndex extends React.Component {
    state = {

    }

    componentDidMount = () => {
        // Watch My Trucks
        const { auth } = this.props
        this.props.dispatch(watchFirestoreDocs('liveTrucks', 'clientId', auth.get('clientId'), SAVE_LIVE_TRUCK_DATA))
        // Watch my Stations
        this.props.dispatch(watchFirestoreDocs('stations', 'clients', auth.get('clientId'), SAVE_STATION, 'array-contains'))
    }


    render = () => {
        return (
            <div className="container-fluid">
                <ClientHeader />
                <ApiLoader />
                <div className="container mb-5">
                    <Switch>
                        <Route path={clientRoutes.supportIndex} render={props => <SupportIndex {...props} />} />
                        <Route path={clientRoutes.stationsPriceIndex} render={props => <PriceIndex {...props} routes={clientRoutes} />} />
                        <Route path={clientRoutes.accountIndex} render={props => <AccountIndex {...props} routes={clientRoutes} /> } />
                        <Route path={clientRoutes.stationsIndex} render={props => <StationsIndex {...props} routes={clientRoutes} />} />
                        <Route path={clientRoutes.fleetIndex} render={props => <FleetIndex {...props} routes={clientRoutes} />} />
                        <Route path={sharedRoutes.clientIndex} render={props => <ClientDashboard {...props} routes={clientRoutes} />} />
                    </Switch>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default withRouter(connect(mapStateToProps)(ClientIndex))
