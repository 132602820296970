import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import Lottie from 'react-lottie'
import * as logoAnim from '../../assets/json/optimusLogo.json'

import { resetPasswordWithCode, clearAuthCode } from '../../actions/auth'
import { sharedRoutes } from '../../constants/routes'

class PasswordReset extends React.Component {
    state = {
        isStopped: true,
        isMissingCode: false
    }

    componentDidMount = () => {
        if (this.props.authCode.isEmpty()) {
            this.setState({ isMissingCode: true })
        }
        this.timer = setTimeout(() => {
            this.setState({ isStopped: false })
        }, 1000)
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearAuthCode())
        clearTimeout(this.timer)
    }

    successFX = () => {
        // nothing for now?
    }

    submitFX = (formData, formId) => {
        const { dispatch, authCode } = this.props
        dispatch(resetPasswordWithCode(authCode.get('authCode'), authCode.get('authTempEmail'), formData.password, formId))
    }

    render = () => {
        if (this.state.isMissingCode) {
            return <Redirect to={sharedRoutes.login} />
        }
        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: logoAnim.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return (
            <div className="container">
                <div className="row pt-5">
                    <div className="col-4 offset-4">
                        <Lottie
                            options={defaultOptions}
                            isStopped={this.state.isStopped}
                            ref={animation => {
                                this.animation = animation
                            }}/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-8 p-4">
                        <div className="input-values">
                            <h1>Reset Your Password</h1>
                            <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Reset Password" buttonClass="black center">
                                <InputElement
                                    default={this.props.authCode.get('authTempEmail')}
                                    label="Email"
                                    extraClass="flex-100"
                                    readOnly />
                                <InputElement
                                    type="password"
                                    default=""
                                    id="password"
                                    label="Password"
                                    placeholder="Something secret only you know..."
                                    extraClass="flex-100"
                                    required />
                            </SHSForm>
                            <div className="w-100 mt-4">
                                <p className="text-center">
                                    <small className="font-weight-light">
                                        <Link to={sharedRoutes.login}>Return to login page</Link>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authCode: state.get('authCode')
    }
}

export default connect(mapStateToProps)(PasswordReset)
