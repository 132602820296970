import React from 'react'
import WidgetLinearGauge from './widget-linear-gauge'

class LinearGasLevel extends React.Component {
    state = {

    }

    render = () => {
        const { report, updateTime } = this.props
        if (!report) {
            return null
        }
        let value = Number(report.get('bioTankLevel'))

        return (
            <WidgetLinearGauge
                value={value}
                header="Fuel Level"
            >
                <div><label>{`${ updateTime }:`}<strong className="blue">{`${value}%`}</strong></label></div>
            </WidgetLinearGauge>
        )
    }
}

export default LinearGasLevel
