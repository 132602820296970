import React from 'react'
import { connect } from 'react-redux'

// models
import InvitationModel from '../../../services/models/invitation-model'

// utilities
import SHSForm from '../../utils/shs-form'
import ModelInput from '../../utils/model-input'

// actions
import { createModel } from '../../../actions/api-model'

// constants
import { ALL_ROLES } from '../../../services/constants/roles'

class InviteUser extends React.Component {
    state = {
        invitation: new InvitationModel(),
        roleOptions: ALL_ROLES
    }

    componentDidMount = () => {
        const roleIndex = ALL_ROLES.indexOf(this.props.auth.get('role'))
        this.setState({ roleOptions: ALL_ROLES.slice(roleIndex) })
    }

    successFX = () => {
        this.props.onClose()
    }

    submitFX = (formData, formId) => {
        const invitation = new InvitationModel()
        invitation.set('clientId', this.props.clientId)
        invitation.hydrate(formData)
        this.props.dispatch(createModel(invitation, formId))
    }

    render = () => {
        return (
            <div className="px-5 pb-5">
                <h1>Invite a New User</h1>
                <p className="mb-5">This will generate a shareable link. This user will have access to this client account.</p>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Create Invitation">
                    <ModelInput
                        model={this.state.invitation}
                        field="firstName"
                        label="Name"
                        placeholder="Bobby" />
                    <ModelInput
                        model={this.state.invitation}
                        field="email"
                        label="Email Address"
                        placeholder="bobby@example.com" />
                    <ModelInput
                        model={this.state.invitation}
                        field="role"
                        options={this.state.roleOptions}
                        label="User Role" />
                </SHSForm>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default connect(mapStateToProps)(InviteUser)
