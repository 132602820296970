import immutable from 'immutable'

import {
    SAVE_AUTH, SAVE_ANONYMOUS_AUTH, REMOVE_AUTH, KILL_STATE
} from '../services/constants/actions'

const INITIAL_STATE = immutable.fromJS({})

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case SAVE_AUTH:
        return state
            .set('authDisplayName', action.auth.displayName)
            .set('emailVerified', action.auth.emailVerified)
            .set('authId', action.auth.uid)
            .set('authEmail', action.auth.email)
            .set('createDate', action.auth.metadata.creationTime)
            .set('lastLoginDate', action.auth.metadata.lastSignInTime)
            .set('isAnonymous', action.auth.isAnonymous)
    case SAVE_ANONYMOUS_AUTH:
        return state
            .set('authId', action.auth.uid)
            .set('isAnonymous', action.auth.isAnonymous)
    case 'SAVE_USER_ACCOUNT':
    case 'SAVE_USER':
        return state
            .merge(action.model)
    case REMOVE_AUTH:
    case KILL_STATE:
        return immutable.fromJS({})
    default:
        return state
    }
}
