import React from 'react'
import Logo from '../../assets/imgs/OptimusTechnologies.svg'

class Footer extends React.Component {
    render = () => {
        let d = new Date()
        let y = d.getFullYear()
        return (
            <div id="footer" className="px-4">
                <div className="optimus-logo">
                    <img src={ Logo } alt="Optimus Technologies" />
                </div>
                <div className="footer-details">
                    <div>
                        &copy; {y} by Optimus Technologies Inc
                    </div>
                    <div>Optimus Technologies (logo and text) is registered in the U.S. Patent and Trademark Office</div>
                    <div>
                        <a className="blue" href="mailto:info@optimustec.com">info@optimustec.com</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
