import pathToRegexp from 'path-to-regexp'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const clientRoutes = {
    fleetIndex: '/dashboard/fleet/',
    fleetView: '/dashboard/fleet/:fleetId',
    stationsIndex: '/dashboard/stations/',
    stationsPriceIndex: '/dashboard/stations/:stationId/price',
    stationsPriceCreate: '/dashboard/stations/:stationId/price/create',
    stationsPriceEdit: '/dashboard/stations/:stationId/price/:priceId/edit',
    stationsView: '/dashboard/stations/:stationId',
    accountIndex: '/dashboard/account/',
    supportIndex: '/dashboard/support/'
}

export const adminRoutes = {
    fleetIndex: '/admin/fleet/',
    fleetEdit: '/admin/fleet/:fleetId/edit',
    fleetView: '/admin/fleet/:fleetId',
    stationsPriceIndex: '/admin/stations/:stationId/price',
    stationsPriceCreate: '/admin/stations/:stationId/price/create',
    stationsPriceEdit: '/admin/stations/:stationId/price/:priceId/edit',
    stationsIndex: '/admin/stations/',
    stationsEdit: '/admin/stations/:stationId/edit',
    stationsView: '/admin/stations/:stationId',
    accountIndex: '/admin/account/',
    clientIndex: '/admin/clients/',
    clientCreate: '/admin/clients/create/',
    clientView: '/admin/clients/:clientId/',
    entityIndex: '/admin/entity/',
    entityEdit: '/admin/entity/:entityId/edit'
}

export const sharedRoutes = {
    index: '/',
    login: '/login/',
    invitation: '/invitation/:invitationId/',
    register: '/register/',
    passwordHelp: '/password-help/',
    passwordReset: '/password-reset/',
    userAgreement: '/user-agreement/',
    authRouter: '/auth-router/',
    authHandler: '/auth-handler/',
    adminIndex: '/admin/',
    clientIndex: '/dashboard/'
}

export const apiRoutes = {
    lookupInvitationStatus: { url: 'lookup-invitation-status/', method: API_METHODS.GET },
    acceptInvitation: { url: 'accept-invitation/', method: API_METHODS.POST },
    listUsers: { url: 'list-users/', method: API_METHODS.POST, action: 'SAVE_USER' },
    deleteClient: { url: 'delete-client/:clientId', method: API_METHODS.POST, action: 'DELETE_CLIENT' },
    deleteUser: { url: 'delete-user/', method: API_METHODS.POST, action: 'DELETE_USER' },
    deleteTruck: { url: 'delete-truck/:truckId', method: API_METHODS.POST, action: 'DELETE_TRUCK' },
    deleteStation: { url: 'delete-station/:stationId', method: API_METHODS.POST, action: 'DELETE_STATION' },
    deleteInvitation: { url: 'delete-invitation/', method: API_METHODS.POST, action: 'DELETE_INVITATION' },
    fetchTruckPositions: { url: 'new-eagle/position/', method: API_METHODS.POST, action: 'SAVE_TRUCK_POSITIONS' },
    getUserData: { url: 'user-data/', method: API_METHODS.GET, action: 'SAVE_USER_ACCOUNT' },
    listAdminTrucks: { url: 'all', method: API_METHODS.GET, action: 'SAVE_TRUCK' },
    fetchAllTrucks: { url: 'new-eagle/fetch-all-trucks/', method: API_METHODS.GET, action: 'SAVE_TRUCK' },
    scrapeAllTrucks: { url: 'new-eagle/scrape-all-trucks/', method: API_METHODS.GET, action: 'DO_NOTHING' },
    scrapeAllEntities: { url: 'new-eagle/scrape-all-entities/', method: API_METHODS.GET, action: 'DO_NOTHING' },
    listAdminStations: { url: 'all', method: API_METHODS.GET, action: 'SAVE_STATION' },
    listClientStations: { url: '', method: API_METHODS.GET, action: 'SAVE_STATION' },
    listEntities: { url: 'all', method: API_METHODS.GET, action: 'SAVE_ENTITY' },
    downloadTruckReportFile: { url: 'new-eagle/csv-report/:truckId/', method: API_METHODS.GET },
    downloadStationReportFile: { url: 'report/', method: API_METHODS.GET },
    deleteStationPrice: { url: 'delete/:priceId', method: API_METHODS.POST, action: 'DELETE_PRICE' },
    // updateTransactions: { url: ':stationId/transactions/update', method: API_METHODS.POST, action: 'UPDATE_TRANSACTION' }
    refreshTransactions: { url: 'new-eagle/refresh-transactions/:stationId/', method: API_METHODS.GET, action: 'SAVE_TRANSACTIONS' }
}

export const buildRoutePath = (route, params) => {
    const toPath = pathToRegexp.compile(route)
    return toPath(params)
}
