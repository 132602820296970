import immutable from 'immutable'

import { CLEAR_FORM, UPSERT_FORM, KILL_STATE } from '../services/constants/actions'

const INITIAL_STATE = immutable.fromJS([])

function getObjectIndex(state, objectId) {
    return state.findIndex((object)=>{
        return object.get('id') === objectId
    })
}

const addObject = (state, action) => {
    return state.update((objects)=>{
        let object = new immutable.fromJS({
            id: action.id,
            status: action.status,
            body: action.body
        })
        return objects.push(object)
    })
}

const updateObject = (state, action, objectIndex) => {
    return state.update(objectIndex, (object) => {
        return object.merge({
            status: action.status,
            body: action.body
        })
    })
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case UPSERT_FORM:
        let formIndex = getObjectIndex(state, action.id)
        if (formIndex === -1) {
            return addObject(state, action)
        }
        return updateObject(state, action, formIndex)
    case CLEAR_FORM:
        return state.filter(field => {
            return action.formId !== field.get('id')
        })
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
