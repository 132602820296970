import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InfoMark from './info-mark'

class InputElement extends React.Component {
    state = {
        value: typeof this.props.default !== 'undefined' ? this.props.default : '',
        isActive: false,
        rawValue: ''
    }

    componentDidMount = () => {
        if (this.props.isFormatted && this.props.formatMask) {
            // console.log(this.props.formatMask)
            const formattedValue = this.props.formatMask.resolve(String(this.state.value))
            const rawValue = this.props.formatMask.unmaskedValue
            this.setState({
                value: formattedValue,
                rawValue: rawValue
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.default !== this.props.default) {
            if (this.props.isFormatted && this.props.formatMask) {
                const formattedValue = this.props.formatMask.resolve(String(this.props.default))
                const rawValue = this.props.formatMask.unmaskedValue
                this.setState({
                    value: formattedValue,
                    rawValue: rawValue
                })
            } else {
                this.setState({ value: this.props.default })
            }
        }
        if (prevProps.formatMask !== this.props.formatMask && this.props.isFormatted) {
            const formattedValue = this.props.formatMask.resolve(String(this.state.value))
            const rawValue = this.props.formatMask.unmaskedValue
            this.setState({
                value: formattedValue,
                rawValue: rawValue
            })
        }
    }

    renderInfo = () => {
        if (this.props.infoMark && this.props.infoMark !== '') {
            return <InfoMark info={this.props.infoMark} />
        }
        return null
    }

    renderLabel = (label) => {
        if (!label || label === '') {
            return <label>&nbsp;{this.renderInfo()}</label>
        }
        return <label>{label}{this.renderInfo()}</label>
    }

    handleChange = (evt) => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
        if (this.props.isFormatted && this.props.formatMask) {
            const formattedValue = this.props.formatMask.resolve(value)
            const rawValue = this.props.formatMask.unmaskedValue
            this.setState({
                value: formattedValue,
                rawValue: rawValue
            })
        } else {
            this.setState({ value: value })
        }
        // TODO: check if we need to send the value to a parent? probably would be raw value
        if (this.props.onChange) {
            this.props.onChange(evt.target.id, value)
        }
    }

    renderError = () => {
        return (
            <div className="invalid-feedback">
                {this.props.errorMessage || 'This field is required'}
            </div>
        )
    }

    renderHelperText = (helperText) => {
        if (this.props.calculatedDisplay && this.state.value !== '') {
            return null
        }
        return <small className={`helper-text ${this.state.isActive ? 'visible' : 'invisible'}`}>{helperText}</small>
    }

    handleOptionClick = (option) => {
        if (this.state.value === option) {
            this.setState({ value: '' })
        } else {
            this.setState({ value: option })
        }
    }

    handleMultiOptionClick = (option) => {
        let values = this.state.value
        const idx = values.indexOf(option)
        if (idx === -1) {
            values.push(option)
        } else {
            values.splice(idx, 1)
        }
        this.setState({ value: values })
    }

    renderRangeSlider = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <div>
                    <input
                        type="range"
                        value={this.state.value}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        onChange={(evt) => {
                            this.setState({ value: evt.target.value })
                            this.handleChange(evt)
                        }} />
                    {this.state.value}
                </div>
            </>
        )
    }

    renderButtonSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <div className="option-btns">
                    {this.props.options.map((option, idx) => {
                        return (
                            <span key={idx} className={`btn btn-select ${this.state.value === option.value ? 'active' : ''}`} onClick={() => this.handleOptionClick(option.value)}>
                                {option.label}
                            </span>
                        )
                    })}
                    <input
                        type="hidden"
                        id={commonProps.id}
                        name={commonProps.name}
                        value={this.state.value} />
                </div>
            </>
        )
    }

    renderButtonMultiSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <div className="option-btns">
                    {this.props.options.map((option, idx) => {
                        return (
                            <span key={idx} className={`btn btn-select ${this.state.value.indexOf(option.value) !== -1 ? 'active' : ''}`} onClick={() => this.handleMultiOptionClick(option.value)}>
                                {option.label}
                            </span>
                        )
                    })}
                    <input
                        type="hidden"
                        id={commonProps.id}
                        name={commonProps.name}
                        value={this.state.value} />
                </div>
            </>
        )
    }

    handleSearchChange = (evt) => {
        const { options } = this.props
        let value = evt.target.value
        let exists = options.find(item => {
            return item.label.toLowerCase() === value.toLowerCase()
        })
        if (exists && this.state.value.indexOf(exists.value) === -1) {
            this.setState({ value: [...this.state.value, exists.value], rawValue: '' })
        } else {
            this.setState({ rawValue: value })
        }
    }

    renderSearchMultiSelect = (props) => {
        const { options } = this.props
        const { value } = this.state
        const multiSearchProps = {
            name: props.id,
            placeholder: props.placeholder,
            required: props.required || false,
            disabled: props.disabled || false
        }
        let activeOptions = options.filter(o => value.indexOf(o.value) === -1)
        return (
            <>
                {this.renderLabel(this.props.label)}
                <input className="form-control" autoComplete="off" value={this.state.rawValue} list={`list-${multiSearchProps.name}`} onChange={this.handleSearchChange} />
                <datalist id={`list-${multiSearchProps.name}`}>
                    {activeOptions.map((option, idx) => {
                        return (
                            <option key={idx} value={option.label}>{option.label}</option>
                        )
                    })}
                </datalist>
                { this.renderActive() }
                <input id={multiSearchProps.name} name={multiSearchProps.name} value={value} type="hidden" />
            </>
        )
    }

    renderActive = () => {
        const { value } = this.state
        const { options } = this.props

        return value.map((item, idx) => {
            let option = options.find(o => o.value === item)
            if (option) {
                return (
                    <span className="active-item" key={idx} onClick={() => this.removeActive(option.value)}>
                        { option.label }
                        <FontAwesomeIcon icon="times" role="presentation" />
                    </span>
                )
            }
            return null
        })
    }

    removeActive = (removeValue) => {
        const { value } = this.state
        let newValue = value.filter(v => v !== removeValue)
        this.setState({ value: newValue })
    }

    renderSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <select {...commonProps}>
                    { this.props.includeInitialValue !== false &&
                        <option value="">
                            {this.props.initialValueLabel || 'Select a value'}
                        </option>
                    }
                    {props.options.map((option, idx) => {
                        return <option value={option} key={idx}>{option}</option>
                    })}
                </select>
            </>
        )
    }

    renderValueSelect = (props, commonProps) => {
        return (
            <>
                {this.renderLabel(this.props.label)}
                <select {...commonProps}>
                    { this.props.includeInitialValue !== false &&
                        <option value="">
                            {this.props.initialValueLabel || 'Select a value'}
                        </option>
                    }
                    {props.options.map((option, idx) => {
                        return <option value={option.value} key={idx}>{option.label}</option>
                    })}
                </select>
                { this.props.helperText !== '' &&
                    <small className="helper-text">{this.props.helperText}</small>
                }
            </>
        )
    }

    renderInput = (props, commonProps) => {
        const type = props.type || 'text'
        if (this.props.refName) {
            commonProps.ref = this.props.refName
        }
        if (type === 'checkbox') {
            // need to remove the value flag and just use checked
            delete commonProps.value
            return (
                <>
                    <input type={type} {...commonProps} checked={this.state.value} />
                    <label className="form-check-label">{this.props.label}</label>
                </>
            )
        }
        return (
            <>
                {this.renderLabel(this.props.label)}
                <input
                    readOnly={this.props.readOnly ? true : false}
                    onBlur={() => this.setState({ isActive: false })}
                    onFocus={() => this.setState({ isActive: true })}
                    autoComplete="off"
                    type={type}
                    {...commonProps} />
            </>
        )
    }

    renderFormInput = (props) => {
        const commonProps = {
            value: this.state.value,
            rawvalue: this.state.rawValue,
            id: props.id,
            name: props.id,
            onChange: this.handleChange,
            className: props.type === 'checkbox' ? 'form-check-input' : 'form-control',
            placeholder: props.placeholder,
            required: props.required || false,
            disabled: props.disabled || false
        }
        switch (props.type) {
        case 'range':
            return this.renderRangeSlider(props, commonProps)
        case 'buttonSelect':
            return this.renderButtonSelect(props, commonProps)
        case 'buttonMultiSelect':
            return this.renderButtonMultiSelect(props, commonProps)
        case 'searchMultiSelect':
            return this.renderSearchMultiSelect(props)
        case 'select':
            return this.renderSelect(props, commonProps)
        case 'valueSelect':
            return this.renderValueSelect(props, commonProps)
        default:
            return this.renderInput(props, commonProps)
        }
    }

    renderCalculatedDisplay = (props) => {
        if (this.props.calculatedDisplay) {
            if (this.state.value !== '') {
                return (
                    <div className="calculated">
                        {this.props.calculatedDisplay(this.state.value)}
                    </div>
                )
            }
        }
        return null
    }

    render = () => {
        return (
            <div className={`display-input ${this.props.extraClass || ''}`}>
                <div className={`form-group ${this.props.type === 'checkbox' ? 'form-check' : ''}`}>
                    {this.renderFormInput(this.props)}
                    {this.renderCalculatedDisplay(this.props)}
                    {this.renderError(this.props.errorMessage)}
                    {this.renderHelperText(this.props.helperText)}
                </div>
            </div>
        )
    }
}

export default InputElement
