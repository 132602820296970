import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientRoutes, sharedRoutes } from '../../constants/routes'

// actions
import { signOut } from '../../actions/auth'

class ClientNav extends React.Component {
    state = {
        isExpanded: false
    }

    onToggle = () => {
        this.setState({ isExpanded: !this.state.isExpanded })
    }

    signOut = () => {
        this.props.dispatch(signOut())
        this.props.history.push(sharedRoutes.login)
    }

    render = () => {
        let menuClasses = classNames('container', {
            expanded: this.state.isExpanded
        })
        let iconClasses = classNames('navbar-toggle', {
            expanded: this.state.isExpanded
        })
        return (
            <>
                <div className={iconClasses} onClick={this.onToggle} aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon className="icon-open" icon="bars" title="Open Menu" />
                    <FontAwesomeIcon className="icon-close" icon="times" title="Close Menu" />
                </div>
                <div id="main-nav" className={menuClasses}>
                    <div className="row links-wrapper">
                        <div className="col text-right">
                            <NavLink to={sharedRoutes.clientIndex} exact onClick={this.onToggle}>
                                <FontAwesomeIcon icon="home" title="Dashboard" />
                                <label>Dashboard</label>
                            </NavLink>
                            <NavLink to={clientRoutes.fleetIndex} onClick={this.onToggle}>
                                <FontAwesomeIcon icon="truck" title="Fleet" />
                                <label>Fleet</label>
                            </NavLink>
                            <NavLink to={clientRoutes.stationsIndex} onClick={this.onToggle}>
                                <FontAwesomeIcon icon="gas-pump" title="Stations" />
                                <label>Stations</label>
                            </NavLink>
                            <NavLink to={clientRoutes.accountIndex} onClick={this.onToggle}>
                                <FontAwesomeIcon icon="user-cog" title="Account" />
                                <label>Account</label>
                            </NavLink>
                            <NavLink to={clientRoutes.supportIndex} onClick={this.onToggle}>
                                <FontAwesomeIcon icon="question-circle" title="Support" />
                                <label>Support</label>
                            </NavLink>
                            <span onClick={this.signOut}>
                                <FontAwesomeIcon icon="sign-out-alt" title="Sign Out" />
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default withRouter(connect(mapStateToProps)(ClientNav))
