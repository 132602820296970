import React from 'react'

class NotFound extends React.Component {
    state = {

    }

    render = () => {
        return (
            <div className="container">
                <p>Unable to find the page.</p>
            </div>
        )
    }
}

export default NotFound
