import immutable from 'immutable'

import Model from './model'

class SourcesModel extends Model {
    _actionType = null
    _collection = null
    _primaryKey = 'id'
    _endpoint = 'sources'
    _fields = immutable.fromJS({})

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    validate() {
        console.log('in the validate function')
    }
}

export default SourcesModel
