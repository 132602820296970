import immutable from 'immutable'

import { SAVE_TRANSACTION, SAVE_LIVE_TRANSACTIONS, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_TRANSACTION:
        objectIndex = utils.getObjectIndex(state, action.model.id)
        if (objectIndex === -1) {
            return utils.addObject(state, action.model)
        }
        return utils.updateObject(state, action.model, objectIndex)
    case `LIST_${SAVE_TRANSACTION}`:
        action.models.forEach(model => {
            objectIndex = utils.getObjectIndex(newState, model.id)
            if (objectIndex === -1) {
                newState = utils.addObject(newState, model)
            } else {
                newState = utils.updateObject(newState, model, objectIndex)
            }
        })
        return newState
    case `LIST_${SAVE_LIVE_TRANSACTIONS}`:
        action.models.forEach(fbModel => {
            fbModel.createDate = { _seconds: fbModel.createDate.seconds, _nanoseconds: fbModel.createDate._nanoseconds }
            fbModel.updateDate = { _seconds: fbModel.updateDate.seconds, _nanoseconds: fbModel.updateDate._nanoseconds }
            if (fbModel.dateValue && fbModel.dateValue.seconds) {
                fbModel.dateValue = { _seconds: fbModel.dateValue.seconds, _nanoseconds: fbModel.dateValue._nanoseconds }
            } else {
                fbModel.dateValue = null
            }
            objectIndex = utils.getObjectIndex(newState, fbModel.id)
            if (objectIndex === -1) {
                newState = utils.addObject(newState, fbModel)
            } else {
                newState = utils.updateObject(newState, fbModel, objectIndex)
            }
        })
        return newState
    case `DELETE_${SAVE_LIVE_TRANSACTIONS}`:
        // If the delete comes from the watcher
        newState = newState.filter((i) => {
            return i.get('id') !== action.model.id
        })
        return newState
    case 'DELETE_STATION':
        newState = newState.filter((i) => {
            return i.get('stationId') !== action.model.stationId
        })
        return newState
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}

