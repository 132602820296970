import React from 'react'
import { connect } from 'react-redux'

// models
import UserModel from '../../../services/models/user-model'
import InvitationModel from '../../../services/models/invitation-model'

// utilities
import SHSForm from '../../utils/shs-form'
import ModelInput from '../../utils/model-input'

// actions
import { updateModel } from '../../../actions/api-model'

// constants
import { ALL_ROLES } from '../../../services/constants/roles'

class editUser extends React.Component {
    state = {
        user: null,
        roleOptions: ALL_ROLES
    }

    componentDidMount = () => {
        const { user, auth } = this.props
        if (user) {
            let userModel = new UserModel()
            userModel.hydrate(user.toJSON())
            this.setState({ user: userModel })
        }
        const roleIndex = ALL_ROLES.indexOf(auth.get('role'))
        this.setState({ roleOptions: ALL_ROLES.slice(roleIndex) })
    }

    successFX = () => {
        this.props.onClose()
    }

    submitFX = (formData, formId) => {
        const { dispatch } = this.props
        const { user } = this.state
        let newModel = new UserModel()
        newModel.hydrate(user.getJSON())
        dispatch(updateModel(newModel, { firstName: formData.firstName, lastName: formData.lastName, role: formData.role }, formId))
    }

    render = () => {
        const { user } = this.state
        if (!user) {
            return <h1>Error finding User.</h1>
        }
        let emptyInvitation = new InvitationModel()
        let roleString = user.get('roleId').slice(user.get('roleId').indexOf('/') + 1)
        return (
            <div>
                <h1 className="my-2">Edit User</h1>
                <p>To edit a user's email, please delete them and send an invitation to the new email</p>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save">
                    <ModelInput
                        model={user}
                        field="firstName"
                        label="First Name"
                        placeholder="" />
                    <ModelInput
                        model={user}
                        field="lastName"
                        label="Last Name"
                        placeholder="" />
                    <ModelInput
                        model={emptyInvitation}
                        default={roleString}
                        options={this.state.roleOptions}
                        field="role"
                        label="User Role" />
                </SHSForm>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth'),
        user: state.get('users').find((u) => {
            return u.get('authId') === ownProps.userId
        })
    }
}

export default connect(mapStateToProps)(editUser)
