import React from 'react'
import { connect } from 'react-redux'

// constants
import { buildRoutePath } from '../../../constants/routes'

// models
import EntityModel from '../../../services/models/entity-model'
import StationModel from '../../../services/models/station-model'
import TruckModel from '../../../services/models/truck-model'

// utilities
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

// actions
import { updateModel, findModel } from '../../../actions/api-model'


class EditEntity extends React.Component {
    state = {
        entity: this.props.entity,
        type: this.props.entity ? this.props.entity.get('type') : null,
        formId: null,
        errorMessage: '',
        nextPath: null
    }

    componentDidMount = () => {
        const { dispatch, entity, match } = this.props
        if (entity) {
            let entityModel = new EntityModel()
            entityModel.hydrate(entity.toJSON())
            this.setState({ entity: entityModel, type: entity.get('type') })
        } else {
            dispatch(findModel(new EntityModel(), match.params.entityId))
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.entity && prevProps.entity !== this.props.entity) {
            let entityModel = new EntityModel()
            entityModel.hydrate(this.props.entity.toJSON())
            this.setState({ entity: entityModel, type: this.props.entity.get('type') })
        }
        if (this.props.newStation !== prevProps.newStation || this.props.newTruck !== prevProps.newTruck) {
            if (this.state.nextPath) {
                this.props.history.push(this.state.nextPath)
            }
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    successFX = (body) => {
        // Dispatch Create Truck or Station entity based on type
        const { dispatch, routes } = this.props
        const { entity, formId } = this.state
        let newModel = null
        let nextPath = null

        if (this.state.type === 'station') {
            // dispatch create a station
            newModel = new StationModel()
            nextPath = buildRoutePath(routes.stationsEdit, { stationId: entity.get('id') })
        } else {
            // dispatch a truck with the type set
            newModel = new TruckModel()
            nextPath = buildRoutePath(routes.fleetEdit, { fleetId: entity.get('id') })
        }

        if (newModel) {
            newModel.hydrate(entity.getJSON())
            // newModel.set('type', this.state.type)
            dispatch(updateModel(newModel, { type: this.state.type, display: entity.get('display') }, formId))
            if (!this.state.nextPath) {
                this.setState({ nextPath: nextPath })
            }
        }
    }

    submitFX = (formData, formId) => {
        const { dispatch } = this.props
        const { entity } = this.state
        let newModel = new EntityModel()
        newModel.hydrate(entity.getJSON())
        dispatch(updateModel(newModel, { type: formData.type, typeSet: true }, formId))
        this.setState({ formId: formId })
    }

    render = () => {
        const { entity } = this.state
        return (
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 mb-5">
                    <h1 className="mt-4">Set Entity Type.</h1>
                    <p className="mb-5">This will determine what views and reports are associated with this item.</p>
                    { !entity && <div>Fetching Data.</div>}
                    { entity &&
                        <>
                            <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save Changes">
                                <InputElement
                                    type="valueSelect"
                                    label="Type"
                                    id="type"
                                    onChange={this.handleChange}
                                    default={entity.get('type')}
                                    options={ [{ value: 'truck-biodiesel', label: 'Biodiesel' }, { value: 'station', label: 'Refueling Station' }, { value: 'truck-diesel', label: 'Diesel' } ]}
                                    required />
                            </SHSForm>
                        </>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const entityId = ownProps.match.params.entityId
    return {
        entity: state.get('entities').find(entity => entity.get('id') === entityId),
        newStation: state.get('stations').find(s => s.get('id') === entityId),
        newTruck: state.get('truckList').find(t => t.get('id') === entityId)
    }
}

export default connect(mapStateToProps)(EditEntity)
