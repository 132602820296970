import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// actions
import { SAVE_LIVE_PRICES } from '../../../../services/constants/actions'
import { watchFirestoreDocsSubCollection } from '../../../../actions/api-model'
import { buildRoutePath } from '../../../../constants/routes'

// components
import StationPricesTable from '../../../general/station-prices-table'

class List extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch } = this.props
        let stationId = this.props.match.params.stationId
        dispatch(watchFirestoreDocsSubCollection('stations', stationId, 'prices', SAVE_LIVE_PRICES))
    }

    render = () => {
        const { station, routes } = this.props
        if (!station) {
            return 'Loading'
        }

        let createLink = buildRoutePath(routes.stationsPriceCreate, { stationId: station.get('id') })
        let backLink = buildRoutePath(routes.stationsView, { stationId: station.get('id') })
        return (
            <>
                <div className="row mb-4">
                    <div className="col-sm-12">
                        <NavLink to={backLink} className="btn-label">
                            <FontAwesomeIcon icon="angle-left" title="Go Back" className="mr-2" />
                            Go Back
                        </NavLink>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-10">
                        <div className="h1">
                            { station.get('display') }: Price History
                        </div>
                        <p>
                            Ensure the time periods do not overlap by editing existing entries as needed.
                        </p>
                    </div>
                    <div className="col-sm-2">
                        <NavLink to={createLink} className="btn btn-outline-secondary btn-blue py-3">
                            Add New Price
                        </NavLink>
                    </div>
                </div>
                <StationPricesTable stationId={station.get('id')} routes={routes} />
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        station: state.get('stations').find(s => s.get('id') === ownProps.match.params.stationId),
        transactions: state.get('transactions').filter(t => t.get('stationId') === ownProps.match.params.stationId)
    }
}

export default connect(mapStateToProps)(List)
