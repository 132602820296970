import uuidv4 from 'uuid/v4'
import immutable from 'immutable'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_USER } from '../constants/actions'

class UserModel extends Model {
    _actionType = SAVE_USER
    _collection = COLLECTIONS.USER_COLLECTION
    _endpoint = 'users'
    _prefix = 'user_'
    _primaryKey = 'authId'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        authId: {
            type: 'string',
            isRequired: true
        },
        hasAccepted: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        firstName: {
            type: 'string',
            isRequired: true
        },
        lastName: {
            type: 'string',
            isRequired: true
        },
        email: {
            type: 'string',
            inputType: 'email',
            isRequired: true
        },
        clientId: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.CLIENT_COLLECTION
        },
        roleId: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.ROLE_COLLECTION
        },
        isDeleted: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        _lastEditedBy: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.USER_COLLECTION
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    acceptInvitation = (params) => {
        let modelJSON = this.getJSON()
        const payload = { ...modelJSON, ...params }
        return this.customAction('accept-invitation/', 'POST', payload)
    }

    listClientUsers = (payload) => {
        return this.customAction('list-client-users/', 'GET', payload)
    }
}

export default UserModel
