import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Modal extends React.Component {
    state = {

    }

    componentDidMount = () => {
        document.body.classList.add('modal-open')
    }

    componentWillUnmount = () => {
        document.body.classList.remove('modal-open')
    }

    render = () => {
        return (
            <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="modalTitleLabel">
                <div onClick={this.props.onClose} className="modal-backdrop fade show"/>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="mb-5 modal-close">
                                <FontAwesomeIcon icon="times-circle" size="lg" title="Close" className="float-right mr-2 mt-2" onClick={this.props.onClose} />
                            </div>
                            <div>
                                {this.props.children}
                                <div className="mt-4 mx-auto text-center" onClick={this.props.onClose}><label>Cancel</label></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal
