import immutable from 'immutable'

import { SAVE_ENTITY, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_ENTITY:
        action.model.clients = action.model.clients || []
        objectIndex = utils.getObjectIndex(state, action.model.id)
        if (objectIndex === -1) {
            return utils.addObject(state, action.model)
        }
        return utils.updateObject(state, action.model, objectIndex)
    case `LIST_${SAVE_ENTITY}`:
        action.models.forEach(model => {
            model.clients = model.clients || []
            objectIndex = utils.getObjectIndex(newState, model.id)
            if (objectIndex === -1) {
                newState = utils.addObject(newState, model)
            } else {
                newState = utils.updateObject(newState, model, objectIndex)
            }
        })
        return newState
    case 'DELETE_TRUCK':
        return state.filter((stateEntity) => stateEntity.get('id') !== action.model.truckId)
    case 'DELETE_STATION':
        return state.filter((stateEntity) => stateEntity.get('id') !== action.model.stationId)
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
