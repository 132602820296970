import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

// actions
import { customModelAction } from '../../actions/api-model'
import { buildRoutePath, apiRoutes, adminRoutes } from '../../constants/routes'

// models
import EntityModel from '../../services/models/entity-model'

// components
import MUIDataTable from 'mui-datatables'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    rowsPerPage: 10
}

class EntityTable extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(customModelAction(new EntityModel(), apiRoutes.listEntities, {}))
    }

    renderFleet = () => {
        const { entities } = this.props

        const columns = [
            {
                name: 'display',
                label: 'Display'
            },
            {
                name: 'entity_id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let link = buildRoutePath(adminRoutes.entityEdit, { entityId: value })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">Set Type</NavLink>
                        )
                    }
                }
            }
        ]

        let entityArray = []
        if (entities) {
            entities.forEach((v) => {
                let t = {
                    entity_id: v.get('id'),
                    display: v.get('display')
                }
                entityArray.push(t)
            })
        }

        return (
            <MUIDataTable
                title=""
                data={entityArray}
                columns={columns}
                options={options}
            />
        )
    }

    render = () => {
        let label = this.props.label ? this.props.label : 'Unassigned Entities'
        let cta = this.props.cta ? this.props.cta : null
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="widget">
                        <h3 className="border-bottom mb-3">
                            {label}
                            {cta}
                        </h3>
                        <div className="mui-table">
                            { this.renderFleet() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        entities: state.get('entities').filter(entity => {
            return !entity.get('typeSet')
        })
    }
}

export default connect(mapStateToProps)(EntityTable)
