import immutable from 'immutable'

import { SAVE_TRUCK_POSITIONS, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case `LIST_${SAVE_TRUCK_POSITIONS}`:
        action.models.forEach((truck) => {
            truck.pos.forEach(position => {
                let timeStamp = position.time.replace('/Date(', '').replace(')/', '')
                let newId = `${truck.truckId}-${timeStamp}`
                objectIndex = utils.getObjectIndex(newState, newId)
                let newPosition = {
                    id: newId,
                    lat: position.lat,
                    lon: position.lon,
                    time: timeStamp,
                    truckId: truck.truckId
                }
                if (objectIndex === -1) {
                    newState = utils.addObject(newState, newPosition)
                } else {
                    newState = utils.updateObject(newState, newPosition, objectIndex)
                }
            })
        })
        return newState
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
