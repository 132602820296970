import uuidv4 from 'uuid/v4'
import immutable from 'immutable'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_INVITATION } from '../constants/actions'
import { ALL_ROLES } from '../constants/roles'

class InvitationModel extends Model {
    _actionType = SAVE_INVITATION
    _collection = COLLECTIONS.INVITATION_COLLECTION
    _endpoint = 'invitations'
    _prefix = 'invitation_'
    _primaryKey = 'id'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        clientId: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.CLIENT_COLLECTION
        },
        email: {
            type: 'string',
            inputType: 'email',
            isRequired: true
        },
        firstName: {
            type: 'string',
            isRequired: true
        },
        role: {
            type: 'reference',
            inputType: 'select',
            options: ALL_ROLES,
            isRequired: true,
            collection: COLLECTIONS.ROLE_COLLECTION
        },
        status: {
            type: 'string',
            isRequired: true,
            default: 'PENDING'
        },
        expires: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.fromMillis(new Date().getTime() + (7 * 86400000)) : null
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        _creator: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.USER_COLLECTION
        },
        _lastEditedBy: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.USER_COLLECTION
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    lookupInvitationStatus = (params) => {
        // TODO: should we check that we have the parameters we need for this route?
        return this.customAction('lookup-invitation-status/', 'GET', params)
    }

}

export default InvitationModel
