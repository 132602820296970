import React from 'react'

class WidgetCallout extends React.Component {
    state = {

    }

    render = () => {
        return (
            <div className="widget">
                <h3 className="border-bottom">{ this.props.header }</h3>
                <div className="widget-wrapper">
                    <div className="callout">
                        <span>{ this.props.value }</span>
                        <label>{ this.props.label }</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default WidgetCallout
