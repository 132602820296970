import React from 'react'
import { connect } from 'react-redux'

import { formatTotalWithUnit } from '../../utils/formatters'

// components
import StationsTable from '../../general/stations-table'
import WidgetCallout from '../../general/widget-callout'
// import MapBlockStations from '../../general/mapblock-stations'
// import BiodieselConsumed from '../../general/biodiesel-consumed'
// import CoOffset from '../../general/co-offset'

class List extends React.Component {
    state = {

    }

    calcTotalInventoryVolume = () => {
        const { stations } = this.props
        if (stations) {
            let total = 0
            stations.forEach(station => {
                let gal = 1
                if (station.get('id') === '4241') {
                    gal = station.get('P_TankLevelVolume') ? Number(station.get('P_TankLevelVolume').replace(' gal', '')) : 0
                } else if (station.get('Volume_Type') === 'Default') {
                    gal = station.get('S_Tank1_Volume') ? Number(station.get('S_Tank1_Volume').replace(' gal', '')) : 0
                } else {
                    gal = station.get('S_Tank1_TCVolume') ? Number(station.get('S_Tank1_TCVolume').replace(' gal', '')) : 0
                }
                total = total + gal
            })
            return <WidgetCallout header="Total Inventory Volume" value={formatTotalWithUnit(total)} label="gals" />
        }
        return <WidgetCallout header="Total Inventory Volume" value="N/A" label="" />
    }

    calcTotalUllage = () => {
        const { stations } = this.props
        if (stations) {
            let total = 0
            stations.forEach(station => {
                let gal = station.get('S_Tank1_Ullage') ? Number(station.get('S_Tank1_Ullage').replace(' gal', '')) : 0
                total = total + gal
            })
            return <WidgetCallout header="Total Ullage" value={formatTotalWithUnit(total)} label="gals" />
        }
        return <WidgetCallout header="Total Ullage" value="N/A" label="" />
    }

    render = () => {
        const { stations, routes } = this.props

        return (
            <div className="container">
                <StationsTable label="Stations" stations={stations} path={routes.stationsView} />
                <div className="row">
                    <div className="col-md-6">
                        { this.calcTotalInventoryVolume() }
                    </div>
                    <div className="col-md-6">
                        { this.calcTotalUllage() }
                        {/* <MapBlockStations stationIds={stationIds} stationPath={routes.stationsView} /> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stations: state.get('stations')
    }
}

export default connect(mapStateToProps)(List)
