import React from 'react'
import { NavLink } from 'react-router-dom'
import { buildRoutePath } from '../../constants/routes'
import moment from 'moment'

// components
import MUIDataTable from 'mui-datatables'
import { biodieselRuntimeCalc } from '../utils/client-offset'

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    customSort: (data, colIndex, order, meta) => {
        if (colIndex === 3) {
            return data.sort((a, b) => {
                // Active Fault, 0, Low Fuel, Operational
                let aVal = -1
                switch (a.data[colIndex]) {
                case 'Active Fault':
                    aVal = 4
                    break
                case 'Needs Attention':
                    aVal = 3
                    break
                case 'Low Fuel':
                    aVal = 2
                    break
                default:
                    aVal = 1
                    break
                }
                let bVal = 1
                switch (b.data[colIndex]) {
                case 'Active Fault':
                    bVal = 4
                    break
                case 'Needs Attention':
                    bVal = 3
                    break
                case 'Low Fuel':
                    bVal = 2
                    break
                default:
                    bVal = 1
                    break
                }
                return (aVal < bVal ? -1 : 1) * (order === 'desc' ? 1 : -1)
            })
        }
        return data.sort((a, b) => {
            return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1)
        })
    }
}

class FleetTable extends React.Component {
    state = {

    }

    renderFleet = () => {
        const { path, vehicles, allClients, clientId } = this.props
        let columns = [
            {
                name: 'display',
                label: 'Vehicle'
            },
            {
                name: 'connected',
                label: 'Last Connected',
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            return moment.unix(value).format('MM/DD/YY hh:mm:ss A')
                        }
                        return 'Not Set'
                    }
                }
            },
            {
                name: 'status',
                label: 'Status',
                options: {
                    customBodyRender: (value) => {
                        let c = value.toLowerCase().replaceAll(' ', '-')
                        return (<span className={c}>{value}</span>)
                    }
                }
            },
            {
                name: 'bioDieselRuntimePer',
                label: 'Biodiesel %',
                options: {
                    customBodyRender: (value) => {
                        if (value < 0 || value > 100) {
                            return 'Err'
                        }
                        return `${value}%`
                    }
                }
            },
            {
                name: 'vehicle_id',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let link = buildRoutePath(path, { fleetId: value })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">View</NavLink>
                        )
                    }
                }
            }
        ]
        if (allClients) {
            let allClientColumn = {
                name: 'clients',
                label: 'Client (s)',
                options: {
                    customBodyRender: (value) => {
                        if (value && value.length > 0) {
                            let tName = value[0]
                            let count = value.length > 1 ? `(+${value.length - 1})` : null
                            return <div title={value.join(', ')}>{tName} {count}</div>
                        }
                    }
                }
            }
            columns.splice(1, 0, allClientColumn)
        }

        let fleetArray = []
        if (vehicles) {
            vehicles.forEach((v) => {
                let clientList = null
                if (v.get('clients') && allClients) {
                    clientList = []
                    v.get('clients').forEach(id => {
                        let client = allClients.find(c => id === c.get('id'))
                        if (client) {
                            clientList.push(client.get('name'))
                        }
                    })
                }
                let time = v.get('report') && v.getIn(['report', 'Time']) ? v.getIn(['report', 'Time']).split('.')[0] + v.getIn(['report', 'Time']).slice(-3) : null
                let values = biodieselRuntimeCalc(v, clientId)
                let t = {
                    vehicle_id: v.get('id'),
                    clients: clientList,
                    display: v.get('display'),
                    connected: time ? moment(time).unix() : null,
                    status: v.get('clients').size > 0 ? v.get('status') || 'Operational' : 'No Client Set',
                    bioDieselRuntimePer: values.percent ? values.percent : 0
                }
                fleetArray.push(t)
            })
        }

        if (!vehicles || vehicles.size === 0) {
            return <label className="text-center w-100">No active vehicles.</label>
        }

        return (
            <MUIDataTable
                title=""
                data={fleetArray}
                columns={columns}
                options={options}
            />
        )
    }

    render = () => {
        let label = this.props.label ? this.props.label : 'Fleet'
        let cta = this.props.cta ? this.props.cta : null
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="widget">
                        <h3 className="border-bottom mb-3">
                            {label}
                            {cta}
                        </h3>
                        <div className="mui-table">
                            { this.renderFleet() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FleetTable
