import React from 'react'
import { connect } from 'react-redux'

// actions
import { findModel } from '../../../actions/api-model'

// models
import UserModel from '../../../services/models/user-model'
import ClientModel from '../../../services/models/client-model'

// components
import Users from './users'
import Invitations from './invitations'
import Details from './details'
import Modal from '../../utils/modal'
import Edit from './edit'
import EmailPreferences from './email-preferences'

class AccountView extends React.Component {
    state = {
        userModel: new UserModel(),
        showModal: false
    }

    componentDidMount = () => {
        const { dispatch, auth } = this.props
        if (auth && auth.get('clientId')) {
            dispatch(findModel(new ClientModel(), auth.get('clientId')))
        }
    }

    componentDidUpdate = (prevProps) => {
        const { dispatch, auth } = this.props
        if (auth && prevProps.auth !== auth && auth.get('clientId')) {
            dispatch(findModel(new ClientModel(), auth.get('clientId')))
        }
    }

    handleModal = (bool) => {
        this.setState({ showModal: bool })
    }

    render = () => {
        const { client, routes, auth } = this.props
        if (!client) {
            return <h1>Loading...</h1>
        }
        let editCTA = <span className="a-style float-right btn btn-outline-secondary" onClick={() => this.handleModal(true)}>Edit</span>

        const adminEmails = ['irwin@shadyhillstudios.com', 'david@shadyhillstudios.com', 'c.huwyler@optimustec.com', 'h.york@optimustec.com']
        let adminAccess = false
        if (adminEmails.indexOf(auth.get('authEmail')) !== -1 || auth.get('authEmail').indexOf('@optimustec') !== -1) {
            adminAccess = true
        }
        return (
            <>
                <div className="row">
                    <div className="col-4">
                        <Details client={ client } cta={editCTA} auth={ auth } routes={routes} />
                    </div>
                    <div className="col-8">
                        <Users clientId={ client.get('id') } />
                        <Invitations clientId={ client.get('id') } />
                        { adminAccess &&
                            <EmailPreferences />
                        }
                    </div>
                </div>
                { this.state.showModal &&
                    <Modal onClose={() => this.handleModal(false)}>
                        <Edit client={client} onClose={() => this.handleModal(false)} />
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    // TODO: Only get my users
    let auth = state.get('auth')
    return {
        auth: auth,
        client: state.get('clients').find((client) => {
            if (auth && auth.get('clientId')) {
                return client.get('id') === auth.get('clientId')
            }
            return false
        }),
        users: state.get('users')
    }
}

export default connect(mapStateToProps)(AccountView)
