import immutable from 'immutable'
import { fireauth } from '../firebase/firebase'

class Model {
    _values = immutable.Map()
    _baseApiUrl = process.env.REACT_APP_FIREBASE_FX_URL

    constructor(options) {
        if (options.db) {
            this.db = options.db
            this.firestore = options.firestore
            this.isFrontEnd = false
        } else {
            this.isFrontEnd = true
        }
    }

    verifyConstruction = () => {
        if (typeof this._collection === 'undefined' || this._collection === '') {
            throw new Error(`Missing required parameter '_collection' for ${this.constructor.name}`)
        }
        return this
        // what other fields should we require on a model?
    }

    validate = () => {
        console.log('in the validate function of the base model for ', this._collection)
        return this
    }

    get = (field) => {
        if (this._fields.has(field)) {
            return this._values.get(field)
        }
        // not sure if we want to throw an error here or just undefined?
        return null
        // throw new Error(`${field} is not a property of ${this.constructor.name}.`)
    }

    // TODO: create a map of changed fields?
    // TODO: what about timestamp for updated?
    set = (field, value) => {
        if (this._fields.has(field)) {
            this._values = this._values.set(field, value)
            return value
        }
        // not sure if we want to throw an error here or just fail gracefully?
        return null
        // throw new Error(`${field} is not a valid property of this ${this.constructor.name}.`)
    }

    getFieldDefinition = (field) => {
        if (this._fields.has(field)) {
            return this._fields.get(field).toJSON()
        }
        throw new Error(`${field} does not exist on ${this.constructor.name}`)
    }

    _buildFromObj = (dataObj) => {
        const keys = Object.keys(dataObj)
        const curValues = this._values
        // loop over the keys of the dataObj and try to set the existing fields
        try {
            keys.forEach(k => {
                this.set(k, dataObj[k])
            })
        } catch (e) {
            this._reset(curValues)
            throw e
        }
        return this
    }

    _generateRequiredDefaultFields = () => {
        this._fields.map((val, key) => {
            if (!this._values.has(key) && val.get('isRequired') && val.has('default') && !val.has('serverSide')) {
                return this.set(key, val.get('default'))
            }
            return null
        })
        return this
    }

    _generateRequiredServerField = () => {
        this._fields.map((val, key) => {
            if (!this._values.has(key) && val.get('isRequired') && val.has('default') && val.has('serverSide')) {
                return this.set(key, val.get('default'))
            }
            return null
        })
        return this
    }

    _generateAPIURL = () => {
        let parentPath = ''
        if (this._isSubCollection) {
            parentPath = `${this._parentCollection}/${this.get(this._parentPK)}/`
        }
        return `${this._baseApiUrl}${parentPath}${this._endpoint}/`
    }

    _generateDocumentPath = () => {
        if (this._isSubCollection) {
            return this.db
                .collection(this._parentCollection)
                .doc(this.get(this._parentPK))
                .collection(this._collection)
                .doc(this.get(this._primaryKey || 'id'))
        }
        return this.db.collection(this._collection).doc(this.get(this._primaryKey || 'id'))
    }

    _generateCollectionPath = () => {
        if (this._isSubCollection) {
            return this.db
                .collection(this._parentCollection)
                .doc(this.get(this._parentPK))
                .collection(this._collection)
        }
        return this.db.collection(this._collection)
    }

    customFileAction = (route, method, payload = {}) => {
        return fireauth.currentUser.getIdToken()
            .then(token => {
                let query = '?' +
                        Object.keys(payload).map(key => {
                            return encodeURIComponent(key) + '=' + encodeURIComponent(payload[key])
                        }).join('&')
                let fetchParams = {
                    headers: { Authorization: `Bearer ${token}` },
                    method: 'GET'
                }
                const apiUrl = `${this._generateAPIURL()}${route}${query}`
                return fetch(apiUrl, fetchParams)
            })
            .then((response) => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    const link = document.createElement('a')
                    link.href = result.data
                    link.target = '_blank'
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode.removeChild(link)
                    return true
                }
                result.hasError = true
                return result
            })
            .catch(err => {
                console.log('something went wrong... ', err)
            })
    }

    customAction = (route, method, payload = {}) => {
        return fireauth.currentUser.getIdToken()
            .then(token => {
                let query = ''
                let params = {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    method: method
                }
                if (method === 'GET') {
                    query = '?' +
                        Object.keys(payload).map(key => {
                            return encodeURIComponent(key) + '=' + encodeURIComponent(payload[key])
                        }).join('&')
                } else if (method === 'POST') {
                    params.body = JSON.stringify(payload)
                }
                const apiUrl = `${this._generateAPIURL()}${route}${query}`
                return Promise.all([token, fetch(apiUrl, params)])
            })
            .then(([token, response]) => {
                return Promise.all([token, response, response.json()])
            })
            .then(([token, response, result]) => {
                if (response.ok) {
                    return result
                }
                result.hasError = true
                return result
            })
            .catch(err => {
                console.log('what happened in here?')
                console.log(err)
            })
    }

    create = (generateDefaults = false) => {
        if (generateDefaults) {
            this._generateRequiredDefaultFields()
        }
        this.validate()
        if (this.isFrontEnd) {
            // TODO: need to check that currentUser exists
            // is it possible to debounce?
            return fireauth.currentUser.getIdToken()
                .then(token => {
                    const apiUrl = `${this._generateAPIURL()}`
                    const payload = this.getJSON()
                    const params = {
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(payload),
                        method: 'POST'
                    }
                    return Promise.all([token, fetch(apiUrl, params)])
                })
                .then(([token, response]) => {
                    return Promise.all([token, response, response.json()])
                })
                .then(([token, response, result]) => {
                    if (response.ok) {
                        return result
                    }
                    result.hasError = true
                    return result
                })
                .catch(err => {
                    // TODO: need to log this?
                    console.log('what happened?')
                    console.log(err)
                })
        }
        this._generateRequiredServerField()
        const modelJSON = this.getJSON()
        const docPath = this._generateDocumentPath()
        return docPath.set(modelJSON)
            .then(() => {
                this.prepareForJSON()
                return this.getJSON()
            })
            .catch(err => {
                console.log('problem setting the model')
                console.log(err)
                throw new Error()
            })
    }

    update = (pk, fieldsObj) => {
        if (this.isFrontEnd) {
            // TODO: need to check that currentUser exists
            // is it possible to debounce?
            return fireauth.currentUser.getIdToken()
                .then(token => {
                    const apiUrl = `${this._generateAPIURL()}${pk}`
                    const payload = fieldsObj
                    const params = {
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(payload),
                        method: 'POST'
                    }
                    return Promise.all([token, fetch(apiUrl, params)])
                })
                .then(([token, response]) => {
                    return Promise.all([token, response, response.json()])
                })
                .then(([token, response, result]) => {
                    if (response.ok) {
                        return result
                    }
                    result.hasError = true
                    return result
                })
                .catch(err => {
                    // TODO: need to log this?
                    console.log('what happened?')
                    console.log(err)
                })
        }
        // TODO: how do we validate the fields?
        // TODO: This is blowing up the timestamp
        fieldsObj.updateDate = this.firestore.Timestamp.now()
        this.set(this._primaryKey || 'id', pk)
        const docPath = this._generateDocumentPath()
        const modelJSON = this.getJSON()
        return docPath.get()
            .then((doc) => {
                if (doc.exists) {
                    return docPath.update(fieldsObj)
                }
                this._generateRequiredServerField()
                return docPath.set({ ...fieldsObj, ...modelJSON })
            })
            .then(() => {
                this.hydrate({ ...fieldsObj, ...modelJSON })
                this.prepareForJSON()
                return this.getJSON()
            })
            .catch(err => {
                console.log('problem setting the model')
                console.log(err)
                throw new Error()
            })
    }

    delete = () => {
        console.log('attempting to delete...')
        let modelJSON = this.getJSON()
        const docPath = this._generateDocumentPath()
        return docPath.delete()
            .then(() => {
                // TODO: Returnnig the original json to help remove it from state, is this correct?
                return modelJSON
            })
            .catch(err => {
                console.log('problem deleting the model')
                console.log(err)
                throw new Error()
            })
    }

    upsert = () => {
        let modelJSON = this.getJSON()
        const docPath = this._generateDocumentPath()

        return docPath.get()
            .then((doc) => {
                if (doc.exists) {
                    // run update
                    this.hydrate(doc.data())
                    modelJSON.updateDate = this.firestore.Timestamp.now()
                    return docPath.update(modelJSON)
                }
                // run set
                this._generateRequiredServerField()
                modelJSON = this.getJSON()
                return docPath.set(modelJSON)
            })
            .then(() => {
                this.prepareForJSON()
                return this.getJSON()
            })
            .catch(err => {
                console.log('problem updating / creating the model')
                console.log(err)
                throw new Error()
            })
    }

    list = () => {
        if (this.isFrontEnd) {
            return fireauth.currentUser.getIdToken()
                .then(token => {
                    const apiUrl = `${this._generateAPIURL()}`
                    const params = {
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        method: 'GET'
                    }
                    return Promise.all([token, fetch(apiUrl, params)])
                })
                .then(([token, response]) => {
                    return Promise.all([token, response, response.json()])
                })
                .then(([token, response, result]) => {
                    if (response.ok) {
                        return result
                    }
                    result.hasError = true
                    return result
                })
                .catch(err => {
                    // TODO: need to log this
                    console.log('what happened?')
                    console.log(err)
                })
        }
        let allResults = []
        // NOTE: Review this method, should we just do a generate path and collapse?
        if (this._isSubCollection) {
            return this.db.collection(this._parentCollection)
                .doc(this.get(this._parentPK))
                .collection(this._collection).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        this._reset()
                        this.hydrate(doc.data())
                        this.prepareForJSON()
                        allResults.push(this.getJSON())
                    })
                    return allResults
                }).catch(err => {
                    console.log('ran into an issue listing the subcollection... ', err)
                    throw new Error()
                })
        }
        return this.db.collection(this._collection).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    this._reset()
                    this.hydrate(doc.data())
                    this.prepareForJSON()
                    allResults.push(this.getJSON())
                })
                return allResults
            }).catch(err => {
                console.log('ran into an issue listing... ', err)
                throw new Error()
            })
    }

    limitList = (limit = 10) => {
        // Removing front end option
        // TODO: Can we just extend the list function??
        let allResults = []
        // NOTE: Review this method, should we just do a generate path and collapse?
        if (this._isSubCollection) {
            return this.db.collection(this._parentCollection)
                .doc(this.get(this._parentPK))
                .collection(this._collection).limit(limit).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        this._reset()
                        this.hydrate(doc.data())
                        this.prepareForJSON()
                        allResults.push(this.getJSON())
                    })
                    return allResults
                }).catch(err => {
                    console.log('ran into an issue listing the subcollection... ', err)
                    throw new Error()
                })
        }
        return this.db.collection(this._collection).limit(limit).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    this._reset()
                    this.hydrate(doc.data())
                    this.prepareForJSON()
                    allResults.push(this.getJSON())
                })
                return allResults
            }).catch(err => {
                console.log('ran into an issue listing... ', err)
                throw new Error()
            })
    }

    find = (pk) => {
        // TODO: do we need to validate the id again the input type that if could be?
        if (this.isFrontEnd) {
            return fireauth.currentUser.getIdToken()
                .then(token => {
                    const apiUrl = `${this._generateAPIURL()}${pk}`
                    const params = {
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        method: 'GET'
                    }
                    return Promise.all([token, fetch(apiUrl, params)])
                })
                .then(([token, response]) => {
                    return Promise.all([token, response, response.json()])
                })
                .then(([token, response, result]) => {
                    if (response.ok) {
                        return result
                    }
                    result.hasError = true
                    return result
                })
                .catch(err => {
                    // TODO: need to log this
                    console.log('what happened?')
                    console.log(err)
                })
        }
        const collectionPath = this._generateCollectionPath()
        return collectionPath.doc(pk).get()
            .then(doc => {
                if (doc.exists) {
                    this.hydrate(doc.data())
                    this.prepareForJSON()
                    return this.getJSON()
                }
                console.log('ALERT: problem finding the doc!')
                return null
            })
            .catch(err => {
                console.log('ERROR: error finding the doc')
                console.log(err)
                return null
            })
    }

    filter = (filters, makeJSON = false) => {
        if (this.isFrontEnd) {
            const query = '?' +
                Object.keys(filters).map(key => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
                }).join('&')
            return fireauth.currentUser.getIdToken()
                .then(token => {
                    // TODO: figure out how to wrap the filters in a query string
                    const apiUrl = `${this._baseApiUrl}${this._endpoint}/filter${query}`
                    const params = {
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        method: 'GET'
                    }
                    return Promise.all([token, fetch(apiUrl, params)])
                })
                .then(([token, response]) => {
                    return Promise.all([token, response, response.json()])
                })
                .then(([token, response, result]) => {
                    if (response.ok) {
                        return result
                    }
                    result.hasError = true
                    return result
                })
                .catch(err => {
                    // TODO: need to log this
                    console.log('what happened?')
                    console.log(err)
                })
        }
        let ref = this.db.collection(this._collection)
        Object.keys(filters).forEach(key => {
            const op = filters[key].operand ? filters[key].operand : '=='
            ref = ref.where(key, op, filters[key].value)
        })
        let allResults = []
        return ref.get()
            .then(querySnapshot => {
                if (makeJSON) {
                    querySnapshot.forEach(doc => {
                        this._reset()
                        this.hydrate(doc.data())
                        this.prepareForJSON()
                        allResults.push(this.getJSON())
                    })
                    return allResults
                }
                return querySnapshot
            }).catch(err => {
                console.log('ran into an issue filtering...')
                console.log(err)
                return null
            })
    }

    // hydrate = (dataObj, author = null, account = null) => {
    hydrate = (dataObj) => {
        this._buildFromObj(dataObj)
        return this
    }

    prepareForJSON = () => {
        this._fields.map((val, key) => {
            if (val.get('type') === 'reference' && this._values.has(key)) {
                return this.set(key, this.get(key).path)
            }
            return null
        })
    }

    setReference = (field, docId) => {
        if (this._fields.has(field) && this._fields.getIn([field, 'type']) === 'reference') {
            this.set(field, this.db.collection(this._fields.getIn([field, 'collection'])).doc(docId))
        }
        return this
    }

    setReferenceFromPath = (field, docPath) => {
        if (this._fields.has(field) && this._fields.getIn([field, 'type']) === 'reference') {
            this.set(field, this.db.doc(docPath))
        }
        return this
    }

    getJSON = () => {
        return this._values.toJSON()
    }

    // TODO: drop any fields that start with _ maybe?
    getPublicJSON = () => {
        return this._values.toJSON()
    }

    getActionType = () => {
        return this._actionType
    }

    // TODO: maybe this should not be exposed? but internalized as a function
    getCollection = () => {
        return this._collection
    }

    _prepareServerData = () => {
        // handle special fields
        let serverFields = {}
        const serverDefaults = ['timestamp', 'reference']
        this._fields.map((val, key) => {
            if (val.get('isRequired') && serverDefaults.indexOf(val.get('type')) !== -1) {
                switch (val.get('type')) {
                case 'timestamp':
                    serverFields[key] = this.firestore.FieldValue.serverTimestamp()
                    return true
                case 'reference':
                    serverFields[key] = this.db.collection(val.get('collection')).doc(this.get(key))
                    return true
                default:
                    return true
                }
            }
            return false
        })
        let modelData = this._values.toJSON()
        return { ...modelData, ...serverFields }
    }

    _reset = (values = immutable.Map()) => {
        this._values = values
    }
}

export default Model