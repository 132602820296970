import uuidv4 from 'uuid/v4'
import immutable from 'immutable'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_EMAIL_PREFERENCE } from '../constants/actions'

class EmailPreferencesModel extends Model {
    _actionType = SAVE_EMAIL_PREFERENCE
    _collection = COLLECTIONS.EMAIL_PREFERENCES_COLLECTION
    _endpoint = 'email-preferences'
    _primaryKey = 'id'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            default: `${uuidv4()}`,
            validator: ['isNotEmpty', 'isString', 'matchesRegex'],
            regexPattern: ''
        },
        truckInstant: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        truckDaily: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        truckWeekly: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        truckMonthly: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        stationInstant: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        stationDaily: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        stationWeekly: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        stationMonthly: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        userId: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.USER_COLLECTION
        },
        clientId: {
            type: 'reference',
            isRequired: true,
            collection: COLLECTIONS.CLIENT_COLLECTION
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }
}

export default EmailPreferencesModel
