import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

import { FB_CONFIG } from './config'

let firestore
let fireauth
let fbFunctions
let fbFieldValue
let fbStorage

const initFirebase = () => {
    firebase.initializeApp(FB_CONFIG)
    firestore = firebase.firestore()
    fireauth = firebase.auth()
    fbFunctions = firebase.functions()
    fbFieldValue = firebase.firestore.FieldValue
    fbStorage = firebase.storage()

    // TURN ON FOR LOCAL, OFF FOR PROD
    // fbFunctions.useFunctionsEmulator('http://localhost:5000')
}

const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
            }
        }
    ]
}

const actionCodeSettings = {
    url: 'http://localhost:3000/password-reset/',
    handleCodeInApp: true
}

export const firebaseUploadToStorage = (path, dir = 'clients') => {
    return fbStorage.ref().child(`${dir}/${path}/`)
}

export const firebaseStorageRefFromURL = (path) => {
    return fbStorage.refFromURL(path)
}

export const firebaseCallableFX = (fx, data) => {
    return fbFunctions.httpsCallable(fx)(data)
}

export {
    initFirebase,
    firestore,
    fireauth,
    fbFieldValue,
    fbFunctions,
    uiConfig,
    actionCodeSettings
}
