import React from 'react'

class SupportIndex extends React.Component {
    state = {}

    render = () => {
        return (
            <>
                <iframe
                    title="support"
                    className="clickup-embed clickup-dynamic-height"
                    src="https://forms.clickup.com/8463503/f/8294f-23031/RYJX4LVHQ3ANNPM66O"
                    width="100%"
                    height="1400px"
                    style={{ background: 'transparent', border: 'none'}} />
                <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js" />
            </>
        )
    }
}

export default SupportIndex
