import React from 'react'
import WidgetCircleGraph from './widget-circle-graph'
import { biodieselRuntimeCalc } from '../utils/client-offset'

class BioDieselRuntime extends React.Component {
    state = {
    }

    render = () => {
        const { vehicle, clientId } = this.props
        if (!vehicle || !vehicle.get('report')) {
            return null
        }
        let values = biodieselRuntimeCalc(vehicle, clientId)

        return (
            <WidgetCircleGraph percent={values.percent} header="Biodiesel Runtime" info={values.info} >
                <div className="d-flex">
                    <label>
                        Hours Bio <strong className="blue">{values.hoursBio}</strong>
                    </label>
                    <label className="mx-2">|</label>
                    <label>
                        Hours Diesel <strong className="blue">{values.hoursDiesel}</strong>
                    </label>
                </div>
            </WidgetCircleGraph>
        )
    }
}

export default BioDieselRuntime
