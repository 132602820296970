import immutable from 'immutable'
// import { nanoid } from 'nanoid'
import uuidv4 from 'uuid/v4'

import Model from './model'

import * as COLLECTIONS from '../constants/collections'
import { SAVE_PRICE } from '../constants/actions'

class PricesModel extends Model {
    _actionType = SAVE_PRICE
    _collection = COLLECTIONS.PRICE_COLLECTION
    _parentCollection = COLLECTIONS.STATION_COLLECTION
    _isSubCollection = true
    _parentPK = 'stationId'
    _endpoint = 'prices'
    _fields = immutable.fromJS({
        id: {
            type: 'string',
            isRequired: true,
            serverSide: true,
            default: `${uuidv4()}`
        },
        stationId: {
            type: 'string',
            isRequired: true
        },
        pricePerGallon: {
            type: 'number',
            isRequired: false
        },
        startDate: {
            type: 'timestamp',
            isRequired: true
        },
        isCurrent: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        hasChanged: {
            type: 'boolean',
            isRequired: true,
            default: true
        },
        isDeleted: {
            type: 'boolean',
            isRequired: true,
            default: false
        },
        userTimezoneOffset: {
            type: 'number',
            isRequired: true,
            default: new Date().getTimezoneOffset() / 60
        },
        endDate: {
            type: 'timestamp',
            isRequired: false
        },
        createDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        },
        updateDate: {
            type: 'timestamp',
            isRequired: true,
            serverSide: true,
            default: this.firestore ? this.firestore.Timestamp.now() : null
        }
    })

    constructor(options = {}) {
        super(options)
        this.verifyConstruction()
    }

    validate() {
        console.log('in the validate function of transaction model')
    }
}

export default PricesModel
