import immutable from 'immutable'

import { SAVE_USER, DELETE_USER, KILL_STATE } from '../services/constants/actions'

import * as utils from './utils'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    let newState = state
    switch (action.type) {
    case SAVE_USER:
        objectIndex = utils.getObjectIndex(state, action.model.authId, 'authId')
        if (objectIndex === -1) {
            return utils.addObject(state, action.model)
        }
        return utils.updateObject(state, action.model, objectIndex)
    case `LIST_${SAVE_USER}`:
        action.models.forEach(model => {
            objectIndex = utils.getObjectIndex(newState, model.authId, 'authId')
            if (objectIndex === -1) {
                newState = utils.addObject(newState, model)
            } else {
                newState = utils.updateObject(newState, model, objectIndex)
            }
        })
        return newState
    case DELETE_USER:
        return newState.filter(s => s.get('authId') !== action.model.authId)
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
