import React from 'react'

class ModelInputElement extends React.Component {

    constructor(props) {
        super(props)
        const { field, model } = props
        const fieldObj = props.model.getFieldDefinition(field)
        const inputType = props.type || fieldObj.inputType
        const value = props.default || model.get(field)
        this.state = { model, field, fieldObj, inputType, value, isActive: false }
    }

    handleChange = (evt) => {
        let { model } = this.state
        const value = this.state.inputType === 'checkbox' ? evt.target.checked : evt.target.value
        model.set(evt.target.id, value)
        // this.props.onChange(model)
        this.setState({ value, model })
    }

    renderLabel = () => {
        const label = this.props.label || this.state.fieldObj.label
        return <label>{label || <>&nbsp;</>}</label>
    }

    renderError = () => {
        return (
            <div className="invalid-feedback">
                {this.props.errorMessage || this.state.fieldObj.errorMessage || 'This field is required'}
            </div>
        )
    }

    renderHelperText = () => {
        return (
            <small className={`helper-text ${this.state.isActive ? 'visible' : 'invisible'}`}>
                {this.props.helperText || this.state.fieldObj.helperText}
            </small>
        )
    }

    renderSelect = (commonProps) => {
        const options = this.props.options || this.state.fieldObj.options || []
        return (
            <>
                {this.renderLabel()}
                <select {...commonProps}>
                    <option value=''>Select a value</option>
                    {options.map((option, idx) => {
                        return <option value={option} key={idx}>{option}</option>
                    })}
                </select>
            </>
        )
    }

    renderTextArea = commonProps => {
        return (
            <>
                {this.renderLabel()}
                <textarea
                    onBlur={() => this.setState({ isActive: false })}
                    onFocus={() => this.setState({ isActive: true })}
                    {...commonProps} />
            </>
        )
    }

    renderInput = (commonProps) => {
        // should type be available in the field too?
        const type = this.props.type || this.state.inputType || 'text'
        return (
            <>
                {this.renderLabel()}
                <input
                    onBlur={() => this.setState({ isActive: false })}
                    onFocus={() => this.setState({ isActive: true })}
                    autoComplete="off"
                    type={type}
                    {...commonProps} />
            </>
        )
    }

    renderCheckbox = (commonProps) => {
        const label = this.props.label || this.state.fieldObj.label
        return (
            <>
                <input type="checkbox" {...commonProps} checked={this.state.model.get(this.state.field) || false} />
                <label className="form-check-label">{label}</label>
            </>
        )
    }

    renderFormInput = () => {
        const commonProps = {
            value: this.state.value || '',
            id: this.props.id || this.props.field,
            name: this.props.id || this.props.field,
            onChange: this.handleChange,
            className: this.state.inputType === 'checkbox' ? 'form-check-input' : 'form-control',
            placeholder: this.props.placeholder || this.state.fieldObj.placeholder,
            required: this.props.required || this.state.fieldObj.isRequired
        }
        switch (this.state.inputType) {
        case 'textarea':
            return this.renderTextArea(commonProps)
        case 'select':
            return this.renderSelect(commonProps)
        case 'geocode':
            return this.renderGeocode(commonProps)
        case 'checkbox':
            return this.renderCheckbox(commonProps)
        default:
            return this.renderInput(commonProps)
        }
    }

    render = () => {
        // const { fieldObj } = this.state
        return (
            <div className={`display-input ${this.props.extraClass}`}>
                <div className={`form-group ${this.state.inputType === 'checkbox' ? 'form-check' : ''}`}>
                    {this.renderFormInput()}
                    {this.renderError()}
                    {this.renderHelperText()}
                </div>
            </div>
        )
    }
}

export default ModelInputElement
