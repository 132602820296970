import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

// actions
import { apiRoutes, adminRoutes, clientRoutes } from '../../../constants/routes'
import { updateModel, listModels, customModelAction } from '../../../actions/api-model'

// constants
import { buildRoutePath } from '../../../constants/routes'

// models
import TruckModel from '../../../services/models/truck-model'
import ClientModel from '../../../services/models/client-model'

// utilities
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import ModelInput from '../../utils/model-input'

class Edit extends React.Component {
    state = {
        vehicle: null,
        vehicleClients: [],
        vehicleFleetIds: [],
        deleted: false
    }

    componentDidMount = () => {
        const { dispatch, vehicle } = this.props
        if (vehicle) {
            let vehicleModel = new TruckModel()
            vehicleModel.hydrate(vehicle.toJSON())
            this.setState({ vehicle: vehicleModel })
        }
        dispatch(listModels(new ClientModel()))
    }

    componentDidUpdate = (prevProps, prevState) => {
        let vehicleModel = new TruckModel()
        if (this.props.vehicle && prevProps.vehicle !== this.props.vehicle) {
            vehicleModel.hydrate(this.props.vehicle.toJSON())
            this.setState({ vehicle: vehicleModel })
        } else if (!this.props.vehicle && this.props.unassignedVehicle && !this.state.vehicle) {
            vehicleModel.hydrate(this.props.unassignedVehicle.toJSON())
            this.setState({ vehicle: vehicleModel })
        } else if (!this.props.vehicle && prevProps.vehicle) {
            this.setState({ deleted: true })
        }
    }

    successFX = (fleetId) => {
        const { routes } = this.props
        let path = buildRoutePath(routes.fleetView, { fleetId })
        this.setState({ isSubmitting: false })
        this.props.history.push(path)
    }

    submitFX = (formData, formId) => {
        // const { vehicle } = this.state
        // vehicle.hydrate(formData)
        // formData.id = this.props.stationId
        // formData.clients = formData.clients && formData.clients !== '' ? formData.clients.split(',') : []
        // this.props.dispatch(updateModel(vehicle, formData, formId, false))
        // this.setState({ isSubmitting: true })
        const { vehicle } = this.state
        const { clients } = this.props
        vehicle.hydrate(formData)
        formData.id = this.props.fleetId
        let truckClients = formData.clients && formData.clients !== '' ? formData.clients.split(',') : []
        formData.clients = truckClients
        let fleetIds = []
        clients.forEach(c => {
            if (truckClients.indexOf(c.get('id')) > -1 && c.get('fleetId')) {
                fleetIds.push(c.get('fleetId'))
            }
        })
        formData.fleetIds = fleetIds
        this.props.dispatch(updateModel(vehicle, formData, formId))
    }

    confirmDelete = () => {
        const { vehicle } = this.state
        const truckId = vehicle.get('id')
        if (!truckId) {
            // shoudlnt happen but catch in case, should we alert the user?
            return null
        }
        let deleteMe = window.confirm('Delete this Truck? This will remove all associated data and cannot be undone.')
        if (deleteMe) {
            let deleteMeCheck = window.confirm('Just to confirm, this cannot be undone. Continue?')
            if (deleteMeCheck) {
                const { dispatch } = this.props
                const routeObj = apiRoutes.deleteTruck
                routeObj.url = buildRoutePath(routeObj.url, { truckId })
                let truckModel = new TruckModel()
                dispatch(customModelAction(truckModel, routeObj, {}, true))
            }
        }
        return null
    }

    renderDelete = () => {
        const { auth } = this.props

        if (auth.get('role') === 'ADMIN') {
            return (
                <>
                    <hr className="mt-4" />
                    <div className="d-flex flex-100 w-100 justify-right">
                        <span className="a-style" onClick={ this.confirmDelete }>Delete</span>
                    </div>
                </>
            )
        }
        return null
    }

    render = () => {
        const { vehicle } = this.state
        const { auth, clients } = this.props

        if (this.state.deleted) {
            if (auth.get('role') === 'ADMIN') {
                return <Redirect to={adminRoutes.fleetIndex} />
            }
            return <Redirect to={clientRoutes.fleetIndex} />
        }

        let clientOptions = []
        if (clients) {
            clients.forEach((client, idx) => {
                clientOptions.push({ value: client.get('id'), label: client.get('name') })
            })
        }

        // NOTE: This is new to help it not blow up on save...
        let clientDefaults = []
        if (vehicle && vehicle.get('clients')) {
            if (typeof vehicle.get('clients') === 'string') {
                clientDefaults = vehicle.get('clients').split(',')
            } else {
                clientDefaults = vehicle.get('clients').toJSON()
            }
        }

        return (
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 mb-5">
                    <h1 className="mt-4">Edit Vehicle</h1>
                    { !vehicle && <div>Fetching Data.</div> }
                    { vehicle &&
                        <>
                            <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save Changes">
                                <ModelInput
                                    model={vehicle}
                                    field="display"
                                    label="Display Name"
                                    placeholder="Truck #1234" />
                                <ModelInput
                                    model={vehicle}
                                    field="vehicleId"
                                    label="Vehicle ID"
                                    placeholder="User to map station transactions." />
                                <InputElement
                                    type="searchMultiSelect"
                                    label="Clients"
                                    id="clients"
                                    default={clientDefaults}
                                    options={clientOptions} />
                                <hr/>
                                <h3>Offsets</h3>
                                <p>
                                    These values will be subtracted from this vehicle display when viewed by certain clients.
                                    These do not alter base data for the vehicle.
                                </p>
                                <p><b><u>Enter only integers. No special characters or commas</u></b></p>
                                <ModelInput
                                    model={vehicle}
                                    field="Offset_Hours_Diesel"
                                    label="Hours_Diesel" />
                                <ModelInput
                                    model={vehicle}
                                    field="Offset_Hours_DPF"
                                    label="Hours_DPF" />
                                <ModelInput
                                    model={vehicle}
                                    field="Offset_Hours_Bio"
                                    label="Hours_Bio" />
                                <ModelInput
                                    model={vehicle}
                                    field="Offset_Hours_LowFuel"
                                    label="Hours_LowFuel" />
                            </SHSForm>
                            { this.renderDelete() }
                        </>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const fleetId = ownProps.match.params.fleetId
    return {
        auth: state.get('auth'),
        clients: state.get('clients'),
        fleetId: fleetId,
        vehicle: state.get('trucks').find(s => s.get('id') === fleetId),
        unassignedVehicle: state.get('truckList').find(t => t.get('id') === fleetId)
    }
}

export default connect(mapStateToProps)(Edit)
