import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// models
import PricesModel from '../../services/models/prices-model'

// utils
import { apiRoutes, buildRoutePath } from '../../constants/routes'
import { formatDate } from '../utils/formatters'
import { customModelAction } from '../../actions/api-model'

// components
import MUIDataTable from 'mui-datatables'

// const EMPTY_END = 10000000000

const options = {
    filterType: 'dropdown',
    print: false,
    search: false,
    download: false,
    filter: false,
    viewColumns: false,
    responsive: 'stacked',
    selectableRows: 'none',
    rowsPerPage: 10,
    setRowProps: (row, dataIndex, rowIndex) => {
        let current = row[0]
        let status = row[4]
        let c = ''
        if (current !== '') {
            c = 'is-current'
        }
        if (status !== '') {
            c = 'has-error'
        }
        return {
            className: c
        }
    }
}

class StationPricesTable extends React.Component {
    state = {
        sortedPrices: null,
        error: null,
        currents: 0
    }

    componentDidMount = () => {
        this.validatePrices()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.prices !== this.props.prices) {
            this.validatePrices()
        }
    }

    confirmDelete = (stationId, priceId) => {
        const { dispatch } = this.props
        let confirmKill = window.confirm('Are you sure you want to delete this entry? This cannot be undone.')
        if (confirmKill) {
            const routeObj = apiRoutes.deleteStationPrice
            let myRouteObj = { ...routeObj }
            myRouteObj.url = buildRoutePath(routeObj.url, { priceId: priceId })
            let price = new PricesModel()
            price.set('stationId', stationId)
            dispatch(customModelAction(price, myRouteObj, {}, true))
        }
    }

    validatePrices = () => {
        const { prices } = this.props
        if (!prices) {
            this.setState({ sortedPrices: null, error: null, curCount: null })
            return null
        }
        let error = false
        let currents = 0
        // check there are not more than 1 current price
        // check there are no overlaps
        let sorted = prices.sortBy(p => p.getIn(['startDate', '_seconds']))
        for (let i = 0; i < sorted.size; i = i + 1) {
            if (sorted.getIn([i, 'isCurrent'])) {
                currents = currents + 1
            }
            let curEnd = sorted.getIn([i, 'endDate']) ? sorted.getIn([i, 'endDate', '_seconds']) : null
            let nextStart = i + 1 <= sorted.size ? sorted.getIn([i + 1, 'startDate', '_seconds']) : null
            if (nextStart && curEnd >= nextStart) {
                sorted = sorted.setIn([i, 'hasError'], true)
                sorted = sorted.setIn([i + 1, 'hasError'], true)
                error = 'Overlaps'
            }
        }
        this.setState({ sortedPrices: sorted, error: error, curCount: currents })
        return null
    }

    renderPrices = () => {
        const { prices, stationId, routes } = this.props
        const { sortedPrices } = this.state

        if (!prices) {
            return 'No Price Data Found'
        }

        const columns = [
            {
                name: 'isCurrent',
                label: 'Cur',
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            return <FontAwesomeIcon icon="dot-circle" title="Current" />
                        }
                        return ''
                    }
                }
            },
            {
                name: 'pricePerGallon',
                label: 'Price Per Gallon',
                options: {
                    customBodyRender: (value) => {
                        return `$${value}`
                    }
                }
            },
            {
                name: 'startDate',
                label: 'Start Date',
                options: {
                    sortDirection: 'desc',
                    customBodyRender: (value) => {
                        let f = formatDate(value)
                        return `${f.month}/${f.day}/${f.year}`
                    }
                }
            },
            {
                name: 'endDate',
                label: 'End Date',
                options: {
                    customBodyRender: (v) => {
                        if (v.get('endDate')) {
                            let f = formatDate(v.getIn(['endDate', '_seconds']) * 1000)
                            return `${f.month}/${f.day}/${f.year}`
                        }
                        return 'Not Set'
                    }
                }
            },
            {
                name: 'status',
                label: 'Status',
                options: {
                    customBodyRender: (v) => {
                        if (v.get('hasError')) {
                            return 'Overlaps'
                        } else if (v.get('isCurrent') && this.state.curCount > 1) {
                            return 'Multiple Currents'
                        }
                        // if (v.get('isCurrent')) {
                        //     return 'Current'
                        // }
                        return ''
                    }
                }
            },
            {
                name: 'concat_ids',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let parts = value.split('~')
                        let link = buildRoutePath(routes.stationsPriceEdit, { stationId: parts[0], priceId: parts[1] })
                        return (
                            <NavLink to={link} className="btn btn-outline-secondary">Edit</NavLink>
                        )
                    }
                }
            },
            {
                name: 'concat_ids',
                label: ' ',
                options: {
                    customBodyRender: (value) => {
                        let parts = value.split('~')
                        return (
                            <div onClick={() => this.confirmDelete(parts[0], parts[1])} className="btn btn-outline-secondary">Delete</div>
                        )
                    }
                }
            }
        ]

        let itemArray = []
        if (sortedPrices) {
            sortedPrices.forEach((v) => {
                let p = {
                    isCurrent: v.get('isCurrent'),
                    pricePerGallon: v.get('pricePerGallon'),
                    startDate: v.getIn(['startDate', '_seconds']) * 1000,
                    endDate: v,
                    concat_ids: `${stationId}~${v.get('id')}`,
                    status: v
                }
                itemArray.push(p)
            })
        }

        return (
            <MUIDataTable
                title=""
                data={itemArray}
                columns={columns}
                options={options}
            />
        )
    }

    render = () => {
        let label = this.props.label ? this.props.label : 'Price Data'
        let cta = this.props.cta ? this.props.cta : null
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="widget">
                        <h3 className="border-bottom mb-3">
                            {label}
                            {cta}
                        </h3>
                        <div className="mui-table">
                            { this.renderPrices() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        prices: state.get('prices').filter(price => {
            return price.get('stationId') === ownProps.stationId && !price.get('isDeleted')
        })
    }
}

export default connect(mapStateToProps)(StationPricesTable)
