import React from 'react'
import { connect } from 'react-redux'

// models
import StationModel from '../../../services/models/station-model'

// utilities
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

// actions
import { updateModel } from '../../../actions/api-model'
import { upsertForm } from '../../../actions/form'

// constants
import { formStates } from '../../../constants/helper-states'

class ManageClientStations extends React.Component {
    state = {
        clientId: this.props.clientId,
        clientStationList: this.props.clientStations
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.stations !== prevProps.stations) {
            let newClientStations = this.props.stations.filter(s => 
                (s.get('clients') && s.get('clients').indexOf(this.props.clientId) > -1)
            )
            this.setState({ clientStationList: newClientStations })
        }
    }

    successFX = () => {
        this.props.onClose()
    }

    submitFX = (formData, formId) => {
        const { dispatch, clientId, stations } = this.props
        let formReset = false
        let curStations = formData.stations ? formData.stations.split(',') : []

        // For all trucks
        stations.forEach(s => {
            // list of all trucks client is currently on
            let updateStation = false
            let hasClient = s.has('clients') ? s.get('clients').indexOf(clientId) !== -1 : false
            let inStationList = curStations.indexOf(s.get('id')) !== -1
            let clientList = s.has('clients') ? s.get('clients').toJSON() : []

            let newModel = new StationModel()
            newModel.set('id', s.get('id'))
            if (hasClient && !inStationList) {
                // was removed from list
                clientList = clientList.filter(c => c !== clientId)
                updateStation = true
            } else if (!hasClient && inStationList) {
                // was added to list
                clientList.push(clientId)
                updateStation = true
            }

            if (updateStation) {
                formReset = true
                dispatch(updateModel(newModel, { clients: clientList }, formId))
            }
        })
        if (!formReset) {
            // We didn't dispatch anything, close the form
            dispatch(upsertForm(formId, formStates.SUCCESS, 'No Stations changed, close form'))
        }
    }


    render = () => {
        const { stations } = this.props
        const { clientStationList } = this.state

        if (!stations) {
            return 'Loading...'
        }

        let defaultOptions = []
        if (clientStationList) {
            clientStationList.forEach(station => {
                defaultOptions.push(station.get('id'))
            })
        }

        let availableStationOptions = []
        if (stations) {
            stations.forEach(s => {
                availableStationOptions.push({ value: s.get('id'), label: s.get('display') })
            })
        }

        return (
            <div className="px-5 pb-5">
                <h1 className="my-2 text-center">Add / Edit Client Stations</h1>
                <p className="text-center">
                    Use the input field and dropdown to add stations to a client.<br/>
                    Remove a station by clicking the [X] on an existing item.
                </p>
                <hr/>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save">
                    <InputElement
                        type="searchMultiSelect"
                        label="Find Stations"
                        id="stations"
                        onChange={this.handleChange}
                        default={defaultOptions}
                        options={availableStationOptions} />
                </SHSForm>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        client: state.get('clients').find(c => c.get('id') === ownProps.clientId),
        clientStations: state.get('stations').filter(s =>
            (s.get('clients') && s.get('clients').indexOf(ownProps.clientId) !== -1)
        ),
        stations: state.get('stations')
    }
}

export default connect(mapStateToProps)(ManageClientStations)
