import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

// components
import BioDieselRuntime from '../../general/biodiesel-runtime'
import LinearGasLevel from '../../general/linear-gas-level'
import LinearFilterDifferential from '../../general/linear-filter-differential'
import VehicleData from '../../general/vehicle-data'
import FaultsListTable from '../../general/faults-list-table'

import MapBlock from '../../general/mapblock'

class View extends React.Component {
    state = {}

    render = () => {
        const { vehicle, report, routes, auth } = this.props
        if (!vehicle) {
            return <div>Error: No Vehicle Found.</div>
        }

        let updateTime = moment.unix(vehicle.get('updateDate')).format('MMM D, h:mm A')
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <h1>{ vehicle.get('display') }</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <FaultsListTable truckId={ vehicle.get('id') } />
                        <div className="row">
                            <div className="col-md-6">
                                <LinearGasLevel report={report} updateTime={updateTime} />
                                <LinearFilterDifferential report={report} updateTime={updateTime} />
                            </div>
                            <div className="col-md-6">
                                <VehicleData truck={vehicle} report={report} clientId={auth.get('clientId')} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <BioDieselRuntime vehicle={vehicle} clientId={auth.get('clientId')} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-5">
                        <h3>Fleet Locations</h3>
                        <MapBlock truckIds={[vehicle.get('id')]} fleetPath={routes.fleetView} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const fleetId = ownProps.match.params.fleetId
    const vehicle = state.get('trucks').find(s => s.get('id') === fleetId)
    return {
        auth: state.get('auth'),
        vehicle: vehicle,
        report: vehicle && vehicle.get('report') ? vehicle.get('report') : null
    }
}

export default connect(mapStateToProps)(View)
