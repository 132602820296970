import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { sharedRoutes, apiRoutes } from '../../constants/routes'

import { customModelAction } from '../../actions/api-model'

import UserModel from '../../services/models/user-model'

class AuthRouter extends React.Component {
    state = {
        isLoading: true,
        isRedirecting: false
    }

    componentDidMount = () => {
        const { auth } = this.props
        if (auth.has('authId')) {
            // for now we're not doing any routing unless we have the auth...
            // TODO: If no user we need to redirect to create user / registration ?
            let user = new UserModel()
            this.props.dispatch(customModelAction(user, apiRoutes.getUserData, {}, true))
            this.setState({
                isLoading: true,
                isRedirecting: false
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        const { auth } = this.props
        if (!prevProps.auth.has('authId') && auth.has('authId')) {
            let user = new UserModel()
            this.props.dispatch(customModelAction(user, apiRoutes.getClientId, {}, true))
        }
        if ((!prevProps.auth.has('clientId') && auth.has('clientId')) || auth.get('role') === 'ADMIN') {
            this.setState({
                isLoading: false,
                isRedirecting: true
            })
        }
    }

    render = () => {
        const { isRedirecting } = this.state
        const { location, auth } = this.props
        if (isRedirecting) {
            // if (location.state && location.state.from.pathname && auth.get('hasAccepted')) {
            if (location.state && location.state.from.pathname) {
                return <Redirect to={location.state.from.pathname} />
            }
            if (auth.get('role') === 'ADMIN') {
                return <Redirect to={sharedRoutes.adminIndex} />
            }
            // if (!auth.get('hasAccepted')) {
            //     return <Redirect to={routes.userAgreement} />
            // }
            return <Redirect to={sharedRoutes.clientIndex} />
        }
        return (
            <div className="container">
                Routing...
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default connect(mapStateToProps)(AuthRouter)
