import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'

// actions
import { listModels } from '../../../actions/api-model'
import { buildRoutePath } from '../../../constants/routes'

// models
import ClientModel from '../../../services/models/client-model'

// components
import BioDieselRuntime from '../../general/biodiesel-runtime'
import LinearGasLevel from '../../general/linear-gas-level'
import LinearFilterDifferential from '../../general/linear-filter-differential'
import VehicleData from '../../general/vehicle-data'
import FaultsListTable from '../../general/faults-list-table'
import WidgetClients from '../../general/widget-clients'

import MapBlock from '../../general/mapblock'

class View extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(listModels(new ClientModel()))
    }

    renderDieselTruck = (updateTime, truckClients, editLink) => {
        // const { fleetId, vehicle, baseVehicle, report, clients, routes } = this.props
        return <h1>Diesel!</h1>
    }

    renderBioTruck = (updateTime, truckClients, editLink) => {
        const { vehicle, report, routes } = this.props
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <NavLink to={ editLink } className="h1">
                            { vehicle.get('display') }
                            <FontAwesomeIcon icon="edit" title="Edit" />
                        </NavLink>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <FaultsListTable truckId={ vehicle.get('id') } />
                        <div className="row">
                            <div className="col-md-6">
                                <LinearGasLevel report={report} updateTime={updateTime} />
                                <LinearFilterDifferential report={report} updateTime={updateTime} />
                            </div>
                            <div className="col-md-6">
                                <VehicleData truck={vehicle} report={report} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <BioDieselRuntime vehicle={vehicle} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-5">
                        <WidgetClients clients={truckClients} />
                        <h3>Fleet Locations</h3>
                        <MapBlock truckIds={[vehicle.get('id')]} fleetPath={routes.fleetView} />
                    </div>
                </div>
            </>
        )
    }

    render = () => {
        const { fleetId, vehicle, baseVehicle, report, clients, routes } = this.props
        const editLink = buildRoutePath(routes.fleetEdit, { fleetId: fleetId })

        if (!vehicle) {
            if (!baseVehicle) {
                return <div>Error: No Vehicle Found.</div>
            }
            return (
                <div className="row">
                    <div className="col-md-12">
                        <h1>{ baseVehicle.get('display') }: NOTICE</h1>
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <p className="text-center">Notice: Assign this truck to at least one client to see full data. If you have just assigned a client, try refreshing the page or wait a moment for the data to load.</p>
                                <div className="flex-100 w-100 justify-center">
                                    <NavLink to={ editLink } className="btn btn-blue btn py-3 px-5 ">
                                            Edit
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        let updateTime = report.get('Time')
        let truckClients = clients && baseVehicle ? clients.filter(c => baseVehicle.get('clients').indexOf(c.get('id')) !== -1) : null
        let truckType = baseVehicle.get('type')
        if (truckType === 'truck-diesel') {
            console.log('TODO: Diesel profile')
            return this.renderBioTruck(updateTime, truckClients, editLink)
            // return this.renderDieselTruck(updateTime, truckClients, editLink)
        }
        return this.renderBioTruck(updateTime, truckClients, editLink)
    }
}

const mapStateToProps = (state, ownProps) => {
    const fleetId = ownProps.match.params.fleetId
    const vehicle = state.get('trucks').find(s => s.get('id') === fleetId)
    return {
        clients: state.get('clients'),
        fleetId: fleetId,
        vehicle: vehicle,
        baseVehicle: state.get('truckList').find(s => s.get('id') === fleetId),
        report: vehicle && vehicle.get('report') ? vehicle.get('report') : null
    }
}

export default connect(mapStateToProps)(View)
