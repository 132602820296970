import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import Lottie from 'react-lottie'
import * as logoAnim from '../../assets/json/optimusLogo.json'

import { generatePasswordResetLink } from '../../actions/auth'
import { sharedRoutes } from '../../constants/routes'

class PasswordHelp extends React.Component {
    state = {
        linkSent: false,
        isStopped: true
    }

    componentDidMount = () => {
        this.timer = setTimeout(() => {
            this.setState({ isStopped: false })
        }, 1000)
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer)
    }

    successFX = () => {
        this.setState({ linkSent: true })
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(generatePasswordResetLink(formData.email, formId))
    }

    render = () => {
        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: logoAnim.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return (
            <div className="container">
                <div className="row pt-5">
                    <div className="col-4 offset-4">
                        <Lottie
                            options={defaultOptions}
                            isStopped={this.state.isStopped}
                            ref={animation => {
                                this.animation = animation
                            }}/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-8 p-4">
                        <div className="input-values">
                            <h1>Password Help</h1>
                            { this.state.linkSent
                                ? <p>Check your email, we sent you a link to help reset your password.</p>
                                : (
                                    <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Get Password Help" buttonClass="black center">
                                        <InputElement
                                            type="email"
                                            default=""
                                            id="email"
                                            label="Email Address"
                                            placeholder="user@example.com"
                                            extraClass="flex-100"
                                            required />
                                    </SHSForm>
                                )
                            }
                            <div className="w-100 mt-4">
                                <p className="text-center">
                                    <small className="font-weight-light">
                                        <Link to={sharedRoutes.login}>Return to login.</Link>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(PasswordHelp)
