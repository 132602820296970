export const CLIENT_COLLECTION = 'clients'
export const INVITATION_COLLECTION = 'invitations'
export const ROLE_COLLECTION = 'configRoles'
export const TRUCK_COLLECTION = 'trucks'
export const EMAIL_PREFERENCES_COLLECTION = 'emailPreferences'
export const ENTITY_COLLECTION = 'entities'
export const USER_COLLECTION = 'users'
export const AUTH_TOKEN_COLLECTION = 'authTokens'
export const STATION_COLLECTION = 'stations'
export const TRANSACTION_COLLECTION = 'transactions'
export const PRICE_COLLECTION = 'prices'
export const REPORT_COLLECTION = 'reports'
export const FAULTS_COLLECTION = 'faults'
export const FAULT_LOG_COLLECTION = 'faultLog'
