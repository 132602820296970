import React from 'react'
import { connect } from 'react-redux'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import ModelInput from '../utils/model-input'

import { anonymousSignIn } from '../../actions/auth'
import { customModelAction } from '../../actions/api-model'
import { registerWithInvitation } from '../../actions/auth'

import { apiRoutes } from '../../constants/routes'

import InvitationModel from '../../services/models/invitation-model'
import UserModel from '../../services/models/user-model'

class InvitationRegistration extends React.Component {
    state = {
        invitationModel: new InvitationModel(),
        userModel: new UserModel()
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(anonymousSignIn())
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.auth && this.props.auth.get('isAnonymous') && !prevProps.auth.get('isAnonymous')) {
            let { invitationModel } = this.state
            this.props.dispatch(customModelAction(invitationModel, apiRoutes.lookupInvitationStatus, { invitationId: this.props.match.params.invitationId }))
        }
        if (this.props.invitation && !prevProps.invitation) {
            let { userModel } = this.state
            if (this.props.invitation.has('firstName')) {
                userModel.set('firstName', this.props.invitation.get('firstName'))
            }
            if (this.props.invitation.has('email')) {
                userModel.set('email', this.props.invitation.get('email'))
            }
            this.setState({ userModel: userModel })
        }
    }

    successFX = () => {
        // does nothing for now...
    }

    submitFX = (formData, formId) => {
        let { userModel } = this.state
        userModel.hydrate(formData)
        // the userModle does not have a password field, so need to pass it explicitly
        this.props.dispatch(registerWithInvitation(userModel, formData.password, this.props.match.params.invitationId, formId))
    }

    render = () => {
        const { invitation } = this.props
        if (!invitation) {
            return <div>Loading...</div>
        }

        if (invitation && invitation.get('status') !== 'PENDING') {
            return <div>This invitation code is no longer valid.</div>
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-6 offset-3 p-4 mt-5">
                        <h1>Register</h1>
                        <p>This is the Optimus registration page.</p>
                        <div className="input-values">
                            <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Register">
                                <ModelInput
                                    model={this.state.userModel}
                                    field="firstName"
                                    label="First Name"
                                    placeholder="Lightening" />
                                <ModelInput
                                    model={this.state.userModel}
                                    field="lastName"
                                    label="Last Name"
                                    placeholder="McQueen" />
                                <ModelInput
                                    model={this.state.userModel}
                                    field="email"
                                    default={invitation.get('email')}
                                    label="Email Address"
                                    placeholder="lightening.mcqueen@drivefast.com" />
                                <InputElement
                                    type="password"
                                    id="password"
                                    label="Password"
                                    placeholder="Something secure that only you know..."
                                    extraClass="flex-100"
                                    required />
                            </SHSForm>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth'),
        invitation: state.get('invitations').find(invitation => invitation.get('id') === ownProps.match.params.invitationId)
    }
}

export default connect(mapStateToProps)(InvitationRegistration)
