import React from 'react'
import VehicleDataMap from '../../constants/vehicle-data-map'
import { offsetClients } from '../../constants/offset-clients'

import ReportDownloadForm from './report-download-form'
import Modal from '../utils/modal'

class VehicleData extends React.Component {
    state = {
        showModal: false
    }

    handleModal = (bool) => {
        this.setState({ showModal: bool })
    }

    formatData = () => {
        const { truck, clientId } = this.props
        let data = []
        Object.keys(VehicleDataMap).forEach((key, i) => {
            let value = this.props.report.get(key) ? this.props.report.get(key) : '--'
            if (VehicleDataMap[key].hasOffset && truck && clientId && offsetClients.indexOf(clientId) > -1) {
                if (truck.get(`Offset_${key}`)) {
                    // NOTE: This assumes we stripped units in the Live Truck item
                    let offset = parseInt(truck.get(`Offset_${key}`), 10)
                    let v = this.props.report.get(key) ? parseInt(value, 10) : 0
                    value = `${v - offset} ${VehicleDataMap[key].unit}`
                }
            }
            if (VehicleDataMap[key].level) {
                value = this.props.report.get(key) ? VehicleDataMap[key].level[parseInt(value, 10)] : '--'
            }
            let block = (
                <div className="widget-table-row" key={i}>
                    <div className="col-sm-6">
                        { VehicleDataMap[key].label }
                    </div>
                    <div className="col-sm-6">
                        { value }
                    </div>
                </div>
            )
            data.push(block)
        })
        return data
    }

    render = () => {
        const { report } = this.props
        if (!report) {
            return null
        }
        // let updateTime = moment.unix(report.get('updateDate')).format('MMM D, h:mm A')
        let updateTime = report.get('Time')
        return (
            <>
                <div className="widget vehicle-data">
                    <h3 className="border-bottom">Latest Vehicle Data</h3>
                    <div className="description"><div><label>{updateTime}</label></div></div>
                    <div className="widget-wrapper">
                        { this.formatData() }
                        <div className="btn btn-outline-secondary btn-blue py-3" onClick={() => this.handleModal(true)}>
                            Generate Report
                        </div>
                    </div>
                </div>
                { this.state.showModal &&
                    <Modal onClose={() => this.handleModal(false)}>
                        <ReportDownloadForm truckDisplay={this.props.truck.get('display')} truckId={report.get('truckId')} onHandleModal={this.handleModal} />
                    </Modal>
                }
            </>
        )
    }
}

export default VehicleData
