import immutable from 'immutable'

import * as helpers from './utils'
import { SAVE_API_CALL, CLEAR_API_CALL, KILL_STATE } from '../services/constants/actions'

const INITIAL_STATE = immutable.fromJS([])

export default (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    // let newState = state
    switch (action.type) {
    case SAVE_API_CALL:
        objectIndex = helpers.getObjectIndex(state, action.data.url, 'url')
        if (objectIndex === -1) {
            return helpers.addObject(state, action.data)
        }
        return helpers.updateObject(state, action.data, objectIndex)
    case CLEAR_API_CALL:
        return state.filter(entry => {
            return action.data.url !== entry.get('url')
        })
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}
