import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import TransactionModel from '../../services/models/transaction-model'

import { customModelAction } from '../../actions/api-model'
import { apiRoutes, buildRoutePath } from '../../constants/routes'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

class StationReportDownloadForm extends React.Component {
    state = {
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
        minDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        reportType: 'txt'
    }

    componentDidUpdate = (prevProps, prevState) => {
        const startMoment = moment(this.state.startDate)
        const endMoment = moment(this.state.endDate)
        if (prevState.startDate !== this.state.startDate) {
            // check that end Date is within 7 days
            if (endMoment.isBefore(startMoment)) {
                this.setState({ endDate: startMoment.format('YYYY-MM-DD') })
            } else if (endMoment.diff(startMoment, 'days') > 32) {
                this.setState({ endDate: startMoment.add(31, 'days').format('YYYY-MM-DD') })
            }
        } else if (prevState.endDate !== this.state.endDate) {
            if (endMoment.isBefore(startMoment)) {
                this.setState({ startDate: endMoment.format('YYYY-MM-DD') })
            } else if (endMoment.diff(startMoment, 'days') > 32) {
                this.setState({ startDate: endMoment.subtract(31, 'days').format('YYYY-MM-DD') })
            }
        }
    }

    generateReport = (params) => {
        const { stationId } = this.props
        const routeObj = apiRoutes.downloadStationReportFile
        routeObj.url = buildRoutePath(routeObj.url, {})
        let transaction = new TransactionModel()
        transaction.set('stationId', stationId)
        this.props.dispatch(customModelAction(transaction, routeObj, params, true, true))
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    successFX = () => {
        this.props.onHandleModal(false)
    }

    submitFX = (formData, formId) => {
        const { startDate, endDate, reportType } = this.state
        const payload = {
            stationId: this.props.stationId,
            start: startDate,
            end: endDate,
            reportType,
            formId
        }
        this.generateReport(payload)
    }

    render = () => {
        return (
            <div>
                <h1>Download Station Report</h1>
                <p>
                    Use the form below to configure your preferences for downloading a report.<br />
                    Reports are limited to a 31 day period.<br />
                    Please note the report files are large and can take 1-2 minutes to download.
                </p>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Download Report" buttonMessage="Processing...">
                    <InputElement
                        type="date"
                        label="Start Date"
                        id="startDate"
                        default={this.state.startDate}
                        onChange={this.handleChange}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate} />
                    <InputElement
                        type="date"
                        label="End Date"
                        id="endDate"
                        default={this.state.endDate}
                        onChange={this.handleChange}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate} />
                    <InputElement
                        type="valueSelect"
                        label="Format"
                        id="reportType"
                        includeInitialValue={false}
                        onChange={this.handleChange}
                        options={[{ value: 'txt', label: 'TXT: FASTER Fleet Management Formatted Text File' }, { value: 'csv', label: 'CSV: Comma Separated Values' }]} />
                </SHSForm>
            </div>
        )
    }
}

export default connect()(StationReportDownloadForm)
