import { combineReducers } from 'redux-immutable'

import apiCacheReducer from './api-cache'
import authReducer from './auth'
import authCodeReducer from './auth-code'
import clientReducer from './clients'
import entityReducer from './entities'
import emailPreferencesReducer from './email-preferences'
import faultsReducer from './faults'
import formReducer from './form'
import invitationReducer from './invitation'
import positionReducer from './positions'
import pricesReducer from './prices'
import stationReducer from './stations'
import transactionReducer from './transactions'
import truckReducer from './trucks'
import truckListReducer from './truck-list'
import userReducer from './users'
import watcherReducer from './watchers'


const indexReducer = combineReducers({
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    clients: clientReducer,
    entities: entityReducer,
    emailPreferences: emailPreferencesReducer,
    faults: faultsReducer,
    forms: formReducer,
    invitations: invitationReducer,
    positions: positionReducer,
    prices: pricesReducer,
    stations: stationReducer,
    transactions: transactionReducer,
    trucks: truckReducer,
    truckList: truckListReducer,
    users: userReducer,
    watchers: watcherReducer
})

export default indexReducer
