import React from 'react'
import { connect } from 'react-redux'

// components
import FleetTable from '../general/fleet-table'
import MapBlock from '../general/mapblock'
import BiodieselConsumed from '../general/biodiesel-consumed'
import CoOffset from '../general/co-offset'
import StationsTable from '../general/stations-table'

class ClientDashboard extends React.Component {
    state = {

    }

    render = () => {
        const { trucks, stations, routes, auth } = this.props
        let truckIds = []
        if (trucks) {
            trucks.forEach((v) => {
                truckIds.push(v.get('id'))
            })
        }
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h1>Dashboard</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        { stations && stations.size > 0 &&
                            <div className="row">
                                <div className="col-md-6">
                                    <BiodieselConsumed stations={stations} clientId={auth.get('clientId')} />
                                </div>
                                <div className="col-md-6">
                                    <CoOffset stations={stations} overview={true} />
                                </div>
                            </div>
                        }
                        <FleetTable label="Trucks" path={ routes.fleetView } vehicles={ trucks } clientId={auth.get('clientId')} />
                        <StationsTable label="Stations" stations={stations} path={routes.stationsView} />
                    </div>
                    <div className="col-md-4 mb-5">
                        <h3>Fleet Locations</h3>
                        <MapBlock truckIds={ truckIds } fleetPath={routes.fleetView} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.get('auth'),
        trucks: state.get('trucks'),
        stations: state.get('stations')
    }
}

export default connect(mapStateToProps)(ClientDashboard)
