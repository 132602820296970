// TODO: Make this configurable by Admin
const VehicleDataMap = {
    Vehicle_State: {
        label: 'Vehicle State',
        level: {
            0: 'Off',
            1: 'Initializing',
            2: 'Diesel',
            3: 'Bio Warmup',
            4: 'Bio',
            5: 'Operation Purge',
            6: 'Vector Purge',
            7: 'Software Update',
            8: 'ESTOP'
        }
    },
    Bio_Pump_Post_Fltr_Pressure: {
        label: 'Bio Pump Post Filter Pressure'
    },
    Hours_DPF: {
        label: 'DPF Hours',
        hasOffset: true,
        unit: 'Hours'
    },
    Hours_Fault: {
        label: 'Fault Hours',
        hasOffset: true,
        unit: 'Hours'
    },
    Hours_LowFuel: {
        label: 'Low Fuel Hours',
        hasOffset: true,
        unit: 'Hours'
    }
}

export default VehicleDataMap
