import React from 'react'
import { nanoid } from 'nanoid'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { upsertForm, clearForm } from '../../actions/form'
import { formStates } from '../../constants/helper-states'


class SHSForm extends React.Component {
    state = {
        formId: nanoid()
    }

    componentDidMount = () => {
        this.props.dispatch(upsertForm(this.state.formId, formStates.EMPTY))
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearForm(this.state.formId))
    }

    componentDidUpdate = (prevProps) => {
        const formStatus = this.props.forms.find(form => (form.get('id') === this.state.formId))
        const prevFormStatus = prevProps.forms.find(form => (form.get('id') === this.state.formId))
        // TODO: add check for previous form status...actually, do we need that?
        if (formStatus && formStatus.get('status') === formStates.SUCCESS && prevFormStatus !== formStatus) {
            // Updating to only call once
            // TODO: look at props to figure out what we should do here, but default is to send body back
            this.props.successFX(formStatus.get('body'))
            // this.props.history.push(buildRoutePath(routes.viewProject, { projectId: formStatus.get('body') }))
        }
    }

    handleChange = (evt) => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
        this.setState({ [evt.target.id]: value })
    }

    handleSubmit = (evt) => {
        evt.preventDefault()
        let validated = evt.target.checkValidity()
        evt.target.classList.remove('was-validated')
        if (!validated) {
            evt.target.classList.add('was-validated')
        } else {
            const { formId } = this.state
            const formDataObj = new FormData(evt.target)
            let formData = {}
            formDataObj.forEach((val, key) => {
                formData[key] = val
            })
            this.props.dispatch(upsertForm(formId, formStates.PROCESSING))
            this.props.submitFX(formData, formId)
        }
    }

    render = () => {
        const formStatus = this.props.forms.find(stateForm => stateForm.get('id') === this.state.formId)
        const buttonLabel = this.props.buttonLabel || 'Submit'
        const buttonMessage = this.props.buttonMessage || 'Redirecting...'
        return (
            <form onSubmit={this.handleSubmit} className={`form ${this.props.extraClass || ''}`} noValidate>
                {this.props.children}
                {formStatus && formStatus.get('status') === 'ERROR' && formStatus.get('body') !== '' &&
                    <div className="alert alert-danger w-100">
                        {formStatus.get('body')}
                    </div>
                }
                <div className="flex-100 w-100 justify-center">
                    <button
                        type="submit"
                        className={`btn btn-blue btn py-3 px-5 ${this.props.buttonClass ? this.props.buttonClass : ''}`}
                        disabled={formStatus && (formStatus.get('status') === 'PROCESSING' || formStatus.get('status') === 'SUCCESS') ? 'disabled' : null} >
                        {formStatus && formStatus.get('status') === 'PROCESSING' && <FontAwesomeIcon icon="spinner" spin className="mr-2" title="Loading" /> }
                        {formStatus && formStatus.get('status') === 'PROCESSING' ? buttonMessage : buttonLabel}
                    </button>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        forms: state.get('forms')
    }
}

export default connect(mapStateToProps)(SHSForm)
